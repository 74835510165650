import { LogoutOutlined, UserOutlined } from '@ant-design/icons';
import { Dropdown, Menu, Tooltip } from 'antd';
import React from 'react';
import ModalService from '../../../../Services/modalService';
import './index.less';

function UserAvatar({
  userData: { imageUrl: userAvatarSrc = '', givenName: userName = '' },
  onLogoutSuccess = () => {},
  onFailure = () => {}
}) {
  const signOut = () => {
    onLogoutSuccess();
  };

  const menu = React.useMemo(
    () => (
      <Menu
        items={[
          {
            key: 'profile',
            label: 'Profile',
            icon: <UserOutlined />
          },
          {
            key: 'Logout',
            label: 'Logout',
            icon: <LogoutOutlined />,
            danger: true,
            onClick: () => {
              ModalService.confirm({
                maskClosable: true,
                title: 'Logout',
                content: 'Are you sure to logout?',
                okType: 'danger',
                okText: 'Logout',
                cancelText: "I'll stay",
                cancelButtonProps: { type: 'primary' },
                onOk: signOut
              });
            }
          }
        ]}
      />
    ),
    []
  );

  return (
    <Tooltip title={userName} placement={'left'}>
      <Dropdown overlay={menu} arrow trigger={['click']}>
        <img
          src={userAvatarSrc}
          className={'cursor-pointer avatar object-contain'}
          referrerPolicy={'no-referrer'}
        />
      </Dropdown>
    </Tooltip>
  );
}

export default React.memo(UserAvatar);
