export const UserActionTypes = {
  LOGIN: 'LOGIN',
  UPDATE_USER_DATA: 'UPDATE_USER_DATA',
  LOGOUT: 'LOGOUT'
};

export const login = userData => {
  return {
    type: UserActionTypes.LOGIN,
    userData
  };
};

export const updateUserData = userData => {
  return {
    type: UserActionTypes.UPDATE_USER_DATA,
    userData
  };
};

export const logout = () => {
  return {
    type: UserActionTypes.LOGOUT
  };
};
