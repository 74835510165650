import { Select } from 'antd';
import React from 'react';

const CustomSelect = React.forwardRef(
  (
    {
      children,
      onChange = () => {},
      onBlur = () => {},
      name = '',
      ...restProps
    },
    ref
  ) => {
    const onSelect = React.useCallback(
      changedValue => {
        const event = {
          target: {
            value: changedValue,
            name
          }
        };

        onChange(event);

        setTimeout(() => onBlur(event));
      },
      [onChange, onBlur, name]
    );

    return (
      <Select onChange={onSelect} ref={ref} {...restProps}>
        {children}
      </Select>
    );
  }
);

export default React.memo(CustomSelect);
