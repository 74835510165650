import { AlertOutlined } from '@ant-design/icons';
import { Col, Divider, Row, Space, Tag } from 'antd';
import moment from 'moment';
import React from 'react';
import { NavLink } from 'react-router-dom';
import { CommonGutterConfig } from '../../../Configs/common';
import CustomText from '../../Atoms/CustomText';
import './index.less';
import ReactMarkdown from 'react-markdown';
import remarkGfm from 'remark-gfm';

const Format = 'Do MMM YYYY, hh:mm A';

function ReleaseCard({
  to = '',
  onView = () => {},
  releaseVersion = '',
  deployedDate = '',
  description = '',
  isLatest = false
}) {
  return (
    <Row className={'min-w-full shadow-inner p-4 release-card-container'}>
      <Col span={24}>
        <Row
          className={'min-w-full'}
          justify={'space-between'}
          wrap
          gutter={CommonGutterConfig.gutterVerticalBase}>
          <Col>
            <Space
              align={'center'}
              wrap
              split={
                <Divider className={'release-divider mx-1'} type={'vertical'} />
              }>
              <Space>
                <NavLink to={{ pathname: to }} onClick={onView}>
                  <CustomText
                    className={
                      'text-3xl font-bold hover:underline sm:underline cursor-pointer version-title'
                    }>
                    {releaseVersion}
                  </CustomText>
                </NavLink>

                {isLatest && (
                  <Tag icon={<AlertOutlined />} color={'success'}>
                    Latest
                  </Tag>
                )}
              </Space>

              <CustomText className={'text-xs'}>
                {moment(deployedDate).format(Format)}
              </CustomText>
            </Space>
          </Col>
        </Row>
      </Col>

      <Col span={24}>
        <Divider className={'release-divider'} />
      </Col>

      <Col span={22}>
        <ReactMarkdown remarkPlugins={[remarkGfm]}>{description}</ReactMarkdown>
      </Col>
    </Row>
  );
}

export default React.memo(ReleaseCard);
