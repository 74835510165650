import * as Yup from 'yup';
import { ClientStatuses } from '../../../../../Configs/common';

export const schema = [
  {
    name: 'status',
    label: 'Status',
    colConfig: { span: 24 },
    inputConfig: {
      name: 'select',
      value: [],
      data: Object.values(ClientStatuses).map(item => ({
        value: item.key,
        label: item.label
      })),
      formItemProps: {
        tooltip: 'Status of the client'
      },
      inputProps: { placeholder: 'Select status' },
      validation: Yup.string()
    }
  }
];
