let _instance = null;

export const setLoadingRef = ref => {
  _instance = ref;
};

const LoadingService = {
  showLoading: () => _instance.showLoading(),
  closeLoading: () => _instance.closeLoading(),
  toggleLoading: () => _instance.toggleLoading()
};

export default LoadingService;
