import React from 'react';
import { setCurrentRouteTitle } from '../../../Services/generalServices';

const WithDefaultSetting = ({ props, defaults = {}, Component }) => {
  React.useEffect(() => {
    if (!defaults.title.length) {
      setCurrentRouteTitle(defaults.title());
    }
  }, [defaults]);

  return <Component {...props} {...defaults} />;
};

export default React.memo(WithDefaultSetting);
