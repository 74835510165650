import {
  DeleteOutlined,
  EyeOutlined,
  InboxOutlined,
  UploadOutlined
} from '@ant-design/icons';
import {
  Button,
  Col,
  Image,
  Popconfirm,
  Progress,
  Row,
  Space,
  Tooltip,
  Upload
} from 'antd';
import ImgCrop from 'antd-img-crop';
import React from 'react';
import { Colors } from '../../../Common/Colors/colors';
import { CommonGutterConfig, ImageAcceptType } from '../../../Configs/common';
import {
  getFormDataForFiles,
  getImageDimension,
  isFileImage,
  toPascalCase
} from '../../../Services/generalServices';
import MessageService from '../../../Services/messageService';
import RouterService from '../../../Services/routerService';
import { useCustomMutation } from '../../Wrappers/UseMutation';
import CustomText from '../CustomText';
import ImageCompression from 'browser-image-compression';

const ProgressStatus = {
  compressing: 'Compressing',
  uploading: 'Uploading'
};

const CustomUpload = React.forwardRef(
  (
    {
      onChange = () => {},
      onBlur = () => {},
      onDelete,
      error = false,
      showUploadButton = false,
      showDragAndDrop = false,
      showCropper = false,
      title = 'Upload',
      isFile = false,
      subTitle = '',
      disabled = false,
      value = null,
      name = '',
      accept = ImageAcceptType,
      multiple = false,
      ImgCropProps = {},
      maxCount = 1,
      uploadOptions,
      ...rest
    },
    ref
  ) => {
    const [progress, setProgress] = React.useState(0);
    const [progressStatus, setProgressStatus] = React.useState(
      ProgressStatus.compressing
    );
    const [preview, setPreview] = React.useState([]);
    const [currentPreviewImage, setCurrentPreviewImage] = React.useState(0);
    const uniqueId = React.useId();
    const { isLoading, mutateAsync } = useCustomMutation({
      mutationKey: uploadOptions?.key || '',
      mutationOptions: { onError: error => MessageService.error(error.message) }
    });

    const isAcceptImage = React.useMemo(
      () => accept === ImageAcceptType,
      [accept]
    );

    const DynamicComponent = React.useMemo(
      () =>
        isAcceptImage && showCropper
          ? {
              Comp: ImgCrop,
              props: {
                rotate: true,
                zoom: true,
                grid: true,
                ...ImgCropProps
              }
            }
          : { Comp: React.Fragment, props: {} },
      [isAcceptImage]
    );

    const generateUrl = React.useCallback(
      file => URL.createObjectURL(file),
      []
    );

    const sendValue = React.useCallback(
      fileList => {
        const event = {
          target: { value: multiple ? fileList : fileList[0], name }
        };

        onChange(event);
        setTimeout(() => onBlur(event), 100);
      },
      [onChange, onBlur, name]
    );

    const onUploadProgress = React.useCallback(
      progressEvent => {
        const uploadProgress = Math.round(
          (progressEvent.loaded * 100) / progressEvent.total
        );

        if (uploadProgress > progress) setProgress(uploadProgress);
      },
      [setProgress, progress]
    );

    const compressionLogic = React.useCallback(async (file, onProgress) => {
      const { height } = await getImageDimension(file);

      const options = {
        maxSizeMB: 1,
        maxWidthOrHeight: height,
        useWebWorker: true
      };

      return await ImageCompression(file, {
        ...options,
        onProgress
      });
    }, []);

    const compressImages = React.useCallback(async () => {
      setProgressStatus(ProgressStatus.compressing);

      const imagesInFiles = multiple
        ? value.filter(item => isFileImage(item)).length
        : isFileImage(value)
        ? 1
        : 0;

      if (imagesInFiles > 0) {
        if (multiple) {
          const compressedFiles = [];

          for (const file of value) {
            if (isFileImage(file)) {
              const compressedFile = await compressionLogic(file, progress => {
                setProgress(prev =>
                  Math.round(prev + progress / imagesInFiles)
                );
              });

              compressedFiles.push(compressedFile);
            } else compressedFiles.push(file);
          }

          return compressedFiles;
        } else return await compressionLogic(value, setProgress);
      } else return value;
    }, [value, setProgress, multiple, compressionLogic, setProgressStatus]);

    const handleUpload = React.useCallback(() => {
      if (!!uploadOptions && uploadOptions.key && !error) {
        const key = uploadOptions?.name ?? name;

        compressImages().then(data => {
          setProgress(0);

          const newValue = {
            [key]: data
          };

          const payload = getFormDataForFiles(newValue);

          if (payload[key]) {
            setProgressStatus(ProgressStatus.uploading);

            mutateAsync({
              payload: payload[key],
              ...(uploadOptions.extraConfig ?? {}),
              config: {
                onUploadProgress
              }
            })
              .then(({ data }) => {
                MessageService.success(
                  `${toPascalCase(name)} has been uploaded successfully!!!`
                );

                if (multiple) {
                  const newValue = [...value]
                    .filter(item => !(item instanceof File))
                    .concat(
                      data.signedUrls.map((url, index) => ({
                        url,
                        uid: uniqueId + index,
                        name: toPascalCase(name) + '-' + index + 1,
                        status: 'done'
                      }))
                    );

                  sendValue(newValue);
                } else if (isFileImage(value)) {
                  sendValue([
                    {
                      url: data.signedUrl,
                      uid: uniqueId,
                      name: toPascalCase(name),
                      status: 'done'
                    }
                  ]);
                }
              })
              .finally(() => setProgress(0));
          }
        });
      }
    }, [
      sendValue,
      value,
      error,
      uploadOptions,
      multiple,
      onUploadProgress,
      setProgress,
      setProgressStatus,
      compressImages
    ]);

    /**
     * Triggering upload function
     */
    React.useEffect(() => {
      const timer = setTimeout(handleUpload, 300);

      return () => {
        clearTimeout(timer);
      };
    }, [error, value]);

    const beforeUpload = React.useCallback(
      (file, fileList = []) => {
        const newFileLists = [];

        for (const fileInFileList of fileList) {
          if (isFileImage(fileInFileList)) {
            const url = generateUrl(fileInFileList);
            const newFile = new File([fileInFileList], fileInFileList.name, {
              type: fileInFileList.type
            });

            newFile.url = url;

            newFileLists.push(newFile);
          } else {
            newFileLists.push(fileInFileList);
          }
        }

        const newFileList = [...(multiple ? value : []), ...newFileLists];
        sendValue(newFileList);

        return false;
      },
      [value, sendValue, setProgress, setProgressStatus]
    );

    const onRemove = React.useCallback(
      file => {
        const fileList = multiple ? [...value] : [value];
        const index = fileList.indexOf(file);
        const spliceFile = () => {
          fileList.splice(index, 1);
          sendValue(fileList);
        };

        if (!!onDelete && !(file instanceof File)) {
          onDelete(file, index)?.then(spliceFile);
        } else {
          spliceFile();
        }
      },
      [value, sendValue, onDelete]
    );

    const onPreview = React.useCallback(
      file => {
        if (multiple) {
          const urlList = [];

          value.forEach(file => {
            if (isFileImage(file)) {
              const url = generateUrl(file);
              urlList.push(url);
            } else if (!!file.url) {
              urlList.push(file.url);
            }
          });

          setCurrentPreviewImage(value.indexOf(file));
          setPreview(urlList);
        } else {
          if (isFileImage(file)) {
            const url = generateUrl(file);

            setPreview([url]);
          } else if (!!file.url) {
            setPreview([file.url]);
          }

          setCurrentPreviewImage(0);
        }
      },
      [setPreview, setCurrentPreviewImage, multiple, value]
    );

    const compProps = React.useMemo(
      () => ({
        ref,
        listType:
          showUploadButton || showDragAndDrop ? 'picture' : 'picture-card',
        fileList: multiple ? value : !!value ? [value] : [],
        accept,
        ...rest,
        beforeUpload,
        onRemove,
        onPreview,
        multiple,
        maxCount
      }),
      [
        ref,
        showUploadButton,
        multiple,
        value,
        accept,
        beforeUpload,
        onRemove,
        onPreview,
        multiple,
        maxCount,
        rest
      ]
    );

    return (
      <React.Fragment>
        <DynamicComponent.Comp {...DynamicComponent.props}>
          {!showDragAndDrop ? (
            !!value && typeof value === 'string' && isFile ? (
              <Space>
                <Button
                  icon={<EyeOutlined />}
                  disabled={disabled || isLoading}
                  type={'dashed'}
                  onClick={() => RouterService.openInNewWindow(value)}>
                  View file
                </Button>

                <Popconfirm
                  title={'Are you sure to delete?'}
                  onConfirm={() => onRemove(value)}>
                  <Tooltip title={'Delete file'} placement={'bottom'}>
                    <Button icon={<DeleteOutlined />} danger />
                  </Tooltip>
                </Popconfirm>
              </Space>
            ) : (
              <Upload disabled={disabled || isLoading} {...compProps}>
                {((multiple && value.length < maxCount) || !value) &&
                  (showUploadButton ? (
                    <Button
                      icon={<UploadOutlined />}
                      disabled={disabled || isLoading}
                      type={'primary'}>
                      {title}
                    </Button>
                  ) : (
                    title
                  ))}
              </Upload>
            )
          ) : (
            <Upload.Dragger disabled={disabled || isLoading} {...compProps}>
              <Space direction={'vertical'} align={'center'}>
                <InboxOutlined className={'text-3xl'} />

                <CustomText className={'text-xl font-medium text-center'}>
                  {title}
                </CustomText>

                {subTitle && (
                  <CustomText
                    className={'text-base font-light text-center'}
                    type={'secondary'}>
                    {subTitle}
                  </CustomText>
                )}

                <CustomText className={'text-xs text-center'} type={'warning'}>
                  Click or drag file to this area to upload
                </CustomText>
              </Space>
            </Upload.Dragger>
          )}
        </DynamicComponent.Comp>

        {/* The below code is to show upload progress bar */}
        {((uploadOptions && isLoading) || !!progress) && (
          <Row
            className={'min-w-full'}
            size={CommonGutterConfig.gutterHorizontalBase}
            align={'middle'}>
            <Col>
              <CustomText className={'text-xs font-medium'}>
                {progressStatus} :&nbsp;
              </CustomText>
            </Col>

            <Col className={'flex-1'}>
              <Progress
                strokeColor={Colors.primaryColor}
                status={'active'}
                percent={progress}
              />
            </Col>
          </Row>
        )}

        {/* The below code is just for image preview */}
        <div
          style={{
            display: 'none'
          }}>
          <Image.PreviewGroup
            preview={{
              visible: preview.length,
              onVisibleChange: () => {
                setPreview([]);
              },
              current: currentPreviewImage
            }}>
            {preview.map((url, index) => {
              return <Image src={url} key={uniqueId + index} />;
            })}
          </Image.PreviewGroup>
        </div>
      </React.Fragment>
    );
  }
);

export default React.memo(CustomUpload);
