import React from 'react';
import { useEffect } from 'react';
import { GQL } from '../../../Apis';
import { AllSubscriptions } from '../../../Apis/allApis';

/**
 * Custom useQuery hook for managing the query and reFetching logic.
 */
function useSubscription({ queryKey, onNext, extraData }) {
    useEffect(() => {
        const unsubscribe = GQL.getInstance().subscribe({
            query: AllSubscriptions[queryKey]?.gql,
        },
        {
            next: (...args) => onNext(extraData, ...args),
        });

        return () => {
            unsubscribe();
        }
    }, [extraData]);
}

function UseSubscription({
    queryKey,
    onNext,
    extraData,
}) {
    return useSubscription({ queryKey, onNext, extraData });
}

export { useSubscription };
export default React.memo(UseSubscription);
