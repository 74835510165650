import {
  AndroidFilled,
  AppleFilled,
  FileSearchOutlined,
  ReloadOutlined
} from '@ant-design/icons';
import { Col, Divider, Row, Space } from 'antd';
import React from 'react';
import { Colors } from '../../../Common/Colors/colors';
import { CommonGutterConfig } from '../../../Configs/common';
import CustomText from '../../../Components/Atoms/CustomText';
import Toolbar from '../../../Components/Cells/Toolbar';
import ButtonGroup from '../../../Components/Molecules/ButtonGroup';
import './container.less';
import DisplayApps from '../../../Components/Cells/DisplayApps';
import ReactMarkdown from 'react-markdown';
import remarkGfm from 'remark-gfm';
import UseQuery from '../../../Components/Wrappers/UseQuery';
import { GithubQueries } from '../../../Apis/github';
import MessageService from '../../../Services/messageService';
import moment from 'moment';
import LoadingProvider from '../../../Components/Wrappers/LoadingProvider';

const Format = 'Do MMM YYYY, hh:mm A';

const Platforms = {
  ios: {
    key: 'ios',
    icon: <AppleFilled />,
    iconProps: {
      style: { color: Colors.ios }
    }
  },
  android: {
    key: 'android',
    icon: <AndroidFilled />,
    iconProps: {
      style: { color: Colors.android }
    }
  }
};

class ViewRelease extends React.PureComponent {
  constructor(props) {
    super(props);

    this.state = {
      deployedApps: Array.from({ length: 111 }).map((_, index) => ({
        ...(index % 2 === 1
          ? {
              platform: Platforms.ios.key
            }
          : {
              platform: Platforms.android.key
            }),

        clientName: `UrbanPiper-${index + 1}`,
        id: '123456-23456-87654-as9da97a-' + index
      }))
    };
  }

  renderTitle = ({ name, published_at }) => {
    return (
      <Space split={<Divider className={'mx-1'} type={'vertical'} />}>
        <CustomText className={'text-4xl font-bold'}>{name}</CustomText>

        <CustomText className={'text-sm'}>
          {moment(published_at).format(Format)}
        </CustomText>
      </Space>
    );
  };

  onReleaseStatus = () => {};

  renderActions =
    (refetch = () => {}) =>
    () => {
      return (
        <ButtonGroup
          buttons={[
            {
              title: 'Check release status',
              icon: <FileSearchOutlined />,
              onClick: this.onReleaseStatus,
              type: 'default'
            },
            {
              title: 'Refresh',
              icon: <ReloadOutlined />,
              onClick: refetch,
              type: 'dashed'
            }
          ]}
        />
      );
    };

  render() {
    const { deployedApps } = this.state;

    const {
      match: {
        params: { id }
      }
    } = this.props;

    return (
      <UseQuery
        queryKey={GithubQueries.getReleaseById.key}
        extraData={[id]}
        queryOptions={{
          onError: error => {
            MessageService.error(error.message);
          }
        }}>
        {({
          query: {
            data: { data: { release = {} } = {} } = {},
            isFetching,
            refetch
          }
        }) => {
          return (
            <LoadingProvider loading={isFetching}>
              <Row
                className={'min-w-full'}
                gutter={CommonGutterConfig.gutterVerticalBase}>
                <Col span={24}>
                  <Toolbar
                    title={this.renderTitle(release)}
                    renderActions={this.renderActions(refetch)}
                  />
                </Col>

                <Col span={24}>
                  <Space direction={'vertical'}>
                    <CustomText type={'secondary'} className={'text-sm'}>
                      Description:
                    </CustomText>

                    <ReactMarkdown remarkPlugins={[remarkGfm]}>
                      {release?.body}
                    </ReactMarkdown>
                  </Space>
                </Col>

                <Col span={24}>
                  <DisplayApps value={deployedApps} />
                </Col>
              </Row>
            </LoadingProvider>
          );
        }}
      </UseQuery>
    );
  }
}

export default ViewRelease;
