import { CheckOutlined, CloseOutlined } from '@ant-design/icons';
import * as Yup from 'yup';
import { Colors } from '../../../../../Common/Colors/colors';
import { CountryCodes } from '../../../../../Configs/countryCodes';

const IndiaConfig = CountryCodes.find(item => item.code === 'IN');

const tooltipConfig = title => ({
  title
});

const SwitchProps = {
  checkedChildren: <CheckOutlined />,
  unCheckedChildren: <CloseOutlined />
};

const AppDisplayConfigs = [
  {
    name: 'banners',
    label: 'Show banners'
  },
  {
    name: 'featuredItems',
    label: 'Show featured items'
  },
  {
    name: 'specialInstructionsTextbox',
    label: 'Show special instruction text view'
  },
  {
    name: 'skipLogin',
    label: 'Skip login'
  },
  {
    name: 'pickupOnly',
    label: 'Is pickup only'
  },
  {
    name: 'walletReload',
    label: 'Disable wallet reload'
  },
  {
    name: 'nearestStoreClosedAlert',
    label: 'Display nearest store closed alert'
  },
  {
    name: 'filter',
    label: 'Enable filter'
  },
  {
    name: 'sort',
    label: 'Enable sort'
  },
  {
    name: 'itemUpselling',
    label: 'Enable item upselling'
  },
  {
    name: 'deliverNow',
    label: 'Hide deliver now'
  },
  {
    name: 'lockedRewards',
    label: 'Hide locked rewards'
  },
  {
    name: 'points',
    label: 'Hide points'
  },
  {
    name: 'rewardDescription',
    label: 'Hide reward description'
  },
  {
    name: 'itemSearch',
    label: 'Item search enabled'
  },
  {
    name: 'orderPickup',
    label: 'Is order pickup enabled'
  }
];

const PickupAndDeliveryConfigs = [
  {
    name: 'alterateDeliveryTimeMessage',
    label: 'Show alternate delivery time message'
  },
  {
    name: 'reorder',
    label: 'Reorder enabled'
  }
].map(config => ({ ...config, inputConfig: { value: true } }));

const ExtraModuleConfigs = [
  {
    name: 'wallet',
    label: 'Show wallet'
  },
  {
    name: 'applyWalletCredits',
    label: 'Apply wallet credits'
  },
  {
    name: 'aboutUs',
    label: 'Show about us'
  },
  {
    name: 'faq',
    label: 'Show FAQ'
  },
  {
    name: 'help',
    label: 'Show help'
  },
  {
    name: 'notifications',
    label: 'Show notifications'
  },
  {
    name: 'offers',
    label: 'Show offers'
  },
  {
    name: 'referral',
    label: 'Show referral'
  },
  {
    name: 'rewards',
    label: 'Show rewards'
  },
  {
    name: 'storeLocation',
    label: 'Show store  location'
  }
].map(config => ({ ...config, inputConfig: { value: true } }));

const DisplaySwitches = (configs = []) => {
  return configs.map(config => {
    return {
      colConfig: { xs: 12, sm: 8, xxl: 4 },
      ...config,
      inputConfig: {
        formItemProps: {
          tooltip: tooltipConfig(`Turn on to ${config.label.toLowerCase()}.`)
        },
        value: false,
        ...config.inputConfig,
        name: 'switch',
        inputProps: { ...SwitchProps },
        validation: Yup.boolean()
      }
    };
  });
};

export const schema = [
  {
    colConfig: { xs: 24 },
    divider: { title: 'App styling data' }
  },
  {
    name: 'primaryColor',
    label: 'Primary color',
    colConfig: { xs: 24, sm: 4 },
    inputConfig: {
      value: Colors.primaryColor,
      formItemProps: {
        tooltip: tooltipConfig('This is your apps primary color')
      },
      inputProps: { placeholder: 'Enter primary color', type: 'color' },
      validation: Yup.string()
    }
  },
  {
    name: 'errorColor',
    label: 'Error color',
    colConfig: { xs: 24, sm: 4 },
    inputConfig: {
      value: Colors.red,
      formItemProps: {
        tooltip: tooltipConfig('This is your apps error color')
      },
      inputProps: { placeholder: 'Enter error color', type: 'color' },
      validation: Yup.string()
    }
  },
  {
    colConfig: { xs: 24 },
    divider: { title: 'App information' }
  },
  {
    name: 'aboutUsUrl',
    label: 'About us link',
    colConfig: { xs: 24, sm: 6 },
    inputConfig: {
      formItemProps: {
        tooltip: tooltipConfig('About us URL for your app.')
      },
      inputProps: { placeholder: 'Enter about us URL' },
      validation: Yup.string().url('About us should be a URL')
    }
  },
  {
    name: 'helpEmail',
    label: 'Help email',
    colConfig: { xs: 24, sm: 6 },
    inputConfig: {
      formItemProps: {
        tooltip: tooltipConfig('Support help email for users to reach you out.')
      },
      inputProps: { placeholder: 'Enter help email.' },
      validation: Yup.string().email('Help email is not valid email.')
    }
  },
  {
    name: 'helpCountryCode',
    label: 'Country letter code',
    colConfig: { xs: 24, sm: 6 },
    inputConfig: {
      name: 'select',
      value: IndiaConfig.code,
      data: CountryCodes.map(item => ({
        value: item.code,
        label: `${item.name} (${item.code})`
      })),
      formItemProps: {
        tooltip: tooltipConfig('Your country letter code.')
      },
      inputProps: {
        placeholder: 'Enter country letter code.',
        showSearch: true,
        filterOption: (input, option) =>
          option.children.toLowerCase().includes(input.toLowerCase())
      },
      validation: Yup.string()
    }
  },
  {
    name: 'countryISDCode',
    label: 'ISD code',
    colConfig: { xs: 6, sm: 2 },
    inputConfig: {
      value: IndiaConfig.dialCode,
      derived: {
        names: ['countryLetterCode'],
        function: ({ countryLetterCode }) => {
          const country = CountryCodes.find(
            item => item.code === countryLetterCode
          );

          return country.dialCode;
        }
      },
      formItemProps: {
        tooltip: tooltipConfig('Your country ISD code.')
      },
      inputProps: {
        placeholder: 'Enter country ISD code.',
        disabled: true
      },
      validation: Yup.string()
    }
  },
  {
    name: 'helpPhone',
    label: 'Help phone',
    colConfig: { xs: 18, sm: 4 },
    inputConfig: {
      formItemProps: {
        tooltip: tooltipConfig(
          'Support help phone number for users to reach you out.'
        )
      },
      inputProps: { placeholder: 'Enter help phone.' },
      validation: Yup.string().test({
        name: 'phoneValidation',
        message: 'Help phone not valid',
        exclusive: true,
        test: function (value) {
          const regex =
            CountryCodes.find(
              item => item.code === this.parent.countryLetterCode
            )?.regex || '';

          return !regex || new RegExp(regex, 'gi').test(value);
        }
      })
    }
  },
  {
    name: 'websiteUrl',
    label: 'Website URL',
    colConfig: { xs: 24, sm: 12, xxl: 10 },
    inputConfig: {
      value: '',
      inputProps: {
        placeholder: 'Enter website url'
      },
      formItemProps: {
        tooltip: tooltipConfig(
          'Enter website URL for universal linking(Deep-Link) and marketing'
        )
      },
      validation: Yup.string().url()
    }
  },
  {
    colConfig: { xs: 24 },
    divider: { title: 'Google keys' }
  },
  {
    name: 'googlePlacesAPIKeyAndroid',
    label: 'Google places API key android',
    colConfig: { xs: 24, md: 12, xxl: 7 },
    inputConfig: {
      type: 'Password',
      inputProps: { placeholder: 'Enter google places api key for android' },
      formItemProps: {
        tooltip: tooltipConfig(
          'Google places api key for searching places on android devices.'
        )
      },
      validation: Yup.string()
    }
  },
  {
    name: 'googlePlacesAPIKeyIOS',
    label: 'Google places API key iOS',
    colConfig: { xs: 24, md: 12, xxl: 7 },
    inputConfig: {
      type: 'Password',
      inputProps: { placeholder: 'Enter google places api key for iOS' },
      formItemProps: {
        tooltip: tooltipConfig(
          'Google places api key for searching places on iOS devices.'
        )
      },
      validation: Yup.string()
    }
  },
  {
    name: 'googlesignInWebClientId',
    label: 'Google signIn web client id',
    colConfig: { xs: 24, md: 12, xxl: 7 },
    inputConfig: {
      type: 'Password',
      inputProps: { placeholder: 'Enter google sign in web client id' },
      formItemProps: {
        tooltip: tooltipConfig(
          'Google sign in web client for Google login purposes.'
        )
      },
      validation: Yup.string()
    }
  },
  {
    name: 'socialLogin',
    label: 'Enable social login',
    colConfig: { xs: 12, md: 4, xxl: 3 },
    inputConfig: {
      name: 'switch',
      value: true,
      inputProps: { ...SwitchProps },
      formItemProps: {
        tooltip: tooltipConfig('Turn on to enable social logins.')
      },
      validation: Yup.boolean()
    }
  },
  {
    colConfig: { xs: 24 },
    divider: { title: 'App display config' }
  },
  ...DisplaySwitches(AppDisplayConfigs),
  {
    colConfig: { xs: 24 },
    divider: { title: 'Pickup and delivery configurations' }
  },
  {
    name: 'orderDeliveryOffsetSeconds',
    label: 'Order delivery offset secs',
    colConfig: { xs: 24, sm: 6 },
    inputConfig: {
      value: 0,
      inputProps: {
        placeholder: 'Enter order delivery offset secs',
        type: 'number'
      },
      formItemProps: {
        tooltip: tooltipConfig(
          'Offset time from current time for the delivery.'
        )
      },
      validation: Yup.number().min(
        0,
        'Order delivery offset secs must be greater than or equal to ${min}'
      )
    }
  },
  {
    name: 'orderDeliveryMessage',
    label: 'Order details delivery message',
    colConfig: { xs: 24, sm: 12, xxl: 10 },
    inputConfig: {
      value: '',
      inputProps: {
        placeholder: 'Enter order details delivery message'
      },
      formItemProps: {
        tooltip: tooltipConfig('Message to be displayed on order details.')
      },
      validation: Yup.string()
    }
  },
  ...DisplaySwitches(PickupAndDeliveryConfigs),
  {
    colConfig: { xs: 24 },
    divider: { title: 'Extra configs' }
  },
  ...DisplaySwitches(ExtraModuleConfigs),
  {
    colConfig: { xs: 24 },
    divider: { title: 'Additional Configs' }
  },
  {
    name: 'facebookAppId',
    label: 'Facebook App ID',
    colConfig: { xs: 24, sm: 12, xxl: 10 },
    inputConfig: {
      value: 0,
      inputProps: {
        placeholder: 'Enter facebook app id',
        type: 'number'
      },
      formItemProps: {
        tooltip: tooltipConfig('Facebook app id')
      },
      validation: Yup.number().integer().moreThan(0)
    }
  },
  {
    name: 'deepLinkScheme',
    label: 'Deep-Link Scheme',
    colConfig: { xs: 24, sm: 12, xxl: 10 },
    inputConfig: {
      value: '',
      inputProps: {
        placeholder: 'Enter deep link scheme'
      },
      formItemProps: {
        tooltip: tooltipConfig('Deep link scheme')
      },
      validation: Yup.string()
    }
  },
  {
    name: 'branchKeyTest',
    label: 'Branch Key (Test)',
    colConfig: { xs: 24, sm: 12, xxl: 10 },
    inputConfig: {
      value: '',
      inputProps: {
        placeholder: 'Enter test branch test key'
      },
      formItemProps: {
        tooltip: tooltipConfig('Branch key test')
      },
      validation: Yup.string()
    }
  },
  {
    name: 'branchKeyLive',
    label: 'Branch Key (Live)',
    colConfig: { xs: 24, sm: 12, xxl: 10 },
    inputConfig: {
      value: '',
      inputProps: {
        placeholder: 'Enter test branch live key'
      },
      formItemProps: {
        tooltip: tooltipConfig('Branch key live')
      },
      validation: Yup.string()
    }
  }
];
