import {
  ExperimentOutlined,
  RocketOutlined,
  VerticalAlignTopOutlined
} from '@ant-design/icons';
import {
  BackTop,
  Col,
  Divider,
  Layout,
  Menu,
  Row,
  Space,
  Tag,
  Tooltip
} from 'antd';
import React from 'react';
import { Colors } from '../../Common/Colors/colors';
import CustomImage from '../../Components/Atoms/CustomImage';
import CustomText from '../../Components/Atoms/CustomText';
import Footer from '../../Components/Molecules/Footer';
import UseBreakPoint from '../../Components/Wrappers/UseBreakPoint';
import { DashboardName } from '../../Configs/app';
import { SideBarContents } from '../../Configs/routes';
import AdminRoutes from '../../Routes/adminRoutes';
import ModalService from '../../Services/modalService';
import { Images } from '../../Shared/images';
import './container.less';
import { NavLink } from 'react-router-dom';
import UserAvatar from './Components/UserAvatar';
import MessageService from '../../Services/messageService';
import { Metrics } from '../../Common/Sizes/metrics';
import { PRODUCTION_MODE } from '../../Configs/environment';

const DashboardContext = React.createContext();
const ContentContainerId = 'content-container';

class Dashboard extends React.PureComponent {
  constructor(props) {
    super(props);

    this.state = {
      collapsed: true,
      selectedKey: null,
      context: {
        setDashboardHeader: this.setDashboardHeader
      },
      dashboardProps: {}
    };

    this.siderItems = Object.values(SideBarContents).map(item => ({
      key: item.key,
      path: item.path,
      label: item.label,
      icon: item.icon
    }));
  }

  componentDidMount() {
    this.setActiveMenuItemFromRouteChanges();
  }

  onLogoutSuccess = () => {
    this.props.logoutUser();

    ModalService.destroyAll();
  };

  onFailure = () => {
    MessageService.error('Logout error');
  };

  scrollToTop = () => {
    const container = document.getElementById(ContentContainerId);
    container.scroll({ top: 0, behavior: 'smooth' });
  };

  componentDidUpdate(prevProps) {
    if (this.props.location.pathname !== prevProps.location.pathname) {
      this.setActiveMenuItemFromRouteChanges();

      /**
       * Go to top of the screen when routing between screens
       */
      this.scrollToTop();

      this.setDashboardHeader(null);
    }
  }

  setActiveMenuItemFromRouteChanges = () => {
    const {
      location: { pathname },
      match: { path }
    } = this.props;

    const selectedRoute = Object.values(SideBarContents).find(item =>
      pathname.includes(path + item.path)
    );

    if (selectedRoute) this.setState({ selectedKey: selectedRoute.key });
  };

  setDashboardHeader = reactNode => {
    const updateState = () =>
      this.setState({ dashboardProps: { dashboardHeader: reactNode } });

    if (!!reactNode) {
      setTimeout(updateState, 0);
    } else updateState();
  };

  toggleCollapse = () => {
    this.setState(prevState => ({ collapsed: !prevState.collapsed }));
  };

  onSiderMenuItemSelect = ({ key }) => {
    this.setState({ selectedKey: key });
  };

  render() {
    const { Header, Sider, Content } = Layout;
    const { collapsed, selectedKey, dashboardProps, context } = this.state;
    const {
      match: { path },
      userData
    } = this.props;

    return (
      <UseBreakPoint>
        {({ xs, sm, lg }) => (
          <Layout className={'h-screen'}>
            <Header className={'dashboard-header flex items-center px-6'}>
              <Row className={'min-w-full'} justify={'space-between'}>
                <Col>
                  <NavLink to={path}>
                    <Space>
                      <CustomImage
                        imageList={Images.urbanPiperLogo}
                        imgClassName={'w-22 h-12 object-contain'}
                      />

                      <CustomText
                        className={
                          'text-2xl font-bold align-middle select-none'
                        }>
                        {DashboardName}
                      </CustomText>
                    </Space>
                  </NavLink>
                </Col>
                <Col>
                  <Space>
                    <Tooltip title={'Environment'} placement={'left'}>
                      {!!PRODUCTION_MODE ? (
                        <Tag color={Colors.red} icon={<RocketOutlined />}>
                          Prod
                        </Tag>
                      ) : (
                        <Tag
                          color={Colors.orange}
                          icon={<ExperimentOutlined />}>
                          Test
                        </Tag>
                      )}
                    </Tooltip>

                    <UserAvatar
                      userData={userData}
                      onLogoutSuccess={this.onLogoutSuccess}
                      onFailure={this.onFailure}
                    />
                  </Space>
                </Col>
              </Row>
            </Header>

            <Divider className={'m-0'} />

            <Layout>
              <Sider
                collapsible
                defaultCollapsed
                collapsed={collapsed}
                width={'14vw'}
                collapsedWidth={(xs || sm) && !lg ? 0 : '5vw'}
                zeroWidthTriggerStyle={{
                  backgroundColor: Colors.primaryColor
                }}
                onCollapse={this.toggleCollapse}>
                <div className={'dashboard-sider-container h-full'}>
                  <Menu
                    mode={'inline'}
                    selectedKeys={[selectedKey]}
                    items={this.siderItems}
                    className={'dashboard-sider-container'}
                    onSelect={this.onSiderMenuItemSelect}
                  />
                </div>
              </Sider>

              <Divider type={'vertical'} className={'m-0 min-h-full'} />

              <Layout>
                <Content
                  id={ContentContainerId}
                  style={{ padding: Metrics.medium }}
                  className={`content-container`}>
                  <DashboardContext.Provider value={context}>
                    {dashboardProps.dashboardHeader &&
                      React.isValidElement(dashboardProps.dashboardHeader) && (
                        <Header
                          className={
                            'dashboard-content-header flex items-center px-0 mb-2'
                          }>
                          {dashboardProps.dashboardHeader}
                        </Header>
                      )}

                    <div className={'content-sub-container min-h-full p-4'}>
                      <AdminRoutes />
                    </div>
                  </DashboardContext.Provider>

                  <div className={'mt-4'}>
                    <Footer showCaption left showDivider />
                  </div>

                  <BackTop
                    className={'back-top-container'}
                    target={() => document.getElementById(ContentContainerId)}>
                    <div
                      className={
                        'back-top text-center flex justify-center items-center'
                      }>
                      <Tooltip title={'Go to top'} placement={'left'}>
                        <VerticalAlignTopOutlined className={'flex text-2xl'} />
                      </Tooltip>
                    </div>
                  </BackTop>
                </Content>
              </Layout>
            </Layout>
          </Layout>
        )}
      </UseBreakPoint>
    );
  }
}

export default Dashboard;

export const withDashboard = RCTComponent =>
  React.forwardRef((props, ref) => (
    <DashboardContext.Consumer>
      {context => {
        return <RCTComponent {...props} ref={ref} {...context} />;
      }}
    </DashboardContext.Consumer>
  ));
