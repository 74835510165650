import { Switch } from 'antd';
import React from 'react';

const CustomSwitch = React.forwardRef(
  ({ onChange = () => {}, onBlur = () => {}, value, name, ...rest }, ref) => {
    const onCheckChange = React.useCallback(
      checked => {
        const event = { target: { value: checked, name } };

        onChange(event);

        setTimeout(() => onBlur(event), 100);
      },
      [onChange, onBlur, name]
    );

    return (
      <Switch ref={ref} onChange={onCheckChange} checked={value} {...rest} />
    );
  }
);

export default React.memo(CustomSwitch);
