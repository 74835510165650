import { RouteNames } from '../Configs/routes';

let _history = null;

export const setHistoryRef = history => {
  _history = history;
};

const pushRoute = (path, state) => {
  _history?.push?.(path, state);
};

const replaceRoute = (path, state) => {
  _history?.replace?.(path, state);
};

const goBack = () => {
  _history?.goBack?.();
};

const openInNewWindow = (path, blank = false) => {
  window.open(
    path,
    ...(blank ? ['_blank', 'toolbar=0', 'location=0', 'menubar=0'] : [])
  );
};

const closeCurrentWindow = () => {
  window.close();
};

const showServerError = props => {
  replaceRoute(RouteNames.ERROR, { title: '500', ...props, status: '500' });
};

const showNotFound = props => {
  replaceRoute(RouteNames.ERROR, { ...props, status: '404' });
};

const showNotAuthorized = props => {
  replaceRoute(RouteNames.ERROR, {
    title: '403',
    subTitle: 'Sorry, you are not authorized to access this page.',
    ...props,
    status: '403'
  });
};

const RouterService = {
  pushRoute,
  replaceRoute,
  goBack,
  showServerError,
  showNotFound,
  showNotAuthorized,
  openInNewWindow,
  closeCurrentWindow
};

export default RouterService;
