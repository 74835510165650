import {
  AndroidFilled,
  AndroidOutlined,
  ApiFilled,
  ApiOutlined,
  AppleFilled,
  AppleOutlined,
  BugFilled,
  BugOutlined,
  DatabaseFilled,
  DatabaseOutlined
} from '@ant-design/icons';
import { Col, Row } from 'antd';
import React from 'react';
import { Colors } from '../../../Common/Colors/colors';
import CustomTab from '../../../Components/Molecules/CustomTab';
import { withDashboard } from '../../../Layouts/Dashboard';
import DashboardHeader from './Components/DashboardHeader';
import AppConfigTab from './Tabs/AppConfig';
import AndroidTab from './Tabs/Android';
import IOSTab from './Tabs/IOS';
import Integrations from './Tabs/Integrations';
import Builds from './Tabs/Builds/container';
import { useCustomQuery } from '../../../Components/Wrappers/UseQuery';
import { ClientQueries } from '../../../Apis/clients';
import MessageService from '../../../Services/messageService';
import RouterService from '../../../Services/routerService';
import {
  setCurrentRouteTitle,
  stripFaultyData
} from '../../../Services/generalServices';
import { StatusCodes } from '../../../Apis';

const Tabs = [
  {
    label: 'App Configs',
    key: 'appConfigs',
    icon: DatabaseOutlined,
    activeIcon: DatabaseFilled,
    color: Colors.purple,
    component: <AppConfigTab />
  },
  {
    label: 'Android',
    key: 'android',
    icon: AndroidOutlined,
    activeIcon: AndroidFilled,
    color: Colors.android,
    component: <AndroidTab />
  },
  {
    label: 'iOS',
    key: 'ios',
    icon: AppleOutlined,
    activeIcon: AppleFilled,
    color: Colors.ios,
    component: <IOSTab />
  },
  // {
  //   label: 'Integrations',
  //   key: 'integrations',
  //   icon: ApiOutlined,
  //   activeIcon: ApiFilled,
  //   color: Colors.blue,
  //   component: <Integrations />
  // },
  {
    label: 'Builds',
    key: 'builds',
    icon: BugOutlined,
    activeIcon: BugFilled,
    color: Colors.indigo,
    component: <Builds />
  }
];
const TabQueryKey = 'tab';

function ViewClient({
  match: {
    path,
    params: { id }
  },
  location: { state, search } = {},
  setDashboardHeader = () => {},
  title = () => {}
}) {
  const setCurrentKeyToQueryParam = React.useCallback(key => {
    key = key ?? Tabs[0].key;

    const params = new URLSearchParams();
    params.set(TabQueryKey, key);
    RouterService.replaceRoute({ search: params.toString() });
  }, []);

  React.useEffect(() => {
    if (!search) {
      setCurrentKeyToQueryParam();
    } else {
      const key = new URLSearchParams(search).get(TabQueryKey);

      if (!Tabs.some(tab => tab.key === key)) {
        const tab = Tabs.find(tab =>
          new RegExp(stripFaultyData(key), 'gi').test(tab.key)
        );

        setCurrentKeyToQueryParam(tab ? tab.key : null);
      }
    }
  }, []);

  const currentTabKey = React.useMemo(() => {
    const key = new URLSearchParams(search).get(TabQueryKey);

    return key;
  }, [path]);

  const {
    query: { data, refetch }
  } = useCustomQuery({
    queryKey: ClientQueries.getClientById.key,
    queryOptions: {
      initialData: { data: { ...state } },
      onError: error => {
        MessageService.error(error.message);

        switch (error.statusCode) {
          case StatusCodes.SERVER_ERROR:
            RouterService.showServerError({
              subTitle: error.message
            });
            break;

          case StatusCodes.NOT_FOUND:
            RouterService.showNotFound({
              subTitle: error.message
            });
            break;
        }
      }
    },
    extraData: [id]
  });

  React.useEffect(() => {
    setDashboardHeader(
      <DashboardHeader data={data?.data} onEditSuccess={refetch} />
    );

    setCurrentRouteTitle(title(data?.data?.bizName));
  }, [data.data, setDashboardHeader, id, title]);

  const onTabChange = React.useCallback(
    key => {
      const params = new URLSearchParams();
      params.set(TabQueryKey, key);
      RouterService.pushRoute({ search: params.toString() });
    },
    [path]
  );

  return (
    <Row className={'min-w-full'}>
      <Col span={24}>
        <CustomTab
          tabBarGutter={8}
          defaultActiveKey={currentTabKey}
          tabs={Tabs}
          tabComponentProps={{ id }}
          onChange={onTabChange}
        />
      </Col>
    </Row>
  );
}

export default withDashboard(ViewClient);
