import Axios from '.';

const AssetsApiRoutes = {
  base: '/assets/app'
};

export const AssetsQueries = {};

/**
 * A generic function which attaches the config, route and payload
 */
const getApiWithConfig =
  (route = '') =>
  // These below params are sent during mutation call
  ({ appId, payload, config }) =>
    Axios.getInstance().post(
      `${AssetsApiRoutes.base}/${appId}/${route}`,
      payload,
      config
    );

export const AssetsMutation = {
  uploadLogo: {
    key: 'uploadLogo',
    api: getApiWithConfig('logo')
  },
  uploadBanner: {
    key: 'uploadBanner',
    api: getApiWithConfig('banner')
  },
  uploadScreenshots: {
    key: 'uploadScreenshots',
    api: ({ payload, appId, config, size = 'large' }) =>
      Axios.getInstance().post(
        `${AssetsApiRoutes.base}/${appId}/screenshots?size=${size}`,
        payload,
        config
      )
  },
  uploadSplashScreen: {
    key: 'uploadSplashScreen',
    api: getApiWithConfig('splashScreen')
  },
  uploadLauncherIcon: {
    key: 'uploadLauncherIcon',
    api: getApiWithConfig('launcherIcon')
  },
  uploadNotificationIcon: {
    key: 'uploadNotificationIcon',
    api: getApiWithConfig('notificationIcon')
  },
  deleteScreenshotById: {
    key: 'deleteScreenshotById',
    api: ({ appId, index, size = 'large' }) =>
      Axios.getInstance().delete(
        `${AssetsApiRoutes.base}/${appId}/screenshots/${index}?size=${size}`
      )
  },
  uploadServicesJsonFile: {
    key: 'uploadServicesJsonFile',
    api: getApiWithConfig('config/json')
  },
  uploadServicesPlistFile: {
    key: 'uploadServicesPlistFile',
    api: getApiWithConfig('config/plist')
  },
  uploadPlayStoreServicesJsonFile: {
    key: 'uploadPlayStoreServicesJsonFile',
    api: getApiWithConfig('googleplay/json')
  },
  uploadAppCenterJsonFile: {
    key: 'uploadAppCenterJsonFile',
    api: getApiWithConfig('appcenter/json')
  },
  uploadAppCenterPlistFile: {
    key: 'uploadAppCenterPlistFile',
    api: getApiWithConfig('appcenter/plist')
  }
};
