import * as Yup from 'yup';
import { ClientStatuses } from '../../../../../Configs/common';
import { AddClientSchema } from '../../../AllClients/Components/AddClientModal/schema';

const AddClientSchemaClone = AddClientSchema.map(item => ({
  ...item,
  inputConfig: {
    ...item.inputConfig,
    inputProps: {
      ...item.inputConfig.inputProps,
      disabled: true
    }
  }
}));

export const Schemes = [
  ...AddClientSchemaClone,
  {
    name: 'status',
    label: 'Status',
    colConfig: { span: 24 },
    inputConfig: {
      required: true,
      name: 'select',
      value: [],
      data: Object.values(ClientStatuses).map(item => ({
        value: item.key,
        label: item.label
      })),
      formItemProps: {
        tooltip: 'Status of the client'
      },
      inputProps: { placeholder: 'Enter status' },
      validation: Yup.string()
    }
  }
];
