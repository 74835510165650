import * as Yup from 'yup';

const ApiUsernameRegex = /^biz_adm[a-zA-Z0-9_]+$/;

export const AddClientSchema = [
  {
    name: 'bizLogoUrl',
    label: 'Logo',
    colConfig: { xs: 24, md: 12 },
    inputConfig: {
      required: true,
      value: '',
      formItemProps: {
        tooltip: 'Logo URL of the client'
      },
      inputProps: {
        placeholder: 'Enter logo URL',
        autoFocus: true
      },
      validation: Yup.string().url('Invalid URL')
    }
  },
  {
    name: 'bizName',
    label: 'Biz name',
    colConfig: { xs: 24, md: 12 },
    inputConfig: {
      required: true,
      formItemProps: {
        tooltip: 'Biz name assigned by UrbanPiper.'
      },
      inputProps: { placeholder: 'Enter biz name.' },
      validation: Yup.string()
    }
  },
  {
    name: 'bizId',
    label: 'Biz Id',
    colConfig: { xs: 24, md: 12 },
    inputConfig: {
      required: true,
      formItemProps: {
        tooltip: 'A unique id assigned to the users by UrbanPiper.'
      },
      inputProps: { type: 'number', placeholder: 'Enter biz id' },
      validation: Yup.number()
        .integer()
        .positive()
        .moreThan(10000000, 'Biz id is not valid')
        .lessThan(99999999, 'Biz id is not valid')
        .required('Required')
    }
  },
  {
    name: 'bizApiKey',
    label: ' API key',
    colConfig: { xs: 24, md: 12 },
    inputConfig: {
      required: true,
      formItemProps: {
        tooltip: 'A unique key assigned to the users by UrbanPiper.'
      },
      inputProps: {
        placeholder: 'Enter API key'
      },
      validation: Yup.string().length(40, 'Length should be ${length}')
    }
  },
  {
    name: 'apiUsername',
    label: ' API username',
    inputConfig: {
      required: true,
      formItemProps: {
        tooltip: 'A unique username assigned to the users by UrbanPiper.'
      },
      inputProps: {
        placeholder: 'Enter API username'
      },
      validation: Yup.string().matches(
        ApiUsernameRegex,
        'API username must be alpha numeric and only "_" special character is allowed.'
      )
    }
  }
];
