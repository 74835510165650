import React from 'react';
import { ClientMutations } from '../../../../../Apis/clients';
import FormInput from '../../../../../Components/Cells/FormInput';
import { useCustomMutation } from '../../../../../Components/Wrappers/UseMutation';
import MessageService from '../../../../../Services/messageService';
import { Schemes } from './schema';

function EditClientConfig({
  defaultValues,
  clientId = '',
  onEditSuccess,
  closeModal
}) {
  const { isLoading, mutateAsync: editClient } = useCustomMutation({
    mutationKey: ClientMutations.editClient.key
  });

  const onSubmit = React.useCallback(
    values => {
      editClient({ payload: values, clientId })
        .then(() => {
          MessageService.success('Client updated successfully!!!');
          closeModal();
          onEditSuccess();
        })
        .catch(err => {
          MessageService.error(err.message);
        });
    },
    [closeModal, onEditSuccess, clientId]
  );

  return (
    <FormInput
      loading={isLoading}
      schemes={Schemes}
      defaultValues={defaultValues}
      onSubmit={onSubmit}
    />
  );
}

export default React.memo(EditClientConfig);
