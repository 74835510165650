import * as Yup from 'yup';
import { AssetsMutation } from '../../../../../Apis/assets';
import {
  DISPLAY_NAME_LENGTH,
  IOS_FILE_VALIDATION,
  NAME_SUBTITLE_LENGTH
} from '../../../../../Configs/common';
import { validateFile } from '../../../../../Services/generalServices';

const tooltipConfig = title => ({
  title
});

export const schema = [
  {
    colConfig: { xs: 24 },
    divider: { title: 'App information' }
  },
  {
    name: 'displayName',
    label: 'Name',
    colConfig: { xs: 24, sm: 8 },
    inputConfig: {
      formItemProps: {
        tooltip: tooltipConfig('App display name')
      },
      inputProps: { placeholder: 'Enter app name' },
      validation: Yup.string().max(
        DISPLAY_NAME_LENGTH,
        'Name must be at most ${max} characters'
      )
    }
  },
  {
    name: 'applicationId',
    label: 'Package name',
    colConfig: { xs: 24, sm: 8 },
    inputConfig: {
      formItemProps: {
        tooltip: tooltipConfig('Package name')
      },
      inputProps: { placeholder: 'Enter package name' },
      validation: Yup.string()
    }
  },
  {
    name: 'teamId',
    label: 'Team Id',
    colConfig: { xs: 24, sm: 8 },
    inputConfig: {
      formItemProps: {
        tooltip: tooltipConfig('Team Id')
      },
      inputProps: { placeholder: 'Enter team id' },
      validation: Yup.string()
    }
  },
  {
    colConfig: { xs: 24 },
    divider: { title: 'Store information' }
  },
  {
    name: 'developerAccountName',
    label: 'Developer account name',
    colConfig: { xs: 24, sm: 8 },
    inputConfig: {
      formItemProps: {
        tooltip: tooltipConfig(
          'Developer account name where the app is hosted.'
        )
      },
      inputProps: { placeholder: 'Enter developer account name' },
      validation: Yup.string()
    }
  },
  {
    name: 'name',
    label: 'App name',
    colConfig: { xs: 24, sm: 6 },
    inputConfig: {
      type: 'TextArea',
      formItemProps: {
        tooltip: tooltipConfig(
          'This is how your app will appear on Play Store.'
        )
      },
      inputProps: {
        placeholder: 'Enter app name',
        autoSize: {
          minRows: 1,
          maxRows: 1
        },
        maxLength: NAME_SUBTITLE_LENGTH,
        showCount: true
      },
      validation: Yup.string().max(
        NAME_SUBTITLE_LENGTH,
        'App name must be at most ${max} characters'
      )
    }
  },
  {
    name: 'subTitle',
    label: 'Sub title',
    colConfig: { xs: 24, sm: 10 },
    inputConfig: {
      type: 'TextArea',
      formItemProps: {
        tooltip: tooltipConfig(
          'A short description for your app. Users can expand to view your full description.'
        )
      },
      inputProps: {
        placeholder: 'Enter sub title',
        autoSize: {
          minRows: 1,
          maxRows: 1
        },
        maxLength: NAME_SUBTITLE_LENGTH,
        showCount: true
      },
      validation: Yup.string().max(
        NAME_SUBTITLE_LENGTH,
        'Subtitle must be at most ${max} characters'
      )
    }
  },
  {
    name: 'keywords',
    label: 'Keywords',
    colConfig: { xs: 24, sm: 12 },
    inputConfig: {
      type: 'TextArea',
      formItemProps: {
        tooltip: tooltipConfig(
          'Include one or more keywords that describe your app. Keywords make search results more accurate. Separate keywords with an English comma, Chinese comma, or a mix of both.'
        )
      },
      inputProps: {
        autoSize: {
          minRows: 1,
          maxRows: 1
        },
        placeholder: 'Enter keywords',
        maxLength: 100,
        showCount: true
      },
      validation: Yup.string().trim().max(100)
    }
  },
  {
    name: 'promotionalText',
    label: 'Promotional text',
    colConfig: { xs: 24, sm: 12 },
    inputConfig: {
      formItemProps: {
        tooltip: tooltipConfig(
          'Promotional text lets you inform your visitors of any current app features without requiring an updated submission.'
        )
      },
      inputProps: {
        placeholder: 'Enter promotional text'
      },
      validation: Yup.string()
    }
  },
  {
    name: 'description',
    label: 'Description',
    colConfig: { xs: 24 },
    inputConfig: {
      type: 'TextArea',
      formItemProps: {
        tooltip: tooltipConfig(
          'A description of your app, detailing features and functionality.'
        )
      },
      inputProps: {
        placeholder: 'Enter description',
        maxLength: 4000,
        showCount: true,
        autoSize: {
          minRows: 4,
          maxRows: 8
        }
      },
      validation: Yup.string().trim().max(4000)
    }
  },
  {
    colConfig: { xs: 24 },
    divider: { title: 'App privacy and copyright' }
  },
  {
    name: 'supportUrl',
    label: 'Support URL',
    colConfig: { xs: 24, md: 12 },
    inputConfig: {
      formItemProps: {
        tooltip: tooltipConfig('A URL with support information for your app.')
      },
      inputProps: {
        placeholder: 'Enter support Url'
      },
      validation: Yup.string().url()
    }
  },
  {
    name: 'supportEmail',
    label: 'Support email',
    colConfig: { xs: 24, md: 12 },
    inputConfig: {
      formItemProps: {
        tooltip: tooltipConfig('An email for support for your app.')
      },
      inputProps: {
        placeholder: 'Enter support email'
      },
      validation: Yup.string().email()
    }
  },
  {
    name: 'marketingUrl',
    label: 'Marketing URL',
    colConfig: { xs: 24, md: 12 },
    inputConfig: {
      formItemProps: {
        tooltip: tooltipConfig(
          'A URL with marketing information about your app.'
        )
      },
      inputProps: {
        placeholder: 'Enter marketing Url'
      },
      validation: Yup.string().url().optional()
    }
  },
  {
    name: 'privacyUrl',
    label: 'Privacy policy URL',
    colConfig: { xs: 24, md: 12 },
    inputConfig: {
      formItemProps: {
        tooltip: tooltipConfig(
          'A URL that links to your privacy policy. A privacy policy is required for all apps.'
        )
      },
      inputProps: {
        placeholder: 'Enter privacy policy Url'
      },
      validation: Yup.string().url()
    }
  },
  {
    name: 'copyright',
    label: 'Copyright',
    colConfig: { xs: 24, md: 12 },
    inputConfig: {
      formItemProps: {
        tooltip: tooltipConfig(
          'The name of the person or entity that owns the exclusive rights to your app, preceded by the year the rights were obtained (for example, "2008 Acme Inc."). Do not provide a URL.'
        )
      },
      inputProps: {
        placeholder: 'Enter copyright'
      },
      validation: Yup.string()
    }
  },
  {
    colConfig: { span: 24 },
    divider: { title: 'Code push configs' }
  },
  {
    name: 'codePushDeploymentKey',
    label: 'Code push deployment key',
    colConfig: { xs: 24, sm: 10 },
    inputConfig: {
      type: 'Password',
      value: '',
      inputProps: { placeholder: 'Enter code push deployment key' },
      formItemProps: {
        tooltip: tooltipConfig(
          'Key for code-push deployment, This is required for sending in-app updates.'
        )
      },
      validation: Yup.string()
    }
  },
  {
    name: 'codePushAppName',
    label: 'Code push app name',
    colConfig: { xs: 24, sm: 8 },
    inputConfig: {
      value: '',
      inputProps: { placeholder: 'Enter code push app name' },
      formItemProps: {
        tooltip: tooltipConfig(
          'The app name is required to target that particular app for the OTA update'
        )
      },
      validation: Yup.string()
    }
  },
  {
    name: 'appcenterPlist',
    label: 'App center config .plist file',
    colConfig: { xs: 24, sm: 6 },
    inputConfig: {
      name: 'file',
      formItemProps: {
        tooltip: tooltipConfig('App center config .plist file for iOS updates')
      },
      inputProps: {
        title: 'Upload .plist file',
        accept: '.plist',
        isFile: true,
        showUploadButton: true,
        uploadOptions: { key: AssetsMutation.uploadAppCenterPlistFile.key }
      },
      validation: Yup.mixed()
    }
  },
  {
    colConfig: { xs: 24 },
    divider: { title: 'Firebase configurations' }
  },
  {
    name: 'serviceConfigPlist',
    label: 'Google services .plist file',
    colConfig: { xs: 24, sm: 6 },
    inputConfig: {
      name: 'file',
      formItemProps: {
        tooltip: tooltipConfig('Google services .plist file for iOS updates')
      },
      inputProps: {
        title: 'Upload .plist file',
        accept: '.plist',
        isFile: true,
        showUploadButton: true,
        uploadOptions: {
          key: AssetsMutation.uploadServicesPlistFile.key
        }
      },
      validation: Yup.mixed()
    }
  }
];

export const storeAssetSchemes = [
  {
    colConfig: { span: 24 },
    divider: { title: 'App screenshots' }
  },
  {
    name: 'screenshotsSmall',
    label: 'Screenshots 5.5" display',
    colConfig: { xs: 24 },
    inputConfig: {
      name: 'file',
      value: [],
      formItemProps: {
        tooltip: tooltipConfig(
          'Screenshots of the app with 5.5" display to be shown on App Store'
        )
      },
      inputProps: {
        title: 'Upload screenshots for 5.5" display',
        showDragAndDrop: true,
        listType: 'picture-card',
        multiple: true,
        uploadOptions: {
          key: AssetsMutation.uploadScreenshots.key,
          name: 'screenshots',
          extraConfig: { size: 'small' }
        }
      },
      validation: Yup.array()
        .min(
          IOS_FILE_VALIDATION.screenshots.small.length.min,
          'Minimum ${min} screenshots should be added.'
        )
        .max(
          IOS_FILE_VALIDATION.screenshots.small.length.max,
          'Maximum ${max} screenshots are allowed.'
        )
        .of(
          Yup.mixed().test({
            name: 'fileSize',
            params: {
              size: IOS_FILE_VALIDATION.screenshots.small.size,
              width: IOS_FILE_VALIDATION.screenshots.small.dimension.width,
              height: IOS_FILE_VALIDATION.screenshots.small.dimension.height
            },
            message:
              'Screenshots must be PNG or JPEG, up to ${size} MB each, with dimension ${width} x ${height}, ',
            test: async value => {
              if (!value || !value.size) return true;

              return await validateFile(
                value,
                IOS_FILE_VALIDATION.screenshots.small
              );
            }
          })
        )
    }
  },
  {
    name: 'screenshots',
    label: 'Screenshots 6.5" display',
    colConfig: { xs: 24 },
    inputConfig: {
      name: 'file',
      value: [],
      formItemProps: {
        tooltip: tooltipConfig(
          'Screenshots of the app with 6.5" display to be shown on App Store'
        )
      },
      inputProps: {
        title: 'Upload screenshots for 6.5" display',
        showDragAndDrop: true,
        listType: 'picture-card',
        multiple: true,
        uploadOptions: { key: AssetsMutation.uploadScreenshots.key }
      },
      validation: Yup.array()
        .min(
          IOS_FILE_VALIDATION.screenshots.base.length.min,
          'Minimum ${min} screenshots should be added.'
        )
        .max(
          IOS_FILE_VALIDATION.screenshots.base.length.max,
          'Maximum ${max} screenshots are allowed.'
        )
        .of(
          Yup.mixed().test({
            name: 'fileSize',
            params: {
              size: IOS_FILE_VALIDATION.screenshots.base.size,
              width: IOS_FILE_VALIDATION.screenshots.base.dimension.width,
              height: IOS_FILE_VALIDATION.screenshots.base.dimension.height
            },
            message:
              'Screenshots must be PNG or JPEG, up to ${size} MB each, with dimension ${width} x ${height}, ',
            test: async value => {
              if (!value || !value.size) return true;

              return await validateFile(
                value,
                IOS_FILE_VALIDATION.screenshots.base
              );
            }
          })
        )
    }
  }
];

export const appAssetSchemes = [
  {
    name: 'launcherIcon',
    label: 'App launcher icon',
    colConfig: { xs: 24 },
    inputConfig: {
      name: 'file',
      formItemProps: {
        tooltip: tooltipConfig(
          'Launcher icon of the app to be displayed on the users phone.'
        )
      },
      inputProps: {
        title: 'Upload launcher icon',
        showDragAndDrop: true,
        uploadOptions: { key: AssetsMutation.uploadLauncherIcon.key }
      },
      validation: Yup.mixed().test({
        name: 'fileSize',
        params: {
          size: IOS_FILE_VALIDATION.launcherIcon.size,
          width: IOS_FILE_VALIDATION.launcherIcon.dimension.width,
          height: IOS_FILE_VALIDATION.launcherIcon.dimension.height
        },
        message:
          'Your launcher icon must be a PNG or JPEG, up to ${size} MB, ${width} px by ${height} px',
        test: async value => {
          if (!value || !value.size) return true;

          return await validateFile(value, IOS_FILE_VALIDATION.launcherIcon);
        }
      })
    }
  },
  {
    name: 'splashScreen',
    label: 'Splash screen',
    colConfig: { xs: 24 },
    inputConfig: {
      name: 'file',
      formItemProps: {
        tooltip: tooltipConfig(
          'Splash screen of the app to be displayed on start of the app.'
        )
      },
      inputProps: {
        title: 'Upload splash screen',
        showDragAndDrop: true,
        uploadOptions: { key: AssetsMutation.uploadSplashScreen.key }
      },
      validation: Yup.mixed().test({
        name: 'fileSize',
        params: {
          size: IOS_FILE_VALIDATION.splashScreen.size,
          width: IOS_FILE_VALIDATION.splashScreen.dimension.width,
          height: IOS_FILE_VALIDATION.splashScreen.dimension.height
        },
        message:
          'Your splash screen must be a PNG or JPEG, up to ${size} MB, ${width} px by ${height} px',
        test: async value => {
          if (!value || !value.size) return true;

          return await validateFile(value, IOS_FILE_VALIDATION.splashScreen);
        }
      })
    }
  }
];
