import { Typography } from 'antd';
import React from 'react';
import './index.less';

const ElementType = {
  text: {
    name: 'text',
    component: Typography.Text
  },
  paragraph: {
    name: 'paragraph',
    component: Typography.Paragraph
  },
  title: {
    name: 'title',
    component: Typography.Title
  },
  link: {
    name: 'link',
    component: Typography.Link
  }
};

const CustomText = React.forwardRef(
  (
    {
      children,
      light = false,
      error = false,
      className = '',
      element = ElementType.text.name,
      ...rest
    },
    ref
  ) => {
    const Component = ElementType[element].component;

    return (
      <Component
        ref={ref}
        className={`${light ? 'light' : ''} ${
          error ? 'error' : ''
        } margin-bottom-0${className ? ` ${className}` : ''}`}
        {...rest}>
        {children}
      </Component>
    );
  }
);

export default React.memo(CustomText);
