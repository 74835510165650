export const Metrics = {
  tiny: '1px',
  smaller: '2px',
  small: '4px',
  normal: '8px',
  medium: '16px',
  large: '32px',
  xLarge: '64px',
  xxLarge: '128px'
};
