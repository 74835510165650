import { Space } from 'antd';
import React from 'react';
import CustomText from '../../Atoms/CustomText';

const PositionType = {
  left: 'left',
  right: 'right'
};

function IconText({
  icon,
  iconProps,
  label,
  position = PositionType.left,
  labelProps = {}
}) {
  const Icon = React.useMemo(
    () => React.cloneElement(icon, { ...iconProps }),
    [icon]
  );

  return (
    <Space size={4}>
      {position === PositionType.left ? Icon : null}

      <CustomText className={'text-lg font-semibold'} {...labelProps}>
        {label}
      </CustomText>

      {position === PositionType.right ? Icon : null}
    </Space>
  );
}

export default React.memo(IconText);
