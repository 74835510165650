import { Button, Col, Empty, Image, Row } from 'antd';
import React from 'react';
import { TestCredsQueries } from '../../Apis/testCreds';
import CustomText from '../../Components/Atoms/CustomText';
import Footer from '../../Components/Molecules/Footer';
import LoadingProvider from '../../Components/Wrappers/LoadingProvider';
import { useCustomQuery } from '../../Components/Wrappers/UseQuery';
import { CommonGutterConfig } from '../../Configs/common';
import MessageService from '../../Services/messageService';

function TestCreds({
  match: {
    params: { id }
  }
}) {
  const TestUserPhone = '1200000000';
  const {
    query: {
      data: { data: bizInfo } = {},
      isFetching: bizInfoFetching,
      refetch: bizInfoRefetch
    } = {}
  } = useCustomQuery({
    queryKey: TestCredsQueries.getClientByBizId.key,
    extraData: [id]
  });
  const {
    query: {
      data: { data: OTPResponse } = {},
      refetch: OTPrefetch,
      isFetching: OTPFetching
    }
  } = useCustomQuery({
    queryKey: TestCredsQueries.getOTP.key,
    extraData: [id],
    queryOptions: {
      onError: error => MessageService.error(error.message),
      enabled: false
    }
  });

  return (
    <LoadingProvider loading={bizInfoFetching}>
      <Row className={'min-w-full h-screen p-4'} align={'middle'}>
        {bizInfo ? (
          <Col xs={24}>
            <Row
              className={'min-w-full flex-col'}
              align={'middle'}
              gutter={CommonGutterConfig.gutterVerticalBase}>
              <Col>
                <Image
                  src={bizInfo.bizLogoUrl}
                  className={'w-72 h-52 '}
                  preview={false}
                />
              </Col>

              <Col>
                <CustomText element={'title'} level={4}>
                  {bizInfo.bizName}
                </CustomText>
              </Col>

              <Col>
                <Button
                  type={'primary'}
                  onClick={OTPrefetch}
                  loading={OTPFetching}>
                  {OTPResponse ? 'Re-request OTP' : 'Request OTP'}
                </Button>
              </Col>

              <Col />
              <Col />
              <Col />

              {!!OTPResponse && (
                <React.Fragment>
                  <Col>
                    <CustomText>
                      Test account phone:{' '}
                      <CustomText strong>{TestUserPhone}</CustomText>{' '}
                    </CustomText>
                  </Col>

                  <Col>
                    <CustomText element={'paragraph'}>
                      Test user OTP for{' '}
                      <CustomText strong italic className={'text-lg'}>
                        {bizInfo.bizName}
                      </CustomText>{' '}
                      is:
                    </CustomText>
                  </Col>

                  <Col>
                    <CustomText element={'title'} level={2} strong>
                      {OTPResponse?.otp}
                    </CustomText>
                  </Col>
                </React.Fragment>
              )}
            </Row>
          </Col>
        ) : (
          <Col xs={24}>
            <Empty description={'Client not found'}>
              <Button type={'primary'} onClick={bizInfoRefetch}>
                Refetch client
              </Button>
            </Empty>
          </Col>
        )}

        <Col xs={24}>
          <Footer />
        </Col>
      </Row>
    </LoadingProvider>
  );
}

export default React.memo(TestCreds);
