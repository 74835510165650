import { PlusOutlined } from '@ant-design/icons';
import { Button, Col, Input, Row, Space, Tag } from 'antd';
import React from 'react';
import { Colors } from '../../../Common/Colors/colors';
import { CommonGutterConfig } from '../../../Configs/common';

const MultiSelect = React.forwardRef(
  (
    {
      value = [],
      onChange = () => {},
      onBlur = () => {},
      name = '',
      disabled = false,
      tokenSeparator = /[\s,]/gi,
      ...rest
    },
    ref
  ) => {
    const [inputValue, setInputValue] = React.useState('');
    const buttonRef = React.useRef();
    const uniqueId = React.useId();
    const sendValue = React.useCallback(
      value => {
        const event = {
          target: {
            name,
            value
          }
        };

        onChange(event);

        setTimeout(() => onBlur(event), 100);
      },
      [onChange, onBlur, name]
    );

    const handleClose = React.useCallback(
      data => {
        const newArray = value.filter(item => item !== data);
        sendValue(newArray);
      },
      [value, sendValue]
    );

    const handleInputConfirm = React.useCallback(
      e => {
        e.preventDefault();

        if (!!inputValue && value.indexOf(inputValue) === -1) {
          sendValue([...value, inputValue]);
          setInputValue('');
        }
      },
      [inputValue, setInputValue, sendValue, value]
    );

    const handleChange = React.useCallback(
      ({ target: { value: inputValue = '' } }) => {
        if (new RegExp(tokenSeparator, 'gi').test(inputValue)) {
          const newValues = inputValue
            .split(tokenSeparator)
            .filter(item => !!item && !value.includes(item));
          const valueClone = [...value, ...newValues];

          sendValue(valueClone);
          setInputValue('');
          return;
        }

        setInputValue(inputValue);
      },
      [setInputValue, sendValue, value, tokenSeparator]
    );

    return (
      <Row
        className={'min-w-full'}
        gutter={CommonGutterConfig.gutterVerticalBase}>
        <Col span={24}>
          <Input.Group compact>
            <Input
              ref={ref}
              onChange={handleChange}
              onBlur={handleInputConfirm}
              onPressEnter={handleInputConfirm}
              value={inputValue}
              disabled={disabled}
              style={{
                width: `calc(100% - ${
                  buttonRef.current?.getBoundingClientRect?.()?.width ?? 90
                }px)`
              }}
              {...rest}
            />

            <Button
              type={'primary'}
              ref={buttonRef}
              disabled={disabled}
              icon={<PlusOutlined />}
              onClick={handleInputConfirm}>
              ADD
            </Button>
          </Input.Group>
        </Col>

        <Col span={24}>
          <Space size={CommonGutterConfig.gutterVerticalXs} wrap>
            {value.map(item => (
              <Tag
                closable
                key={uniqueId + item}
                color={Colors.primaryColor}
                onClose={e => {
                  e.preventDefault();
                  handleClose(item);
                }}>
                {item}
              </Tag>
            ))}
          </Space>
        </Col>
      </Row>
    );
  }
);

export default React.memo(MultiSelect);
