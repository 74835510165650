import Axios from '.';

const BuildApiRoutes = {
  base: '/build'
};

export const BuildQueries = {
  getBuildsByClientId: {
    key: 'getBuildsByClientId',
    api: clientId =>
      Axios.getInstance().get(`${BuildApiRoutes.base}/client/${clientId}`)
  },
  getAllBuilds: {
    key: 'getAllBuilds',
    api: () => Axios.getInstance().get(`${BuildApiRoutes.base}/all`)
  }
};

export const BuildSubscriptions = {
  subscribeToBuild: {
    key: 'subscribeToBuild',
    gql: `
      subscription SubscribeToBuild {
        buildUpdated {
          id
          progress {
            currentStep
            steps {
              state
              progress
            }
          }
        }
      }
    `
  }
}

export const BuildMutations = {
  requestBuild: {
    key: 'requestBuild',
    api: ({ clientId, payload }) =>
      Axios.getInstance().post(
        `${BuildApiRoutes.base}/client/${clientId}`,
        payload
      )
  },
  acceptBuild: {
    key: 'acceptBuild',
    api: buildId =>
      Axios.getInstance().post(`${BuildApiRoutes.base}/${buildId}/accept`)
  },
  uploadBuild: {
    key: 'uploadBuild',
    api: ({ buildId, payload, config }) =>
      Axios.getInstance().post(
        `${BuildApiRoutes.base}/${buildId}`,
        payload,
        config
      )
  }
};
