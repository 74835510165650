import { AppMutations, AppQueries } from './app';
import { AssetsMutation, AssetsQueries } from './assets';
import { BuildMutations, BuildQueries, BuildSubscriptions } from './builds';
import { ClientMutations, ClientQueries } from './clients';
import { GithubMutations, GithubQueries } from './github';
import { TestCredsMutations, TestCredsQueries } from './testCreds';
import { UserMutations, UserQueries } from './user';

// Registering all Queries
export const AllQueries = {
  ...UserQueries,
  ...TestCredsQueries,
  ...ClientQueries,
  ...AppQueries,
  ...AssetsQueries,
  ...BuildQueries,
  ...GithubQueries
};

// Registering all Mutations
export const AllMutations = {
  ...UserMutations,
  ...TestCredsMutations,
  ...ClientMutations,
  ...AppMutations,
  ...AssetsMutation,
  ...BuildMutations,
  ...GithubMutations
};

export const AllSubscriptions = {
  ...BuildSubscriptions
};
