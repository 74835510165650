import React from 'react';
import LoadingIndicator from '../../Atoms/LoadingIndicator';
import './index.less';

class LoadingProvider extends React.PureComponent {
  constructor(props) {
    super(props);

    this.state = {
      loading: false
    };
  }

  showLoading() {
    this.setState({ loading: true });
  }

  closeLoading() {
    this.setState({ loading: false });
  }

  toggleLoading() {
    this.setState(prevState => ({ loading: !prevState.loading }));
  }

  render() {
    const { children, loading: propLoading, indicator, className } = this.props;
    const { loading } = this.state;

    return (
      <React.Fragment>
        {children}

        {(loading || propLoading) && (
          <div
            className={`h-screen w-screen loading-container items-center justify-center ${className}`}>
            {indicator && React.isValidElement(indicator) ? (
              React.cloneElement(indicator)
            ) : (
              <LoadingIndicator />
            )}
          </div>
        )}
      </React.Fragment>
    );
  }
}

export default LoadingProvider;
