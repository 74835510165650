import Axios from '.';

const AppApiRoutes = {
  base: '/app',
  clientApp: '/app/client'
};

export const AppQueries = {
  getAppsByClientId: {
    key: 'getAppsByClientId',
    api: clientId =>
      Axios.getInstance().get(`${AppApiRoutes.clientApp}/${clientId}`)
  },
  getAppByClientIdAndPlatform: {
    key: 'getAppByClientIdAndPlatform',
    api: (clientId, platform) =>
      Axios.getInstance().get(
        `${AppApiRoutes.clientApp}/${clientId}/platform/${platform}`
      )
  },
  getAppConfigByClientId: {
    key: 'getAppConfigByClientId',
    api: clientId =>
      Axios.getInstance().get(`${AppApiRoutes.clientApp}/${clientId}/config`)
  },
  downloadAllConfig: {
    key: 'downloadAllConfig',
    api: (clientId, onDownloadProgress) =>
      Axios.getInstance().get(
        `${AppApiRoutes.clientApp}/${clientId}/download`,
        { responseType: 'blob', onDownloadProgress }
      )
  },
  getKeystoreFiles: {
    key: 'getKeystoreFiles',
    api: () => Axios.getInstance().get(`${AppApiRoutes.base}/keystore`)
  }
};

export const AppMutations = {
  createApp: {
    key: 'createApp',
    api: ({ payload, id }) =>
      Axios.getInstance().post(`${AppApiRoutes.base}/${id}`, payload)
  },
  updateApp: {
    key: 'updateApp',
    api: ({ payload, id }) =>
      Axios.getInstance().put(`${AppApiRoutes.base}/${id}`, payload)
  },
  updateAppConfig: {
    key: 'updateAppConfig',
    api: ({ clientId, payload }) =>
      Axios.getInstance().post(
        `${AppApiRoutes.clientApp}/${clientId}/config`,
        payload
      )
  }
};
