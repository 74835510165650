import { Divider } from 'antd';
import React from 'react';
import CustomText from '../../Atoms/CustomText';

function CustomDivider({
  title = '',
  titleProps = {},
  orientation = 'left',
  ...rest
}) {
  const { className, ...restTitleProps } = titleProps;

  return (
    <Divider orientation={orientation} {...rest}>
      {title && (
        <CustomText
          type={'secondary'}
          className={`font-normal text-sm ${className ? className : ''}`}
          {...restTitleProps}>
          {title}
        </CustomText>
      )}
    </Divider>
  );
}

export default React.memo(CustomDivider);
