import { Modal } from 'antd';

let instance = null;

export const setModalRef = ref => {
  instance = ref;
};

const ModalService = {
  showModal: (...rest) => {
    instance.showModal(...rest);
  },

  closeModal: (...rest) => {
    instance.closeModal(...rest);
  },

  closeAll: (...rest) => {
    instance.closeAll(...rest);
  },

  ...Modal
};

export default ModalService;
