import Axios from '.';
import axios from 'axios';

const TestCredsApiRoutes = {
  base: '/test-creds'
};

export const TestCredsQueries = {
  getClientByBizId: {
    key: 'getClientByBizId',
    api: bizId =>
      Axios.getInstance().get(
        `${TestCredsApiRoutes.base}/get-client-info/${bizId}`
      )
  },
  getOTP: {
    key: 'getOTP',
    api: bizId =>
      Axios.getInstance().get(`${TestCredsApiRoutes.base}/get-otp/${bizId}`)
  }
};

export const TestCredsMutations = {};
