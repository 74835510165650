import { UserMutations } from '../../Apis/user';
import LoadingService from '../../Services/loadingService';
import MessageService from '../../Services/messageService';
import ModalService from '../../Services/modalService';
import { NotificationService } from '../../Services/notificationService';
import { login, logout, updateUserData } from '../Actions/user';

export const loginThunk = response => {
  return dispatch => {
    LoadingService.toggleLoading();

    UserMutations.login
      .api(response.credential)
      .then(({ data = {} } = {}) => {
        const userData = {
          secretToken: data.accessToken,
          ...response.profileObj
        };

        dispatch(login(userData));

        NotificationService.showNotification({
          type: 'success',
          message: 'Log in',
          description: 'Logged in successfully 🥳'
        });
      })
      .catch(error => {
        MessageService.error(error.message);
      })
      .finally(LoadingService.toggleLoading);
  };
};

export const updateUserDataThunk = (userData = {}) => {
  return dispatch => {
    dispatch(updateUserData(userData));
  };
};

export const logoutThunk = (sessionTimeOut = false) => {
  return dispatch => {
    if (sessionTimeOut) {
      ModalService.warning({
        maskClosable: true,
        title: 'Whoops, Your session has expired!',
        content: 'Your session has expired. No worries, simply login again',
        afterClose: ModalService.destroyAll
      });
    } else {
      NotificationService.showNotification({
        type: 'success',
        message: 'Log out',
        description: 'Logged out successfully 🥳'
      });
    }

    dispatch(logout({}));
  };
};
