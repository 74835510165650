import { Button, Col, Divider, Row, Space } from 'antd';
import React from 'react';
import CustomText from '../../../../../../../../Components/Atoms/CustomText';
import FormInput from '../../../../../../../../Components/Cells/FormInput';
import CustomDivider from '../../../../../../../../Components/Molecules/CustomDivider';
import { CommonGutterConfig } from '../../../../../../../../Configs/common';
import { toPascalCase } from '../../../../../../../../Services/generalServices';
import RouterService from '../../../../../../../../Services/routerService';

const IconGeneratorSuggestion = {
  logo: {
    key: 'logo',
    title: 'App icon (Logo)',
    url: 'https://appicon.co/'
  },
  featureGraphic: {
    key: 'featureGraphic',
    title: 'Feature graphic',
    url: 'https://www.norio.be/legacy-graphic-generator/'
  },
  screenshots: {
    key: 'screenshots',
    title: 'Screenshots',
    url: 'https://studio.app-mockup.com/'
  }
};

class UploadAssets extends React.Component {
  static defaultProps = {
    onUpload: () => {},
    schemes: [],
    extraInputConfig: {},
    data: {}
  };

  constructor(props) {
    super(props);

    this.state = {};
    this.MaxImageToRender = 10;
  }

  getFormattedDataForAsset = () => {
    const { data } = this.props;

    const newData = {};

    const generateUrlObject = (key, url, index = 0) => ({
      uid: key,
      name: key,
      status: 'done',
      index,
      url
    });

    Object.keys(data).forEach(key => {
      if (!data[key]) {
        newData[key] = null;
        return;
      }

      if (typeof data[key] === 'string') {
        newData[key] = generateUrlObject(toPascalCase(key), data[key]);

        return;
      }

      /**
       * If an array of URL's are sent, then only show images less than or equal to `MaxImageToRender` threshold.
       */
      data[key]
        .filter((_, index) => index < this.MaxImageToRender)
        .forEach((url, index) => {
          if (!newData[key]) {
            newData[key] = [];
          }

          newData[key][index] = generateUrlObject(
            toPascalCase(key) + '-' + index + 1,
            url,
            index
          );
        });
    });

    return newData;
  };

  render() {
    const { schemes, extraInputConfig, commonInputConfig } = this.props;

    return (
      <Row
        className={'min-w-full'}
        gutter={CommonGutterConfig.gutterVerticalXs}>
        <Col span={24}>
          <FormInput
            defaultValues={this.getFormattedDataForAsset()}
            schemes={schemes}
            extraInputConfig={extraInputConfig}
            commonInputConfig={commonInputConfig}
            showSaveButton={false}
          />
        </Col>

        <Col span={24}>
          <CustomDivider title={'Suggested website to generate the assets'} />
        </Col>

        <Col span={24}>
          <Space
            size={CommonGutterConfig.gutterVerticalXs}
            direction={'vertical'}>
            {Object.keys(IconGeneratorSuggestion).map((key, index) => {
              const { title, url } = IconGeneratorSuggestion[key];

              return (
                <Space
                  size={CommonGutterConfig.gutterHorizontalXs}
                  key={key + index}>
                  <CustomText>{title}:</CustomText>

                  <Button
                    type={'link'}
                    onClick={() => RouterService.openInNewWindow(url)}>
                    {url}
                  </Button>
                </Space>
              );
            })}
          </Space>
        </Col>
      </Row>
    );
  }
}

export default UploadAssets;
