import {
  CloseCircleOutlined,
  RedoOutlined,
  SnippetsOutlined
} from '@ant-design/icons';
import {
  Avatar,
  Button,
  Col,
  Dropdown,
  Progress,
  Row,
  Space,
  Tag,
  Tooltip
} from 'antd';
import moment from 'moment';
import CustomText from '../../../../../Components/Atoms/CustomText';
import { CommonGutterConfig, Platforms } from '../../../../../Configs/common';

const Format = 'Do MMM YYYY (hh:mm A)';

const getProgressStatus = progress => {
  if (progress.failure) return 'exception';
  if (progress.currentStep < progress.steps.length - 1) return 'active';
  return '';
};

export const BuildColumns = [
  {
    title: 'Requested by',
    dataIndex: 'requestedAt',
    key: 'requestedAt',
    render: ({ value, record }) =>
      record ? (
        <Row
          justify="space-evenly"
          gutter={CommonGutterConfig.gutterHorizontalSm}>
          <Col flex="none">
            <Avatar src={record.requestedBy.avatar} size="large" />
          </Col>
          <Col flex="auto">
            <Space
              direction="vertical"
              size={CommonGutterConfig.gutterVerticalSm}>
              <CustomText>{record.requestedBy.name}</CustomText>

              <Tooltip title={moment(value).format(Format)}>
                <CustomText type={'secondary'} className={'text-xs'}>
                  {moment(value).fromNow()}
                </CustomText>
              </Tooltip>
            </Space>
          </Col>
        </Row>
      ) : (
        '-'
      ),
    sorter: (a, b) => a.requestedAt?.localeCompare?.(b.requestedAt),
    children: []
  },
  {
    title: 'Reason',
    dataIndex: '',
    render: ({ value }) => <div>{value.comment}</div>,
    children: []
  },
  {
    title: 'Platform',
    dataIndex: '',
    render: ({ value }) => (
      <Tag
        icon={Platforms[value.app.platform].icon}
        color={Platforms[value.app.platform].color}>
        {value.release.tag_name}
      </Tag>
    ),
    children: [],
    filters: Object.keys(Platforms).map(key => ({
      text: Platforms[key].label,
      value: key
    })),
    onFilter: (value, record) => record.app.platform === value
  },
  {
    title: 'Progress',
    dataIndex: 'progress',
    render: ({ value }) => (
      <Progress
        percent={Math.floor(
          (100 * value.currentStep) / (value.steps.length - 1)
        )}
        status={getProgressStatus(value)}
      />
    ),
    children: []
  },
  {
    title: 'Actions',
    dataIndex: '',
    render: ({ value }) => (
      <Space
        direction="horizontal"
        size={CommonGutterConfig.gutterHorizontalXs}>
        <Button icon={<SnippetsOutlined />}>Logs</Button>
        {value.failure && (
          <Button icon={<RedoOutlined />} type="primary">
            Retry
          </Button>
        )}
        {!value.failure && value.status !== 'completed' && (
          <Button icon={<CloseCircleOutlined />} danger>
            Cancel
          </Button>
        )}
      </Space>
    )
  }
];
