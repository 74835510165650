import React from 'react';
import Lottie from 'react-lottie';
import { Lotties } from '../../../Shared/lotties';

const defaultOptions = {
  loop: true,
  autoplay: true,
  animationData: Lotties.loading,
  rendererSettings: {
    preserveAspectRatio: 'xMidYMid slice'
  }
};

const Sizes = {
  small: {
    key: 'small',
    value: 80
  },
  base: {
    key: 'base',
    value: 120
  },
  large: {
    key: 'large',
    value: 240
  }
};

function LoadingIndicator({ size = Sizes.base.key }) {
  return (
    <div className={'flex items-center min-h-full min-w-full'}>
      <Lottie
        options={defaultOptions}
        height={Sizes[size].value}
        width={Sizes[size].value}
        isClickToPauseDisabled
      />
    </div>
  );
}

export default React.memo(LoadingIndicator);
