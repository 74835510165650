import React from 'react';
import { useMutation } from 'react-query';
import { AllMutations } from '../../../Apis/allApis';

/**
 * Custom useMutation hook for managing the mutation.
 */
function useCustomMutation({ mutationKey = '', mutationOptions = {} }) {
  const mutation = useMutation(AllMutations[mutationKey]?.api, {
    mutationKey,
    ...mutationOptions
  });

  return { ...mutation, mutationKey };
}

function UseMutation({ children, mutationKey = '', mutationOptions = {} }) {
  return children({ ...useCustomMutation({ mutationKey, mutationOptions }) });
}

export { useCustomMutation };
export default React.memo(UseMutation);
