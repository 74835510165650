import { notification } from 'antd';

const NotificationType = {
  open: 'open',
  success: 'success',
  warning: 'warning',
  info: 'info',
  error: 'error'
};

notification.config({
  duration: 3,
  maxCount: 3,
  placement: 'bottomLeft'
});

const showNotification = ({ type = NotificationType.open, ...args }) => {
  notification[NotificationType[type]]({
    ...args
  });
};

export const NotificationService = {
  showNotification
};
