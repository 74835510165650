import React from 'react';

const CustomImage = React.forwardRef(
  ({ imageList = [], imgClassName = '', ...rest }, ref) => {
    const [images, setImages] = React.useState([]);
    const [image, setImage] = React.useState({});

    const getTypeOfImage = (imageSrc = new File()) => {
      return imageSrc.type;
    };

    React.useEffect(() => {
      const imageListClone = [...imageList];
      const lastImage = imageListClone.splice(imageListClone.length - 1, 1);

      const restImages = imageListClone.map(imageSrc => ({
        src: imageSrc,
        type: getTypeOfImage(imageSrc)
      }));

      setImages(restImages);
      setImage({ src: lastImage, type: getTypeOfImage(lastImage) });
    }, [imageList]);

    return (
      <picture {...rest} ref={ref}>
        {images.map((item, index) => {
          return <source srcSet={item.src} type={item.type} key={index} />;
        })}

        <img
          src={image.src}
          type={image.type}
          alt={'img'}
          className={imgClassName}
        />
      </picture>
    );
  }
);

export default React.memo(CustomImage);
