import { Button, Result } from 'antd';
import React from 'react';
import { NavLink } from 'react-router-dom';
import { RouteNames } from '../../Configs/routes';

function Error({
  location: {
    state: {
      status = '404',
      title = '404',
      subTitle = 'Sorry, the page you visited does not exist.'
    } = {}
  } = {}
}) {
  return (
    <Result
      status={status}
      title={title}
      subTitle={subTitle}
      extra={
        <NavLink to={RouteNames.DASHBOARD + RouteNames.ALL_CLIENTS}>
          <Button type={'primary'}>Back home</Button>
        </NavLink>
      }
    />
  );
}

export default React.memo(Error);
