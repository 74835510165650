import { AppstoreFilled, FileImageFilled } from '@ant-design/icons';
import { Button, Col, Row } from 'antd';
import React from 'react';
import CustomDivider from '../../../../../../Components/Molecules/CustomDivider';
import IconText from '../../../../../../Components/Molecules/IconText';
import { CommonGutterConfig } from '../../../../../../Configs/common';
import ModalService from '../../../../../../Services/modalService';
import UploadAssets from './Components/UploadAssets';

function RenderHeader({
  storeAssetsProps,
  appAssetsProps,
  refetch = () => {},
  loading = false
}) {
  const onUploadStoreAsset = React.useCallback(() => {
    ModalService.showModal({
      title: <IconText icon={<FileImageFilled />} label={'Store assets'} />,
      renderChildren: <UploadAssets {...storeAssetsProps} />,
      width: 'lg',
      ModalProps: { afterClose: refetch }
    });
  }, [storeAssetsProps]);

  const onUploadAppAsset = React.useCallback(() => {
    ModalService.showModal({
      title: <IconText icon={<AppstoreFilled />} label={'App assets'} />,
      renderChildren: <UploadAssets {...appAssetsProps} />,
      ModalProps: { afterClose: refetch }
    });
  }, [appAssetsProps]);

  return (
    <Row
      className={'min-w-full'}
      gutter={CommonGutterConfig.gutterHorizontalBase}>
      <Col span={24}>
        <CustomDivider title={'App assets'} />
      </Col>

      <Col>
        <Button
          type={'primary'}
          onClick={onUploadStoreAsset}
          loading={loading}
          icon={<FileImageFilled />}>
          Store assets
        </Button>
      </Col>

      <Col>
        <Button
          type={'primary'}
          onClick={onUploadAppAsset}
          loading={loading}
          icon={<AppstoreFilled />}>
          App assets
        </Button>
      </Col>
    </Row>
  );
}

export default React.memo(RenderHeader);
