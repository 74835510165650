export const CountryCodes = [
  {
    name: 'Afghanistan',
    dialCode: '+93',
    code: 'AF',
    regex: '^937[0-9]{0,11}$|7[0-9]{0,9}$'
  },
  { name: 'Aland Islands', dialCode: '+358', code: 'AX' },
  {
    name: 'Albania',
    dialCode: '+355',
    code: 'AL',
    regex: '^(\\+355|0)6[789]\\d{6}$'
  },
  {
    name: 'Algeria',
    dialCode: '+213',
    code: 'DZ',
    regex: '^(\\+?213|0)(5|6|7)\\d{8}$'
  },
  { name: 'AmericanSamoa', dialCode: '+1684', code: 'AS' },
  {
    name: 'Andorra',
    dialCode: '+376',
    code: 'AD',
    regex: '^(\\+376)?[346]\\d{5}$'
  },
  { name: 'Angola', dialCode: '+244', code: 'AO', regex: '^(\\+244)\\d{9}$' },
  { name: 'Anguilla', dialCode: '+1264', code: 'AI' },
  { name: 'Antarctica', dialCode: '+672', code: 'AQ' },
  { name: 'Antigua and Barbuda', dialCode: '+1268', code: 'AG' },
  {
    name: 'Argentina',
    dialCode: '+54',
    code: 'AR',
    regex: '^\\+?549(11|[2368]\\d)\\d{8}$'
  },
  {
    name: 'Armenia',
    dialCode: '+374',
    code: 'AM',
    regex: '^(\\+?374|0)((10|[9|7][0-9])\\d{6}$|[2-4]\\d{7}$)'
  },
  { name: 'Aruba', dialCode: '+297', code: 'AW' },
  {
    name: 'Australia',
    dialCode: '+61',
    code: 'AU',
    regex: '^(\\+?61|0)4\\d{8}$'
  },
  {
    name: 'Austria',
    dialCode: '+43',
    code: 'AT',
    regex: '^(\\+43|0)\\d{1,4}\\d{3,12}$'
  },
  {
    name: 'Azerbaijan',
    dialCode: '+994',
    code: 'AZ',
    regex: '^(\\+994|0)(10|5[015]|7[07]|99)\\d{7}$'
  },
  { name: 'Bahamas', dialCode: '+1242', code: 'BS' },
  {
    name: 'Bahrain',
    dialCode: '+973',
    code: 'BH',
    regex: '^(\\+?973)?(3|6)\\d{7}$'
  },
  {
    name: 'Bangladesh',
    dialCode: '+880',
    code: 'BD',
    regex: '^(\\+?880|0)1[13456789][0-9]{8}$'
  },
  { name: 'Barbados', dialCode: '+1246', code: 'BB' },
  {
    name: 'Belarus',
    dialCode: '+375',
    code: 'BY',
    regex: '^(\\+?375)?(24|25|29|33|44)\\d{7}$'
  },
  {
    name: 'Belgium',
    dialCode: '+32',
    code: 'BE',
    regex: '^(\\+?32|0)4\\d{8}$'
  },
  { name: 'Belize', dialCode: '+501', code: 'BZ' },
  { name: 'Benin', dialCode: '+229', code: 'BJ' },
  {
    name: 'Bermuda',
    dialCode: '+1441',
    code: 'BM',
    regex: '^(\\+?1)?441(((3|7)\\d{6}$)|(5[0-3][0-9]\\d{4}$)|(59\\d{5}))'
  },
  {
    name: 'Bhutan',
    dialCode: '+975',
    code: 'BT',
    regex: '^(\\+?975|0)?(17|16|77|02)\\d{6}$'
  },
  {
    name: 'Bolivia, Plurinational State of',
    dialCode: '+591',
    code: 'BO',
    regex: '^(\\+?591)?(6|7)\\d{7}$'
  },
  {
    name: 'Bosnia and Herzegovina',
    dialCode: '+387',
    code: 'BA',
    regex: '^((((\\+|00)3876)|06))((([0-3]|[5-6])\\d{6})|(4\\d{7}))$'
  },
  {
    name: 'Botswana',
    dialCode: '+267',
    code: 'BW',
    regex: '^(\\+?267)?(7[1-8]{1})\\d{6}$'
  },
  {
    name: 'Brazil',
    dialCode: '+55',
    code: 'BR',
    regex:
      '^((\\+?55\\ ?[1-9]{2}\\ ?)|(\\+?55\\ ?\\([1-9]{2}\\)\\ ?)|(0[1-9]{2}\\ ?)|(\\([1-9]{2}\\)\\ ?)|([1-9]{2}\\ ?))((\\d{4}\\-?\\d{4})|(9[2-9]{1}\\d{3}\\-?\\d{4}))$'
  },
  { name: 'British Indian Ocean Territory', dialCode: '+246', code: 'IO' },
  { name: 'Brunei Darussalam', dialCode: '+673', code: 'BN' },
  {
    name: 'Bulgaria',
    dialCode: '+359',
    code: 'BG',
    regex: '^(\\+?359|0)?8[789]\\d{7}$'
  },
  {
    name: 'Burkina Faso',
    dialCode: '+226',
    code: 'BF',
    regex: '^(\\+226|0)[67]\\d{7}$'
  },
  { name: 'Burundi', dialCode: '+257', code: 'BI' },
  { name: 'Cambodia', dialCode: '+855', code: 'KH' },
  {
    name: 'Cameroon',
    dialCode: '+237',
    code: 'CM',
    regex: '^(\\+?237)6[0-9]{8}$'
  },
  {
    name: 'Canada',
    dialCode: '+1',
    code: 'CA',
    regex:
      '^((\\+1|1)?( |-)?)?(\\([2-9][0-9]{2}\\)|[2-9][0-9]{2})( |-)?([2-9][0-9]{2}( |-)?[0-9]{4})$'
  },
  { name: 'Cape Verde', dialCode: '+238', code: 'CV' },
  { name: 'Cayman Islands', dialCode: '+ 345', code: 'KY' },
  { name: 'Central African Republic', dialCode: '+236', code: 'CF' },
  { name: 'Chad', dialCode: '+235', code: 'TD' },
  {
    name: 'Chile',
    dialCode: '+56',
    code: 'CL',
    regex: '^(\\+?56|0)[2-9]\\d{1}\\d{7}$'
  },
  {
    name: 'China',
    dialCode: '+86',
    code: 'CN',
    regex: '^((\\+|00)86)?(1[3-9]|9[28])\\d{9}$'
  },
  { name: 'Christmas Island', dialCode: '+61', code: 'CX' },
  { name: 'Cocos (Keeling) Islands', dialCode: '+61', code: 'CC' },
  {
    name: 'Colombia',
    dialCode: '+57',
    code: 'CO',
    regex: '^(\\+?57)?3(0(0|1|2|4|5)|1\\d|2[0-4]|5(0|1))\\d{7}$'
  },
  { name: 'Comoros', dialCode: '+269', code: 'KM' },
  { name: 'Congo', dialCode: '+242', code: 'CG' },
  {
    name: 'Congo, The Democratic Republic of the Congo',
    dialCode: '+243',
    code: 'CD'
  },
  { name: 'Cook Islands', dialCode: '+682', code: 'CK' },
  {
    name: 'Costa Rica',
    dialCode: '+506',
    code: 'CR',
    regex: '^(\\+506)?[2-8]\\d{7}$'
  },
  { name: "Cote d'Ivoire", dialCode: '+225', code: 'CI' },
  { name: 'Croatia', dialCode: '+385', code: 'HR' },
  { name: 'Cuba', dialCode: '+53', code: 'CU', regex: '^(\\+53|0053)?5\\d{7}' },
  { name: 'Cyprus', dialCode: '+357', code: 'CY' },
  {
    name: 'Czech Republic',
    dialCode: '+420',
    code: 'CZ',
    regex: '^(\\+?420)? ?[1-9][0-9]{2} ?[0-9]{3} ?[0-9]{3}$'
  },
  {
    name: 'Denmark',
    dialCode: '+45',
    code: 'DK',
    regex: '^(\\+?45)?\\s?\\d{2}\\s?\\d{2}\\s?\\d{2}\\s?\\d{2}$'
  },
  { name: 'Djibouti', dialCode: '+253', code: 'DJ' },
  { name: 'Dominica', dialCode: '+1767', code: 'DM' },
  {
    name: 'Dominican Republic',
    dialCode: '+1849',
    code: 'DO',
    regex: '^(\\+?1)?8[024]9\\d{7}$'
  },
  {
    name: 'Ecuador',
    dialCode: '+593',
    code: 'EC',
    regex: '^(\\+?593|0)([2-7]|9[2-9])\\d{7}$'
  },
  {
    name: 'Egypt',
    dialCode: '+20',
    code: 'EG',
    regex: '^((\\+?20)|0)?1[0125]\\d{8}$'
  },
  {
    name: 'El Salvador',
    dialCode: '+503',
    code: 'SV',
    regex: '^(\\+?503)?[67]\\d{7}$'
  },
  { name: 'Equatorial Guinea', dialCode: '+240', code: 'GQ' },
  { name: 'Eritrea', dialCode: '+291', code: 'ER' },
  {
    name: 'Estonia',
    dialCode: '+372',
    code: 'EE',
    regex: '^(\\+?372)?\\s?(5|8[1-4])\\s?([0-9]\\s?){6,7}$'
  },
  { name: 'Ethiopia', dialCode: '+251', code: 'ET' },
  { name: 'Falkland Islands (Malvinas)', dialCode: '+500', code: 'FK' },
  {
    name: 'Faroe Islands',
    dialCode: '+298',
    code: 'FO',
    regex: '^(\\+?298)?\\s?\\d{2}\\s?\\d{2}\\s?\\d{2}$'
  },
  {
    name: 'Fiji',
    dialCode: '+679',
    code: 'FJ',
    regex: '^(\\+?679)?\\s?\\d{3}\\s?\\d{4}$'
  },
  {
    name: 'Finland',
    dialCode: '+358',
    code: 'FI',
    regex: '^(\\+?358|0)\\s?(4(0|1|2|4|5|6)?|50)\\s?(\\d\\s?){4,8}\\d$'
  },
  {
    name: 'France',
    dialCode: '+33',
    code: 'FR',
    regex: '^(\\+?33|0)[67]\\d{8}$'
  },
  {
    name: 'French Guiana',
    dialCode: '+594',
    code: 'GF',
    regex: '^(\\+?594|0|00594)[67]\\d{8}$'
  },
  {
    name: 'French Polynesia',
    dialCode: '+689',
    code: 'PF',
    regex: '^(\\+?689)?8[789]\\d{6}$'
  },
  { name: 'Gabon', dialCode: '+241', code: 'GA' },
  { name: 'Gambia', dialCode: '+220', code: 'GM' },
  {
    name: 'Georgia',
    dialCode: '+995',
    code: 'GE',
    regex: '^(\\+?995)?(5|79)\\d{7}$'
  },
  {
    name: 'Germany',
    dialCode: '+49',
    code: 'DE',
    regex: '^((\\+49|0)1)(5[0-25-9]\\d|6([23]|0\\d?)|7([0-57-9]|6\\d))\\d{7,9}$'
  },
  {
    name: 'Ghana',
    dialCode: '+233',
    code: 'GH',
    regex: '^(\\+233|0)(20|50|24|54|27|57|26|56|23|28|55|59)\\d{7}$'
  },
  { name: 'Gibraltar', dialCode: '+350', code: 'GI' },
  {
    name: 'Greece',
    dialCode: '+30',
    code: 'GR',
    regex: '^(\\+?30|0)?(69\\d{8})$'
  },
  {
    name: 'Greenland',
    dialCode: '+299',
    code: 'GL',
    regex: '^(\\+?299)?\\s?\\d{2}\\s?\\d{2}\\s?\\d{2}$'
  },
  { name: 'Grenada', dialCode: '+1473', code: 'GD' },
  {
    name: 'Guadeloupe',
    dialCode: '+590',
    code: 'GP',
    regex: '^(\\+?590|0|00590)[67]\\d{8}$'
  },
  { name: 'Guam', dialCode: '+1671', code: 'GU' },
  { name: 'Guatemala', dialCode: '+502', code: 'GT' },
  {
    name: 'Guernsey',
    dialCode: '+44',
    code: 'GG',
    regex: '^(\\+?44|0)1481\\d{6}$'
  },
  { name: 'Guinea', dialCode: '+224', code: 'GN' },
  { name: 'Guinea-Bissau', dialCode: '+245', code: 'GW' },
  {
    name: 'Guyana',
    dialCode: '+595',
    code: 'GY',
    regex: '^(\\+592|0)6\\d{6}$'
  },
  { name: 'Haiti', dialCode: '+509', code: 'HT' },
  { name: 'Holy See (Vatican City State)', dialCode: '+379', code: 'VA' },
  {
    name: 'Honduras',
    dialCode: '+504',
    code: 'HN',
    regex: '^(\\+?504)?[9|8]\\d{7}$'
  },
  {
    name: 'Hong Kong',
    dialCode: '+852',
    code: 'HK',
    regex: '^(\\+?852[-\\s]?)?[456789]\\d{3}[-\\s]?\\d{4}$'
  },
  {
    name: 'Hungary',
    dialCode: '+36',
    code: 'HU',
    regex: '^(\\+?36|06)(20|30|31|50|70)\\d{7}$'
  },
  { name: 'Iceland', dialCode: '+354', code: 'IS' },
  {
    name: 'India',
    dialCode: '+91',
    code: 'IN',
    regex: '^(\\+?91|0)?[6789]\\d{9}$'
  },
  {
    name: 'Indonesia',
    dialCode: '+62',
    code: 'ID',
    regex:
      '^(\\+?62|0)8(1[123456789]|2[1238]|3[1238]|5[12356789]|7[78]|9[56789]|8[123456789])([\\s?|\\d]{5,11})$'
  },
  {
    name: 'Iran, Islamic Republic of Persian Gulf',
    dialCode: '+98',
    code: 'IR',
    regex: '^(\\+?98[\\-\\s]?|0)9[0-39]\\d[\\-\\s]?\\d{3}[\\-\\s]?\\d{4}$'
  },
  {
    name: 'Iraq',
    dialCode: '+964',
    code: 'IQ',
    regex: '^(\\+?964|0)?7[0-9]\\d{8}$'
  },
  {
    name: 'Ireland',
    dialCode: '+353',
    code: 'IE',
    regex: '^(\\+?353|0)8[356789]\\d{7}$'
  },
  { name: 'Isle of Man', dialCode: '+44', code: 'IM' },
  {
    name: 'Israel',
    dialCode: '+972',
    code: 'IL',
    regex: '^(\\+972|0)([23489]|5[012345689]|77)[1-9]\\d{6}$'
  },
  {
    name: 'Italy',
    dialCode: '+39',
    code: 'IT',
    regex: '^(\\+?39)?\\s?3\\d{2} ?\\d{6,7}$'
  },
  { name: 'Jamaica', dialCode: '+1876', code: 'JM' },
  {
    name: 'Japan',
    dialCode: '+81',
    code: 'JP',
    regex: '^(\\+81[ \\-]?(\\(0\\))?|0)[6789]0[ \\-]?\\d{4}[ \\-]?\\d{4}$'
  },
  { name: 'Jersey', dialCode: '+44', code: 'JE' },
  {
    name: 'Jordan',
    dialCode: '+962',
    code: 'JO',
    regex: '^(\\+?962|0)?7[789]\\d{7}$'
  },
  {
    name: 'Kazakhstan',
    dialCode: '+77',
    code: 'KZ',
    regex: '^(\\+?7|8)?7\\d{9}$'
  },
  {
    name: 'Kenya',
    dialCode: '+254',
    code: 'KE',
    regex: '^(\\+?254|0)(7|1)\\d{8}$'
  },
  {
    name: 'Kiribati',
    dialCode: '+686',
    code: 'KI',
    regex: '^((\\+686|686)?)?( )?((6|7)(2|3|8)[0-9]{6})$'
  },
  {
    name: "Korea, Democratic People's Republic of Korea",
    dialCode: '+850',
    code: 'KP'
  },
  {
    name: 'Korea, Republic of South Korea',
    dialCode: '+82',
    code: 'KR',
    regex:
      '^((\\+?82)[ \\-]?)?0?1([0|1|6|7|8|9]{1})[ \\-]?\\d{3,4}[ \\-]?\\d{4}$'
  },
  {
    name: 'Kuwait',
    dialCode: '+965',
    code: 'KW',
    regex: '^(\\+?965)[569]\\d{7}$'
  },
  { name: 'Kyrgyzstan', dialCode: '+996', code: 'KG' },
  { name: 'Laos', dialCode: '+856', code: 'LA' },
  { name: 'Latvia', dialCode: '+371', code: 'LV', regex: '^(\\+?371)2\\d{7}$' },
  {
    name: 'Lebanon',
    dialCode: '+961',
    code: 'LB',
    regex: '^(\\+?961)?((3|81)\\d{6}|7\\d{7})$'
  },
  {
    name: 'Lesotho',
    dialCode: '+266',
    code: 'LS',
    regex: '^(\\+?266)(22|28|57|58|59|27|52)\\d{6}$'
  },
  { name: 'Liberia', dialCode: '+231', code: 'LR' },
  {
    name: 'Libyan Arab Jamahiriya',
    dialCode: '+218',
    code: 'LY',
    regex: '^((\\+?218)|0)?(9[1-6]\\d{7}|[1-8]\\d{7,9})$'
  },
  { name: 'Liechtenstein', dialCode: '+423', code: 'LI' },
  {
    name: 'Lithuania',
    dialCode: '+370',
    code: 'LT',
    regex: '^(\\+370|8)\\d{8}$'
  },
  {
    name: 'Luxembourg',
    dialCode: '+352',
    code: 'LU',
    regex: '^(\\+352)?((6\\d1)\\d{6})$'
  },
  {
    name: 'Macao',
    dialCode: '+853',
    code: 'MO',
    regex: '^(\\+?853[-\\s]?)?[6]\\d{3}[-\\s]?\\d{4}$'
  },
  { name: 'Macedonia', dialCode: '+389', code: 'MK' },
  { name: 'Madagascar', dialCode: '+261', code: 'MG' },
  { name: 'Malawi', dialCode: '+265', code: 'MW' },
  {
    name: 'Malaysia',
    dialCode: '+60',
    code: 'MY',
    regex:
      '^(\\+?6?01){1}(([0145]{1}(\\-|\\s)?\\d{7,8})|([236789]{1}(\\s|\\-)?\\d{7}))$'
  },
  {
    name: 'Maldives',
    dialCode: '+960',
    code: 'MV',
    regex: '^(\\+?960)?(7[2-9]|91|9[3-9])\\d{7}$'
  },
  { name: 'Mali', dialCode: '+223', code: 'ML' },
  {
    name: 'Malta',
    dialCode: '+356',
    code: 'MT',
    regex: '^(\\+?356|0)?(99|79|77|21|27|22|25)[0-9]{6}$'
  },
  { name: 'Marshall Islands', dialCode: '+692', code: 'MH' },
  {
    name: 'Martinique',
    dialCode: '+596',
    code: 'MQ',
    regex: '^(\\+?596|0|00596)[67]\\d{8}$'
  },
  { name: 'Mauritania', dialCode: '+222', code: 'MR' },
  {
    name: 'Mauritius',
    dialCode: '+230',
    code: 'MU',
    regex: '^(\\+?230|0)?\\d{8}$'
  },
  { name: 'Mayotte', dialCode: '+262', code: 'YT' },
  {
    name: 'Mexico',
    dialCode: '+52',
    code: 'MX',
    regex: '^(\\+?52)?(1|01)?\\d{10,11}$'
  },
  {
    name: 'Micronesia, Federated States of Micronesia',
    dialCode: '+691',
    code: 'FM'
  },
  { name: 'Moldova', dialCode: '+373', code: 'MD' },
  { name: 'Monaco', dialCode: '+377', code: 'MC' },
  { name: 'Mongolia', dialCode: '+976', code: 'MN' },
  { name: 'Montenegro', dialCode: '+382', code: 'ME' },
  { name: 'Montserrat', dialCode: '+1664', code: 'MS' },
  {
    name: 'Morocco',
    dialCode: '+212',
    code: 'MA',
    regex: '^(?:(?:\\+|00)212|0)[5-7]\\d{8}$'
  },
  {
    name: 'Mozambique',
    dialCode: '+258',
    code: 'MZ',
    regex: '^(\\+?258)?8[234567]\\d{7}$'
  },
  {
    name: 'Myanmar',
    dialCode: '+95',
    code: 'MM',
    regex: '^(\\+?959|09|9)(2[5-7]|3[1-2]|4[0-5]|6[6-9]|7[5-9]|9[6-9])[0-9]{7}$'
  },
  {
    name: 'Namibia',
    dialCode: '+264',
    code: 'NA',
    regex: '^(\\+?264|0)(6|8)\\d{7}$'
  },
  { name: 'Nauru', dialCode: '+674', code: 'NR' },
  {
    name: 'Nepal',
    dialCode: '+977',
    code: 'NP',
    regex: '^(\\+?977)?9[78]\\d{8}$'
  },
  {
    name: 'Netherlands',
    dialCode: '+31',
    code: 'NL',
    regex: '^(((\\+|00)?31\\(0\\))|((\\+|00)?31)|0)6{1}\\d{8}$'
  },
  { name: 'Netherlands Antilles', dialCode: '+599', code: 'AN' },
  { name: 'New Caledonia', dialCode: '+687', code: 'NC' },
  {
    name: 'New Zealand',
    dialCode: '+64',
    code: 'NZ',
    regex: '^(\\+?64|0)[28]\\d{7,9}$'
  },
  { name: 'Nicaragua', dialCode: '+505', code: 'NI' },
  { name: 'Niger', dialCode: '+227', code: 'NE' },
  {
    name: 'Nigeria',
    dialCode: '+234',
    code: 'NG',
    regex: '^(\\+?234|0)?[789]\\d{9}$'
  },
  { name: 'Niue', dialCode: '+683', code: 'NU' },
  { name: 'Norfolk Island', dialCode: '+672', code: 'NF' },
  { name: 'Northern Mariana Islands', dialCode: '+1670', code: 'MP' },
  {
    name: 'Norway',
    dialCode: '+47',
    code: 'NO',
    regex: '^(\\+?47)?[49]\\d{7}$'
  },
  {
    name: 'Oman',
    dialCode: '+968',
    code: 'OM',
    regex: '^((\\+|00)968)?(9[1-9])\\d{6}$'
  },
  {
    name: 'Pakistan',
    dialCode: '+92',
    code: 'PK',
    regex: '^((00|\\+)?92|0)3[0-6]\\d{8}$'
  },
  { name: 'Palau', dialCode: '+680', code: 'PW' },
  {
    name: 'Palestinian Territory, Occupied',
    dialCode: '+970',
    code: 'PS',
    regex: '^(\\+?970|0)5[6|9](\\d{7})$'
  },
  {
    name: 'Panama',
    dialCode: '+507',
    code: 'PA',
    regex: '^(\\+?507)\\d{7,8}$'
  },
  { name: 'Papua New Guinea', dialCode: '+675', code: 'PG' },
  {
    name: 'Paraguay',
    dialCode: '+595',
    code: 'PY',
    regex: '^(\\+?595|0)9[9876]\\d{7}$'
  },
  { name: 'Peru', dialCode: '+51', code: 'PE', regex: '^(\\+?51)?9\\d{8}$' },
  {
    name: 'Philippines',
    dialCode: '+63',
    code: 'PH',
    regex: '^(09|\\+639)\\d{9}$'
  },
  { name: 'Pitcairn', dialCode: '+872', code: 'PN' },
  {
    name: 'Poland',
    dialCode: '+48',
    code: 'PL',
    regex: '^(\\+?48)? ?[5-8]\\d ?\\d{3} ?\\d{2} ?\\d{2}$'
  },
  {
    name: 'Portugal',
    dialCode: '+351',
    code: 'PT',
    regex: '^(\\+?351)?9[1236]\\d{7}$'
  },
  { name: 'Puerto Rico', dialCode: '+1939', code: 'PR' },
  { name: 'Qatar', dialCode: '+974', code: 'QA' },
  {
    name: 'Romania',
    dialCode: '+40',
    code: 'RO',
    regex: '^(\\+?4?0)\\s?7\\d{2}(\\/|\\s|\\.|\\-)?\\d{3}(\\s|\\.|\\-)?\\d{3}$'
  },
  { name: 'Russia', dialCode: '+7', code: 'RU', regex: '^(\\+?7|8)?9\\d{9}$' },
  {
    name: 'Rwanda',
    dialCode: '+250',
    code: 'RW',
    regex: '^(\\+?250|0)?[7]\\d{8}$'
  },
  {
    name: 'Reunion',
    dialCode: '+262',
    code: 'RE',
    regex: '^(\\+?262|0|00262)[67]\\d{8}$'
  },
  { name: 'Saint Barthelemy', dialCode: '+590', code: 'BL' },
  {
    name: 'Saint Helena, Ascension and Tristan Da Cunha',
    dialCode: '+290',
    code: 'SH'
  },
  { name: 'Saint Kitts and Nevis', dialCode: '+1869', code: 'KN' },
  { name: 'Saint Lucia', dialCode: '+1758', code: 'LC' },
  { name: 'Saint Martin', dialCode: '+590', code: 'MF' },
  { name: 'Saint Pierre and Miquelon', dialCode: '+508', code: 'PM' },
  { name: 'Saint Vincent and the Grenadines', dialCode: '+1784', code: 'VC' },
  { name: 'Samoa', dialCode: '+685', code: 'WS' },
  {
    name: 'San Marino',
    dialCode: '+378',
    code: 'SM',
    regex: '^((\\+378)|(0549)|(\\+390549)|(\\+3780549))?6\\d{5,9}$'
  },
  { name: 'Sao Tome and Principe', dialCode: '+239', code: 'ST' },
  {
    name: 'Saudi Arabia',
    dialCode: '+966',
    code: 'SA',
    regex: '^(!?(\\+?966)|0)?5\\d{8}$'
  },
  { name: 'Senegal', dialCode: '+221', code: 'SN' },
  {
    name: 'Serbia',
    dialCode: '+381',
    code: 'RS',
    regex: '^(\\+3816|06)[- \\d]{5,9}$'
  },
  { name: 'Seychelles', dialCode: '+248', code: 'SC' },
  {
    name: 'Sierra Leone',
    dialCode: '+232',
    code: 'SL',
    regex: '^(\\+?232|0)\\d{8}$'
  },
  {
    name: 'Singapore',
    dialCode: '+65',
    code: 'SG',
    regex: '^(\\+65)?[3689]\\d{7}$'
  },
  {
    name: 'Slovakia',
    dialCode: '+421',
    code: 'SK',
    regex: '^(\\+?421)? ?[1-9][0-9]{2} ?[0-9]{3} ?[0-9]{3}$'
  },
  {
    name: 'Slovenia',
    dialCode: '+386',
    code: 'SI',
    regex:
      '^(\\+386\\s?|0)(\\d{1}\\s?\\d{3}\\s?\\d{2}\\s?\\d{2}|\\d{2}\\s?\\d{3}\\s?\\d{3})$'
  },
  { name: 'Solomon Islands', dialCode: '+677', code: 'SB' },
  { name: 'Somalia', dialCode: '+252', code: 'SO' },
  {
    name: 'South Africa',
    dialCode: '+27',
    code: 'ZA',
    regex: '^(\\+?27|0)\\d{9}$'
  },
  { name: 'South Sudan', dialCode: '+211', code: 'SS' },
  {
    name: 'South Georgia and the South Sandwich Islands',
    dialCode: '+500',
    code: 'GS'
  },
  {
    name: 'Spain',
    dialCode: '+34',
    code: 'ES',
    regex: '^(\\+?34)?[6|7]\\d{8}$'
  },
  {
    name: 'Sri Lanka',
    dialCode: '+94',
    code: 'LK',
    regex: '^(?:0|94|\\+94)?(7(0|1|2|4|5|6|7|8)( |-)?)\\d{7}$'
  },
  { name: 'Sudan', dialCode: '+249', code: 'SD' },
  { name: 'Suriname', dialCode: '+597', code: 'SR' },
  { name: 'Svalbard and Jan Mayen', dialCode: '+47', code: 'SJ' },
  { name: 'Swaziland', dialCode: '+268', code: 'SZ' },
  {
    name: 'Sweden',
    dialCode: '+46',
    code: 'SE',
    regex: '^(\\+?46|0)[\\s\\-]?7[\\s\\-]?[02369]([\\s\\-]?\\d){7}$'
  },
  {
    name: 'Switzerland',
    dialCode: '+41',
    code: 'CH',
    regex: '^(\\+41|0)([1-9])\\d{1,9}$'
  },
  {
    name: 'Syrian Arab Republic',
    dialCode: '+963',
    code: 'SY',
    regex: '^(!?(\\+?963)|0)?9\\d{8}$'
  },
  {
    name: 'Taiwan',
    dialCode: '+886',
    code: 'TW',
    regex: '^(\\+?886\\-?|0)?9\\d{8}$'
  },
  {
    name: 'Tajikistan',
    dialCode: '+992',
    code: 'TJ',
    regex: '^(\\+?992)?[5][5]\\d{7}$'
  },
  {
    name: 'Tanzania, United Republic of Tanzania',
    dialCode: '+255',
    code: 'TZ',
    regex: '^(\\+?255|0)?[67]\\d{8}$'
  },
  {
    name: 'Thailand',
    dialCode: '+66',
    code: 'TH',
    regex: '^(\\+66|66|0)\\d{9}$'
  },
  { name: 'Timor-Leste', dialCode: '+670', code: 'TL' },
  { name: 'Togo', dialCode: '+228', code: 'TG' },
  { name: 'Tokelau', dialCode: '+690', code: 'TK' },
  { name: 'Tonga', dialCode: '+676', code: 'TO' },
  { name: 'Trinidad and Tobago', dialCode: '+1868', code: 'TT' },
  {
    name: 'Tunisia',
    dialCode: '+216',
    code: 'TN',
    regex: '^(\\+?216)?[2459]\\d{7}$'
  },
  {
    name: 'Turkey',
    dialCode: '+90',
    code: 'TR',
    regex: '^(\\+?90|0)?5\\d{9}$'
  },
  {
    name: 'Turkmenistan',
    dialCode: '+993',
    code: 'TM',
    regex: '^(\\+993|993|8)\\d{8}$'
  },
  { name: 'Turks and Caicos Islands', dialCode: '+1649', code: 'TC' },
  { name: 'Tuvalu', dialCode: '+688', code: 'TV' },
  {
    name: 'Uganda',
    dialCode: '+256',
    code: 'UG',
    regex: '^(\\+?256|0)?[7]\\d{8}$'
  },
  {
    name: 'Ukraine',
    dialCode: '+380',
    code: 'UA',
    regex: '^(\\+?38|8)?0\\d{9}$'
  },
  {
    name: 'United Arab Emirates',
    dialCode: '+971',
    code: 'AE',
    regex: '^((\\+?971)|0)?[5|6][024568]\\d{7}$'
  },
  {
    name: 'United Kingdom',
    dialCode: '+44',
    code: 'GB',
    regex: '^(\\+?44|0)7\\d{9}$'
  },
  {
    name: 'United States',
    dialCode: '+1',
    code: 'US',
    regex:
      '^((\\+1|1)?( |-)?)?(\\([2-9][0-9]{2}\\)|[2-9][0-9]{2})( |-)?([2-9][0-9]{2}( |-)?[0-9]{4})$'
  },
  {
    name: 'Uruguay',
    dialCode: '+598',
    code: 'UY',
    regex: '^(\\+598|0)9[1-9][\\d]{6}$'
  },
  {
    name: 'Uzbekistan',
    dialCode: '+998',
    code: 'UZ',
    regex: '^(\\+?998)?(6[125-79]|7[1-69]|88|9\\d)\\d{7}$'
  },
  { name: 'Vanuatu', dialCode: '+678', code: 'VU' },
  {
    name: 'Venezuela, Bolivarian Republic of Venezuela',
    dialCode: '+58',
    code: 'VE',
    regex: '^(\\+?58)?(2|4)\\d{9}$'
  },
  {
    name: 'Vietnam',
    dialCode: '+84',
    code: 'VN',
    regex:
      '^((\\+?84)|0)((3([2-9]))|(5([25689]))|(7([0|6-9]))|(8([1-9]))|(9([0-9])))([0-9]{7})$'
  },
  { name: 'Virgin Islands, British', dialCode: '+1284', code: 'VG' },
  { name: 'Virgin Islands, U.S.', dialCode: '+1340', code: 'VI' },
  { name: 'Wallis and Futuna', dialCode: '+681', code: 'WF' },
  { name: 'Yemen', dialCode: '+967', code: 'YE' },
  {
    name: 'Zambia',
    dialCode: '+260',
    code: 'ZM',
    regex: '^(\\+?26)?09[567]\\d{7}$'
  },
  {
    name: 'Zimbabwe',
    dialCode: '+263',
    code: 'ZW',
    regex: '^(\\+263)[0-9]{9}$'
  }
];
