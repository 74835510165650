import { message } from 'antd';

message.config({
  duration: 3,
  maxCount: 3
});

const MessageService = {
  ...message
};

export default MessageService;
