import { FilterFilled, UserAddOutlined } from '@ant-design/icons';
import { Button, Col, Divider, Input, Radio, Row, Space, Tooltip } from 'antd';
import React from 'react';
import { ClientStatuses, CommonGutterConfig } from '../../../Configs/common';
import Toolbar from '../../../Components/Cells/Toolbar';
import IconText from '../../../Components/Molecules/IconText';
import UseQuery from '../../../Components/Wrappers/UseQuery';
import { withDashboard } from '../../../Layouts/Dashboard';
import ModalService from '../../../Services/modalService';
import AddClientModal from './Components/AddClientModal';
import './container.less';
import MessageService from '../../../Services/messageService';
import { NotificationService } from '../../../Services/notificationService';
import { ClientMutations, ClientQueries } from '../../../Apis/clients';
import UseMutation from '../../../Components/Wrappers/UseMutation';
import LoadingService from '../../../Services/loadingService';
import LoadingProvider from '../../../Components/Wrappers/LoadingProvider';
import { stripFaultyData } from '../../../Services/generalServices';
import ClientDisplay from './Components/ClientDisplay';
import { ClientDisplayType } from './Components/ClientDisplay';
import ClientFilter from './Components/ClientFilter';

class AllClients extends React.PureComponent {
  constructor(props) {
    super(props);

    this.pageSizeOptions = [20, 50, 100];

    this.state = {
      page: 1,
      pageSize: this.pageSizeOptions[0],
      search: '',
      displayType: ClientDisplayType.grid.key,
      filter: {}
    };
  }

  componentDidMount() {
    this.props.setDashboardHeader(this.setDashboardHeader());
  }

  setDashboardHeader = () => {
    const {
      match: { path },
      userName
    } = this.props;

    return (
      <Toolbar
        title={`Hello, ${userName}`}
        renderActions={() => {
          return (
            <Button
              type={'primary'}
              size={'large'}
              icon={<UserAddOutlined />}
              onClick={() => {
                ModalService.showModal({
                  title: (
                    <IconText icon={<UserAddOutlined />} label={'Add client'} />
                  ),
                  width: 'lg',
                  renderChildren: <AddClientModal path={path} />
                });
              }}>
              Add Client
            </Button>
          );
        }}
      />
    );
  };

  renderActions = ({ loading }) => {
    const { displayType, filter, search } = this.state;

    return (
      <Space align={'center'}>
        <Radio.Group
          value={displayType}
          buttonStyle={'solid'}
          disabled={loading}
          onChange={({ target: { value } }) =>
            this.setState({ displayType: value })
          }>
          {Object.keys(ClientDisplayType).map(key => (
            <Tooltip
              key={key}
              title={ClientDisplayType[key].label}
              placement={'bottom'}>
              <Radio.Button value={ClientDisplayType[key].key}>
                {ClientDisplayType[key].icon}
              </Radio.Button>
            </Tooltip>
          ))}
        </Radio.Group>

        <Tooltip title={'Filter'} placement={'bottom'}>
          <Button
            icon={<FilterFilled />}
            type={'primary'}
            loading={loading}
            onClick={() => {
              ModalService.showModal({
                title: <IconText icon={<FilterFilled />} label={'Filter'} />,
                renderChildren: (
                  <ClientFilter
                    filter={filter}
                    onFilter={values =>
                      this.setState({ filter: { ...values }, search: '' })
                    }
                  />
                )
              });
            }}
          />
        </Tooltip>

        <Tooltip
          title={'Search with client name or biz id'}
          placement={'bottomRight'}>
          <Input.Search
            enterButton
            allowClear
            placeholder={'Search'}
            value={search}
            onChange={({ target: { value } }) =>
              this.setState({ search: value, filter: {} })
            }
            loading={loading}
          />
        </Tooltip>
      </Space>
    );
  };

  // onPageChange = ({ page, onSet = () => {} }) => {
  //   this.setState({ page }, onSet);
  // };

  // onPageSizeChange = ({ pageSize, onSet = () => {} }) => {
  //   this.setState({ pageSize }, onSet);
  // };

  // reFetchData = ({ refetch = () => {}, data }) => {
  //   const { page, pageSize } = this.state;

  //   refetch([page, pageSize], { initialData: data });
  // };

  onDeleteClient = (mutateAsync, clientData, refetch) => {
    LoadingService.toggleLoading();

    mutateAsync(clientData.id)
      .then(() => {
        refetch();

        NotificationService.showNotification({
          type: 'success',
          message: 'Delete client',
          description: `${clientData.bizName} client deleted successfully!!!`
        });
      })
      .catch(error => {
        MessageService.error(error.message);
      })
      .finally(LoadingService.toggleLoading);
  };

  handleFilteredDataRender = (data = []) => {
    const { search, filter } = this.state;

    const searchRegExp = new RegExp(stripFaultyData(search), 'i');

    return (
      data
        /**
         * The below filter is for filtering according to the filter object
         */
        .filter(item =>
          !!filter.status ? filter.status === item.status : true
        )
        /**
         * The below filter is for filtering clients through the search text
         */
        .filter(
          item =>
            searchRegExp.test(item.bizName) || searchRegExp.test(item.bizId)
        )
    );
  };

  render() {
    // const { page, pageSize } = this.state;
    const { displayType } = this.state;
    const {
      match: { path }
    } = this.props;

    return (
      <UseQuery
        queryKey={ClientQueries.getClients.key}
        queryOptions={{
          onError: error => {
            MessageService.error(error.message);
          }
        }}>
        {({ query: { data: { data = [] } = {}, isFetching, refetch } }) => {
          const activeClients = data.filter(
            item => item.status === ClientStatuses.active.key
          ).length;

          return (
            <LoadingProvider loading={isFetching}>
              <Row
                className={'min-w-full'}
                gutter={CommonGutterConfig.gutterVerticalBase}>
                <Col span={24}>
                  <Toolbar
                    title={`All clients: ${activeClients}/${data.length}`}
                    size={'middle'}
                    loading={isFetching}
                    onReload={refetch}
                    renderActions={() =>
                      this.renderActions({ loading: isFetching })
                    }
                  />
                </Col>

                <Col span={24}>
                  <Divider className={'my-2'} />
                </Col>

                <Col span={24}>
                  <Row className={'min-w-full'} gutter={[24, 16]}>
                    <UseMutation
                      mutationKey={ClientMutations.deleteClientById.key}>
                      {({ mutateAsync }) => {
                        return (
                          <ClientDisplay
                            data={this.handleFilteredDataRender(data)}
                            onDeleteClient={item =>
                              this.onDeleteClient(mutateAsync, item, refetch)
                            }
                            {...{ path, displayType }}
                          />
                        );
                      }}
                    </UseMutation>
                  </Row>
                </Col>

                {/* <Col span={24}>
                <Pagination
                  total={length}
                  current={page}
                  showTotal={(total, range) =>
                    `${range[0]}-${range[1]} of ${total} items`
                  }
                  hideOnSinglePage
                  pageSize={pageSize}
                  pageSizeOptions={this.pageSizeOptions}
                  onChange={page =>
                    this.onPageChange({
                      page,
                      onSet: () => this.reFetchData({ refetch, data })
                    })
                  }
                  onShowSizeChange={(_, pageSize) =>
                    this.onPageSizeChange({
                      pageSize,
                      onSet: () => this.reFetchData({ refetch, data })
                    })
                  }
                />
              </Col> */}
              </Row>
            </LoadingProvider>
          );
        }}
      </UseQuery>
    );
  }
}

export default withDashboard(AllClients);
