import React from 'react';
import FormInput from '../../../../../Components/Cells/FormInput';
import MessageService from '../../../../../Services/messageService';
import { storeAssetSchemes, schema, appAssetSchemes } from './schema';
import { Button, Col, Row, Tooltip } from 'antd';
import Toolbar from '../../../../../Components/Cells/Toolbar';
import { ShopOutlined } from '@ant-design/icons';
import { CommonGutterConfig, Platforms } from '../../../../../Configs/common';
import LoadingService from '../../../../../Services/loadingService';
import UseQuery from '../../../../../Components/Wrappers/UseQuery';
import { AppMutations, AppQueries } from '../../../../../Apis/app';
import { AssetsMutation } from '../../../../../Apis/assets';
import UseMutation from '../../../../../Components/Wrappers/UseMutation';
import RenderHeader from '../Components/RenderHeader';

class IOSTab extends React.PureComponent {
  constructor(props) {
    super(props);

    this.state = {};
  }

  onStoreClick = () => {};

  onSubmit = (values, mutateAsync, refetch, id) => {
    delete values.serviceConfigPlist;
    delete values.appcenterPlist;

    const payload = {
      ...values,
      platform: Platforms.ios.key
    };

    LoadingService.toggleLoading();

    mutateAsync({ payload, id })
      .then(() => {
        refetch();

        MessageService.success('Changes updated successfully!!!');
      })
      .catch(error => {
        MessageService.error(error.message);
      })
      .finally(LoadingService.toggleLoading);
  };

  onStoreScreenShotDelete =
    (appId, mutateAsync, size = 'large') =>
    (_, index) => {
      LoadingService.toggleLoading();
      return mutateAsync({ appId, index, size }).finally(
        LoadingService.toggleLoading
      );
    };

  render() {
    const { id } = this.props;

    return (
      <UseQuery
        queryKey={AppQueries.getAppByClientIdAndPlatform.key}
        queryOptions={{
          onError: error => {
            MessageService.error(error.message);
          }
        }}
        extraData={[id, Platforms.ios.key]}>
        {({ query: { data: { data = {} } = {}, isFetching, refetch } }) => (
          <UseMutation mutationKey={AppMutations.updateApp.key}>
            {({ mutateAsync: updateOrCreateApp }) => (
              <Row
                className={'min-w-full'}
                gutter={CommonGutterConfig.gutterVerticalLg}>
                <Col span={24}>
                  <Toolbar
                    title={'iOS & App Store configs'}
                    size={'middle'}
                    loading={isFetching}
                    onReload={refetch}
                    renderActions={() => (
                      <Tooltip title={'Go to store'} placement={'bottom'}>
                        <Button
                          type={'primary'}
                          loading={isFetching}
                          icon={<ShopOutlined />}
                          onClick={this.onStoreClick}
                        />
                      </Tooltip>
                    )}
                  />
                </Col>

                <Col span={24}>
                  <UseMutation
                    mutationKey={AssetsMutation.deleteScreenshotById.key}>
                    {({ mutateAsync: deleteScreenshotById }) => (
                      <FormInput
                        defaultValues={data}
                        schemes={schema}
                        loading={isFetching}
                        extraInputConfig={{
                          appcenterPlist: {
                            inputProps: {
                              uploadOptions: {
                                extraConfig: { appId: data?.id }
                              }
                            }
                          },
                          serviceConfigPlist: {
                            inputProps: {
                              uploadOptions: {
                                extraConfig: { appId: data?.id }
                              }
                            }
                          }
                        }}
                        renderHeader={
                          <RenderHeader
                            refetch={refetch}
                            storeAssetsProps={{
                              schemes: storeAssetSchemes,
                              commonInputConfig: {
                                inputProps: {
                                  uploadOptions: {
                                    extraConfig: { appId: data?.id }
                                  }
                                }
                              },
                              extraInputConfig: {
                                screenshotsSmall: {
                                  inputProps: {
                                    onDelete: this.onStoreScreenShotDelete(
                                      data?.id,
                                      deleteScreenshotById,
                                      'small'
                                    )
                                  }
                                },
                                screenshots: {
                                  inputProps: {
                                    onDelete: this.onStoreScreenShotDelete(
                                      data?.id,
                                      deleteScreenshotById
                                    )
                                  }
                                }
                              },
                              data: {
                                screenshotsSmall:
                                  data?.screenshots?.small?.screenshots ?? [],
                                screenshots:
                                  data?.screenshots?.large?.screenshots ?? []
                              }
                            }}
                            appAssetsProps={{
                              schemes: appAssetSchemes,
                              commonInputConfig: {
                                inputProps: {
                                  uploadOptions: {
                                    extraConfig: { appId: data?.id }
                                  }
                                }
                              },
                              data: {
                                launcherIcon: data?.launcherIcon ?? '',
                                splashScreen: data?.splashScreen ?? ''
                              }
                            }}
                          />
                        }
                        onSubmit={values =>
                          this.onSubmit(
                            { ...data, ...values },
                            updateOrCreateApp,
                            refetch,
                            data.id
                          )
                        }
                      />
                    )}
                  </UseMutation>
                </Col>
              </Row>
            )}
          </UseMutation>
        )}
      </UseQuery>
    );
  }
}

export default IOSTab;
