import Axios from '.';

const ClientApiRoutes = {
  base: '/client'
};

export const ClientQueries = {
  getClients: {
    key: 'getClients',
    api: () => Axios.getInstance().get(ClientApiRoutes.base)
  },
  getClientById: {
    key: 'getClientById',
    api: id => Axios.getInstance().get(`${ClientApiRoutes.base}/${id}`)
  }
};

export const ClientMutations = {
  createClient: {
    key: 'createClient',
    api: data => Axios.getInstance().post(`${ClientApiRoutes.base}`, data)
  },
  editClient: {
    key: 'editClient',
    api: ({ payload, clientId }) =>
      Axios.getInstance().put(`${ClientApiRoutes.base}/${clientId}`, payload)
  },
  deleteClientById: {
    key: 'deleteClientById',
    api: id => Axios.getInstance().delete(`${ClientApiRoutes.base}/${id}`)
  }
};
