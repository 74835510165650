import { Col, Row } from 'antd';
import React from 'react';
import { BuildQueries } from '../../Apis/builds';
import Toolbar from '../../Components/Cells/Toolbar';
import BuildDetails from '../../Components/Cells/BuildDetails';
import CustomTable from '../../Components/Molecules/CustomTable';
import UseQuery from '../../Components/Wrappers/UseQuery';
import { CommonGutterConfig } from '../../Configs/common';
import MessageService from '../../Services/messageService';
import { Columns } from './schema';
import { BuildSubscriptions } from '../../Apis/builds';
import UseSubscription from '../../Components/Wrappers/UseSubscription';

class AllBuilds extends React.PureComponent {
  constructor(props) {
    super(props);

    this.state = {
      updatedBuilds: {}
    };
  }

  onSuccess = refetch => {
    refetch();
  };

  mergeDataWithState = (data, state) => {
    return data.map(build => {
      if (state[build.id]) {
        build.progress = state[build.id].progress;
      }
      return build;
    });
  };

  getFilterDataForClients = data => {
    return data.reduce(
      (acc, item) =>
        acc.some(client => client.value === item.client.bizName)
          ? acc
          : [
              ...acc,
              {
                text: item.client.bizName,
                value: item.client.bizName,
                bizId: item.client.bizId
              }
            ],
      []
    );
  };

  render() {
    return (
      <UseQuery
        queryKey={BuildQueries.getAllBuilds.key}
        queryOptions={{
          onError: error => {
            MessageService.error(error.message);
          }
        }}>
        {({ query: { data: { data = [] } = {}, isFetching, refetch } }) => {
          return (
            <Row
              className={'min-w-full'}
              gutter={CommonGutterConfig.gutterVerticalLg}>
              <Col span={24}>
                <Toolbar
                  size={'middle'}
                  title={'All builds'}
                  loading={isFetching}
                  onReload={refetch}
                />
              </Col>
              <UseSubscription
                queryKey={BuildSubscriptions.subscribeToBuild.key}
                extraData={data}
                onNext={(preData, { data: { buildUpdated } }) => {
                  const updatedBuildId = buildUpdated.id;
                  const buildIndex = preData.findIndex(
                    build => build.id === updatedBuildId
                  );
                  if (buildIndex >= 0) {
                    this.setState({
                      updatedBuilds: {
                        ...this.state.updatedBuilds,
                        [updatedBuildId]: buildUpdated
                      }
                    });
                  }
                }}
              />

              <Col span={24}>
                <CustomTable
                  columns={Columns}
                  dynamicColumnProps={{
                    client: {
                      filters: this.getFilterDataForClients(data)
                    }
                  }}
                  extraActions={{
                    onSuccess: () => this.onSuccess(refetch)
                  }}
                  expandable={{
                    expandedRowRender: record => <BuildDetails {...record} />
                  }}
                  extraData={{ loading: isFetching }}
                  dataSource={this.mergeDataWithState(
                    data,
                    this.state.updatedBuilds
                  )}
                  loading={isFetching}
                />
              </Col>
            </Row>
          );
        }}
      </UseQuery>
    );
  }
}

export default AllBuilds;
