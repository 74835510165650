import { CloudDownloadOutlined, EditOutlined } from '@ant-design/icons';
import { Button, Col, Image, Progress, Row, Space, Tag, Tooltip } from 'antd';
import React from 'react';
import { AppQueries } from '../../../../../Apis/app';
import CustomText from '../../../../../Components/Atoms/CustomText';
import { useCustomQuery } from '../../../../../Components/Wrappers/UseQuery';
import {
  ClientStatuses,
  CommonGutterConfig
} from '../../../../../Configs/common';
import ModalService from '../../../../../Services/modalService';
import { Images } from '../../../../../Shared/images';
import EditClientConfig from '../EditClientConfig';
import './index.less';
import jsFileDownload from 'js-file-download';
import { Colors } from '../../../../../Common/Colors/colors';

function DashboardHeader({ data = {}, onEditSuccess = () => {} }) {
  const [progress, setProgress] = React.useState(0);

  const onDownloadProgress = progressEvent => {
    const total = progressEvent.currentTarget.response.size;
    const loaded = progressEvent.loaded;

    const downloadProgress = Math.floor((loaded * 100) / total);

    if (downloadProgress > progress) setProgress(downloadProgress);
  };

  const onSuccess = React.useCallback(downloadedData => {
    jsFileDownload(downloadedData.data, `${data.bizName} Config.zip`, 'zip');
  }, []);

  const {
    query: { isFetching, refetch }
  } = useCustomQuery({
    queryKey: AppQueries.downloadAllConfig.key,
    extraData: [data?.id, onDownloadProgress],
    queryOptions: { enabled: false, onSuccess, onSettled: () => setProgress(0) }
  });

  const onEdit = React.useCallback(() => {
    ModalService.showModal({
      title: 'Edit client details',
      renderChildren: (
        <EditClientConfig
          defaultValues={data}
          clientId={data?.id}
          onEditSuccess={onEditSuccess}
        />
      )
    });
  }, [data, onEditSuccess]);

  return (
    <Row
      className={'min-w-full'}
      gutter={CommonGutterConfig.gutterHorizontalSm}>
      <Col xs={24} lg={19}>
        <Space>
          <Image
            src={data?.bizLogoUrl}
            fallback={Images.placeholderImage}
            className={'w-32 h-32 object-contain'}
          />

          <Row
            className={'min-w-full'}
            gutter={CommonGutterConfig.gutterVerticalXs}>
            <Col span={24} className={'base-col'}>
              <Space align={'center'}>
                <CustomText className={'text-2xl font-bold'}>
                  {data?.bizName}
                </CustomText>

                <Tag
                  icon={ClientStatuses[data?.status]?.icon}
                  color={ClientStatuses[data?.status]?.color}>
                  {ClientStatuses[data?.status]?.label}
                </Tag>
              </Space>
            </Col>

            <Col span={24} className={'base-col'}>
              <CustomText
                className={'text-sm'}
                copyable={{ text: data?.bizId }}>
                Biz id: {data?.bizId}
              </CustomText>
            </Col>

            <Col span={24} className={'base-col'}>
              <CustomText
                className={'text-sm'}
                copyable={{ text: data?.apiUsername }}>
                API username: {data?.apiUsername}
              </CustomText>
            </Col>

            <Col span={24} className={'base-col'}>
              <CustomText
                className={'text-sm'}
                copyable={{ text: data?.bizApiKey }}>
                API key: {data?.bizApiKey}
              </CustomText>
            </Col>
          </Row>
        </Space>
      </Col>

      <Col xs={24} lg={5} className={'flex justify-end'}>
        <Space
          size={CommonGutterConfig.gutterHorizontalXs}
          direction={'vertical'}>
          <Space size={CommonGutterConfig.gutterHorizontalSm}>
            <Tooltip>
              <Button
                type={'dashed'}
                icon={<EditOutlined />}
                size={'large'}
                onClick={onEdit}
              />
            </Tooltip>

            <Button
              type={'primary'}
              size={'large'}
              icon={<CloudDownloadOutlined />}
              loading={isFetching}
              onClick={refetch}>
              Download config
            </Button>
          </Space>

          {!!progress && (
            <Progress
              strokeColor={Colors.primaryColor}
              status={'active'}
              percent={progress}
            />
          )}
        </Space>
      </Col>
    </Row>
  );
}

export default React.memo(DashboardHeader);
