import { MinusCircleOutlined } from '@ant-design/icons';
import { Badge, Space, Tooltip } from 'antd';
import React from 'react';
import { Colors } from '../../../Common/Colors/colors';
import CustomText from '../../Atoms/CustomText';
import './index.less';

function ClientPlatformCard({
  icon,
  iconProps = {},
  name,
  selected = true,
  onSelect,
  onRemoveTitle = 'Are you sure to remove?',
  onRemove,
  className = '',
  ...rest
}) {
  const canRemove = React.useMemo(
    () => onRemove && onRemove instanceof Function,
    [onRemove]
  );

  const Comp = React.useMemo(
    () => (canRemove && selected ? Badge : React.Fragment),
    [canRemove, selected]
  );

  const CompProps = React.useMemo(
    () =>
      canRemove && selected
        ? {
            status: 'error',
            onClick: onRemove,
            count: (
              <Tooltip title={'Remove'}>
                <div
                  className={
                    'badge-container flex justify-center items-center cursor-pointer'
                  }>
                  <MinusCircleOutlined style={{ color: Colors.white }} />
                </div>
              </Tooltip>
            )
          }
        : {},
    [canRemove, onRemove, selected]
  );

  const onCardClick = React.useMemo(
    () =>
      onSelect
        ? event => {
            event.stopPropagation();
            onSelect();
          }
        : () => {},
    [onSelect]
  );

  return (
    <Space
      direction={'vertical'}
      align={'center'}
      className={`${selected ? '' : 'less-opacity'} ${
        canRemove ? 'app-container' : ''
      } ${className} min-w-full`}
      size={[0, 2]}
      {...rest}>
      <Comp {...CompProps}>
        <div
          className={`platform-icon-container p-4 flex justify-center items-center ${
            onSelect ? 'cursor-pointer' : ''
          }`}
          onClick={onCardClick}>
          {React.cloneElement(icon, {
            className: `text-2xl ${iconProps.className}`,
            ...iconProps
          })}
        </div>
      </Comp>

      <CustomText className={'text-xs font-bold'} type={'secondary'}>
        {name}
      </CustomText>
    </Space>
  );
}

export default React.memo(ClientPlatformCard);
