import { connect } from 'react-redux';
import { logoutThunk } from '../../Redux/Thunks/user';
import Dashboard, { withDashboard } from './container';

const mapStateToProps = state => ({
  isLoggedIn: state.user.isLoggedIn,
  userData: state.user.userData
});

const mapDispatchToProps = dispatch => ({
  logoutUser: response => dispatch(logoutThunk(response))
});

export { withDashboard };
export default connect(mapStateToProps, mapDispatchToProps)(Dashboard);
