import { Table } from 'antd';
import React from 'react';
import { Metrics } from '../../../Common/Sizes/metrics';
import LoadingIndicator from '../../Atoms/LoadingIndicator';
import './index.less';

function CustomTable({
  loading = false,
  extraActions = {},
  extraData = {},
  columns = [],
  commonColumnProps = {},
  dynamicColumnProps = {},
  pagination = false,
  ...rest
}) {
  const getRender = React.useCallback(
    (value, record, index, render) =>
      render({ value, record, index, extraData, extraActions }),
    [extraData, extraActions]
  );

  const attachRenderToChildren = React.useCallback(
    (children = []) => {
      return children.map(item => ({
        // Here comes each column object props
        ...item,
        ...(item.render
          ? {
              render: (value, record, index) =>
                getRender(value, record, index, item.render)
            }
          : {}),
        ...commonColumnProps,
        ...(item.hasOwnProperty('dataIndex')
          ? dynamicColumnProps[item.dataIndex]
          : {}),

        ...(item.children && !!item.children.length
          ? {
              children: attachRenderToChildren(item.children)
            }
          : {})
      }));
    },
    [getRender, commonColumnProps, dynamicColumnProps]
  );

  columns = React.useMemo(
    () => attachRenderToChildren(columns),
    [columns, extraData, extraActions, dynamicColumnProps]
  );

  return (
    <Table
      columns={columns}
      rowKey={record => record.id}
      loading={{
        spinning: loading,
        indicator: <LoadingIndicator size={'small'} />
      }}
      pagination={pagination}
      rowClassName={'rowClass'}
      sticky={{
        offsetHeader: '-' + Metrics.medium
      }}
      {...rest}
    />
  );
}

export default React.memo(CustomTable);
