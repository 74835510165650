import {
  EyeInvisibleOutlined,
  EyeOutlined,
  LoadingOutlined
} from '@ant-design/icons';
import { Button, Col, Row, Space, Steps } from 'antd';
import React from 'react';
import { CommonGutterConfig } from '../../../Configs/common';
import { RouteNames, SubRoutes } from '../../../Configs/routes';
import CustomText from '../../Atoms/CustomText';
import ReleaseCard from '../ReleaseCard';

const StepProgressStatus = {
  process: 'process',
  final: 'final'
};

function BuildDetails({ progress, release, requestedBy }) {
  const stepId = React.useId();
  const releaseViewPath = React.useMemo(
    () =>
      RouteNames.DASHBOARD +
      RouteNames.RELEASES +
      '/' +
      release.id +
      SubRoutes.VIEW,
    [release]
  );
  const [showRelease, setShowRelease] = React.useState(false);

  return (
    <Row gutter={CommonGutterConfig.gutterVerticalBase}>
      <Col span={24}>
        <Space direction={'vertical'} size={'middle'} className={'min-w-full'}>
          <CustomText element={'title'} level={4}>
            Build progress details:
          </CustomText>

          <Steps
            current={progress.currentStep + 1}
            size={'small'}
            labelPlacement={'vertical'}>
            {progress.steps.map((step, index) => (
              <Steps.Step
                key={stepId + index}
                status={step.progress}
                description={step.state}
                icon={
                  step.progress === StepProgressStatus.process &&
                  progress.currentStep !== progress.steps.length - 1 ? (
                    <LoadingOutlined />
                  ) : (
                    ''
                  )
                }
              />
            ))}
          </Steps>
        </Space>
      </Col>

      <Col span={24}>
        <Space size={'middle'} className={'min-w-full'}>
          <CustomText element={'title'} level={4}>
            Requested by:
          </CustomText>

          <CustomText
            copyable={{ text: requestedBy.email }}
            element={'title'}
            level={5}>
            {requestedBy.name} ({requestedBy.email})
          </CustomText>
        </Space>
      </Col>

      <Col span={24}>
        <Space direction={'vertical'} size={'middle'} className={'min-w-full'}>
          <Space
            size={CommonGutterConfig.gutterHorizontalBase}
            className={'min-w-full'}>
            <CustomText element={'title'} level={4}>
              Release details:
            </CustomText>

            <Button
              type={'dashed'}
              icon={showRelease ? <EyeInvisibleOutlined /> : <EyeOutlined />}
              onClick={() => setShowRelease(release => !release)}>
              {showRelease ? 'Hide' : 'View'}
            </Button>
          </Space>

          {showRelease && (
            <ReleaseCard
              key={release.id}
              to={releaseViewPath}
              releaseVersion={release.name}
              deployedDate={release.published_at}
              description={release.body}
            />
          )}
        </Space>
      </Col>
    </Row>
  );
}

export default React.memo(BuildDetails);
