import {
  AlertOutlined,
  AndroidFilled,
  AppleFilled,
  ExclamationCircleOutlined,
  FormatPainterOutlined,
  PullRequestOutlined,
  RestOutlined,
  ScheduleOutlined,
  SyncOutlined
} from '@ant-design/icons';
import { Colors } from '../Common/Colors/colors';
import { Images } from '../Shared/images';

export const CommonGutterConfig = {
  gutterXs: [4, 4],
  gutterSm: [8, 8],
  gutterBase: [16, 16],
  gutterLg: [24, 24],
  gutterXLg: [32, 32],
  gutterVerticalXs: [0, 4],
  gutterVerticalSm: [0, 8],
  gutterVerticalBase: [0, 16],
  gutterVerticalLg: [0, 24],
  gutterVerticalXLg: [0, 32],
  gutterHorizontalXs: [4, 0],
  gutterHorizontalSm: [8, 0],
  gutterHorizontalBase: [16, 0],
  gutterHorizontalLg: [24, 0],
  gutterHorizontalXLg: [32, 0]
};

export const ANDROID_FILE_VALIDATION = {
  logo: {
    size: 1,
    dimension: { width: 512, height: 512 }
  },
  featureBanner: {
    size: 1,
    dimension: { width: 1024, height: 500 }
  },
  screenshots: {
    size: 8,
    length: { min: 2, max: 8 },
    dimension: { aspect: 0.5, widthRange: [320, 3840] }
  },
  splashScreen: {
    size: 1,
    dimension: { width: 640, height: 1136 }
  },
  launcherIcon: {
    size: 1,
    dimension: { width: 1024, height: 1024 }
  },
  notificationIcon: {
    size: 1,
    dimension: { width: 1024, height: 1024 }
  }
};

export const IOS_FILE_VALIDATION = {
  screenshots: {
    small: {
      size: 8,
      length: { min: 2, max: 10 },
      dimension: { width: 1242, height: 2208 }
    },
    base: {
      size: 8,
      length: { min: 2, max: 10 },
      dimension: { width: 1242, height: 2688 }
    }
  },
  launcherIcon: {
    size: 1,
    dimension: { width: 1024, height: 1024 }
  },
  splashScreen: {
    size: 4,
    dimension: { width: 1242, height: 2208 }
  }
};

export const NAME_SUBTITLE_LENGTH = 30;
export const DISPLAY_NAME_LENGTH = 20;

export const Platforms = {
  android: {
    key: 'android',
    label: 'Android',
    color: Colors.android,
    icon: <AndroidFilled />
  },
  ios: {
    key: 'ios',
    label: 'iOS',
    color: Colors.ios,
    icon: <AppleFilled />
  }
};

export const ImageAcceptType =
  'image/png, image/gif, image/jpeg, image/jpg, image/webp';

export const Statuses = {
  pendingRelease: {
    key: 'pendingRelease',
    label: 'Pending Release',
    icon: <SyncOutlined />,
    color: '#FFA726'
  },
  active: {
    key: 'active',
    label: 'Active',
    icon: <AlertOutlined />,
    color: '#8BC34A'
  },
  churned: {
    key: 'churned',
    label: 'Churned',
    icon: <RestOutlined />,
    color: '#F44336'
  }
};

export const ClientStatuses = {
  inactive: {
    key: 'inactive',
    label: 'In active',
    icon: <ExclamationCircleOutlined />,
    color: '#FF5722'
  },
  active: {
    key: 'active',
    label: 'Active',
    icon: <AlertOutlined />,
    color: '#8BC34A'
  },
  churned: {
    key: 'churned',
    label: 'Churned',
    icon: <RestOutlined />,
    color: '#F44336'
  }
};

export const ReleaseTypes = [
  {
    value: 'nativeRelease',
    label: 'Native release'
  },
  {
    value: 'codePushRelease',
    label: 'Code push release'
  }
];

export const BuildStatuses = {
  requested: {
    key: 'requested',
    label: 'Requested',
    icon: <PullRequestOutlined />,
    color: '#FFC107'
  },
  inprogress: {
    key: 'inprogress',
    label: 'In progress',
    icon: <FormatPainterOutlined />,
    color: '#FF9800'
  },
  completed: {
    key: 'completed',
    label: 'Completed',
    icon: <ScheduleOutlined />,
    color: '#4CAF50'
  }
};

export const FoodQuotes = [
  {
    quote: 'The only thing I like better than talking about food is eating.',
    author: 'John Walters',
    imageSet: Images.signIn[0]
  },
  {
    quote: "You don't need a silver fork to eat good food.",
    author: 'Paul Prudhomme',
    imageSet: Images.signIn[1]
  },
  {
    quote: 'People who love to eat are always the best people.',
    author: 'Julia Child',
    imageSet: Images.signIn[2]
  },
  {
    quote: 'Food is really and truly the most effective medicine.',
    author: 'Joel Fuhrman',
    imageSet: Images.signIn[3]
  },
  {
    quote: 'Life is uncertain. Eat dessert first.',
    author: 'Ernestine Ulmer',
    imageSet: Images.signIn[4]
  }
];
