import { Col, Collapse, Row, Space } from 'antd';
import React from 'react';
import { AppQueries } from '../../../../../../../Apis/app';
import { GithubQueries } from '../../../../../../../Apis/github';
import { BuildMutations } from '../../../../../../../Apis/builds';
import CustomText from '../../../../../../../Components/Atoms/CustomText';
import FormInput from '../../../../../../../Components/Cells/FormInput';
import UseMutation from '../../../../../../../Components/Wrappers/UseMutation';
import UseQuery from '../../../../../../../Components/Wrappers/UseQuery';
import {
  CommonGutterConfig,
  Platforms
} from '../../../../../../../Configs/common';
import { toPascalCase } from '../../../../../../../Services/generalServices';
import LoadingService from '../../../../../../../Services/loadingService';
import MessageService from '../../../../../../../Services/messageService';
import ModalService from '../../../../../../../Services/modalService';
import { schemes } from './schema';
import moment from 'moment';

class RequestBuild extends React.PureComponent {
  static defaultProps = {
    onSuccess: () => { },
    id: ''
  };

  constructor(props) {
    super(props);

    this.state = {
      errorTitle: '',
      errorMessages: []
    };

    this.clientId = props.id;
    this.onSuccess = props.onSuccess;
  }

  onSubmit = requestBuild => payload => {
    LoadingService.toggleLoading();

    requestBuild({ clientId: this.clientId, payload })
      .then(() => {
        MessageService.success('Build requested successfully!!!');
        this.onSuccess();
        ModalService.closeModal();
      })
      .catch(error => {
        console.log(error, 'error');

        MessageService.error(error.description.message || error.message);

        if (Array.isArray(error.description.message)) {
          this.setState({
            errorMessages: error.description.message,
            errorTitle: error.description.errorMessage
          });
        }
      })
      .finally(() => {
        LoadingService.toggleLoading();
      });
  };

  render() {
    const { errorTitle, errorMessages } = this.state;

    return (
      <UseQuery
        queryKey={AppQueries.getAppsByClientId.key}
        extraData={[this.clientId]}>
        {({ query: { data: { data: appData } = [], isAppFetching, isAppLoading } }) => (
          <UseMutation mutationKey={BuildMutations.requestBuild.key}>
            {({ mutateAsync: requestBuild }) => {
              return (
                <UseQuery queryKey={GithubQueries.getAllReleases.key}>
                  {({
                    query: {
                      data: { data: githubData = [] } = {},
                      isFetching,
                      isLoading
                    }
                  }) => (
                    <Space
                      direction={'vertical'}
                      size={CommonGutterConfig.gutterVerticalBase}>
                      {!!errorMessages.length && (
                        <Row
                          className={'min-w-full'}
                          gutter={CommonGutterConfig.gutterVerticalBase}>
                          <Col span={24}>
                            <CustomText className={'font-bold text-lg'} error>
                              {errorTitle}
                            </CustomText>
                          </Col>

                          <Col span={24}>
                            <Collapse defaultActiveKey={[`collapse-panel`]}>
                              <Collapse.Panel
                                key={`collapse-panel`}
                                header={
                                  <CustomText error>
                                    {errorMessages.length} errors found, Expand
                                    to see them all.
                                  </CustomText>
                                }>
                                <Row>
                                  {errorMessages.map((item, index) => {
                                    return (
                                      <Col key={index} span={24}>
                                        <Space
                                          wrap
                                          size={
                                            CommonGutterConfig.gutterHorizontalSm
                                          }>
                                          <CustomText className={'font-medium'}>
                                            {toPascalCase(item.name)} :
                                          </CustomText>

                                          <CustomText
                                            error
                                            className={'text-xs'}>
                                            {toPascalCase(item.error)}
                                          </CustomText>
                                        </Space>
                                      </Col>
                                    );
                                  })}
                                </Row>
                              </Collapse.Panel>
                            </Collapse>
                          </Col>

                          <Col span={24}>
                            <CustomText className={'font-light text-xs'}>
                              **Resolve all the errors to request the build.**
                            </CustomText>
                          </Col>
                        </Row>
                      )}
                      <FormInput
                        schemes={schemes}
                        size={'large'}
                        loading={isFetching || isLoading || isAppFetching || isAppLoading}
                        saveButtonProps={{
                          block: true,
                          title: 'REQUEST'
                        }}
                        onSubmit={this.onSubmit(requestBuild)}
                        extraInputConfig={{
                          appIds: {
                            data: appData?.map(item => ({
                              value: item.id,
                              label: `${item.name ?? ''} (${Platforms[item.platform].label
                                })`
                            }))
                          },
                          releaseId: {
                            data: githubData?.map(release => ({
                              value: release.id,
                              label: (
                                <span>
                                  <strong>{release.name}</strong> -{' '}
                                  {moment(release.published_at).format(
                                    "DD MMM'YY"
                                  )}
                                </span>
                              )
                            }))
                          }
                        }}
                      />
                    </Space>
                  )}
                </UseQuery>
              );
            }}
          </UseMutation>
        )}
      </UseQuery>
    );
  }
}

export default RequestBuild;
