import { ThunderboltOutlined } from '@ant-design/icons';
import { Col, Row } from 'antd';
import React from 'react';
import FormInput from '../../../Components/Cells/FormInput';
import Toolbar from '../../../Components/Cells/Toolbar';
import { CommonGutterConfig } from '../../../Configs/common';
import MessageService from '../../../Services/messageService';
import { schemes } from './schema';

class NewRelease extends React.PureComponent {
  constructor(props) {
    super(props);

    this.state = {};
  }

  /**
   * Validate the updated version with the previous version
   */
  checkVersionIncrement = values => {
    console.log(values, 'Entered version');
  };

  onSubmit = (values, formikHelpers) => {
    console.log(values, 'values');
    formikHelpers.setSubmitting(true);

    // this.checkVersionIncrement(values);

    MessageService.loading('Creating release', 5).then(() => {
      formikHelpers.setSubmitting(false);

      MessageService.success('Release created successfully!!!');
    });
  };

  render() {
    return (
      <Row
        className={'min-w-full'}
        gutter={CommonGutterConfig.gutterVerticalBase}>
        <Col span={24}>
          <Toolbar title={'New release'} />
        </Col>

        <Col span={24}>
          <FormInput
            schemes={schemes}
            onSubmit={this.onSubmit}
            saveButtonProps={{
              title: 'RELEASE',
              icon: <ThunderboltOutlined />
            }}
          />
        </Col>
      </Row>
    );
  }
}

export default NewRelease;
