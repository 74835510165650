export const getImageDimension = file => {
  return new Promise((resolve, reject) => {
    const image = new Image();
    const objectUrl = URL.createObjectURL(file);
    image.src = objectUrl;

    image.onload = () => {
      URL.revokeObjectURL(objectUrl);
      resolve({ width: image.width, height: image.height });
    };

    image.onerror = () => {
      reject();
    };
  });
};

const checkInRange = (number, range = []) => range[0] <= number <= range[1];

export const validateFile = async (
  file,
  fileValidation,
  checkAspect = false
) => {
  const oneMB = 1024;

  const image = await getImageDimension(file);

  if (checkAspect) {
    return (
      file.size / oneMB <= fileValidation.size * oneMB &&
      (image.width / image.height).toFixed(1) ===
        fileValidation.dimension.aspect?.toFixed(1) &&
      checkInRange(image.width, fileValidation.dimension.widthRange)
    );
  }

  return (
    file.size / oneMB <= fileValidation.size * oneMB &&
    image.width === fileValidation.dimension.width &&
    image.height === fileValidation.dimension.height
  );
};

export const isFileImage = file => {
  return (
    file &&
    (file instanceof File || file instanceof Blob) &&
    file['type'].split('/')[0] === 'image'
  );
};

export const getFormDataForFiles = (values = {}, replaceKeys = {}) => {
  const payload = {};

  Object.keys(values).forEach(valueKey => {
    const key = replaceKeys[valueKey] ?? valueKey;

    if (values[valueKey] instanceof File || values[valueKey] instanceof Blob) {
      const formData = new FormData();
      formData.append(key, values[valueKey], values[valueKey].name);

      payload[valueKey] = formData;
    } else if (
      Array.isArray(values[valueKey]) &&
      values[valueKey].some(
        data => data instanceof File || data instanceof Blob
      )
    ) {
      const formData = new FormData();
      const filesArray = values[valueKey].filter(
        data => data instanceof File || data instanceof Blob
      );

      filesArray.forEach(data => {
        formData.append(key, data, data.name);
      });

      payload[valueKey] = formData;
    }
  });

  return payload;
};

export const getFormDataValues = values => {
  const payload = new FormData();

  Object.keys(values).forEach(key => payload.append(key, values[key]));

  return payload;
};

export const deepMergeObjects = (obj1, obj2) => {
  const merged = {};

  for (const each in obj2) {
    if (each in obj1) {
      if (typeof obj1[each] === 'object' && typeof obj2[each] === 'object') {
        merged[each] = deepMergeObjects(obj1[each], obj2[each]);
      } else {
        merged[each] = [obj1[each], obj2[each]];
      }
    } else {
      merged[each] = obj2[each];
    }
  }

  for (const each in obj1) {
    if (!(each in obj2)) {
      merged[each] = obj1[each];
    }
  }

  return merged;
};

export const stripFaultyData = string =>
  string.replace(/[\\\+\*\^\$\?\.]/gi, '');

export const toPascalCase = (string = '') =>
  string
    .replace(/([A-Z0-9])/g, match => ` ${match}`)
    .replace(/^./, match => match.toUpperCase());

export const setCurrentRouteTitle = (title = '') => {
  if (!!title) {
    document.title = title;
  }
};
