import React from 'react';
import FormInput from '../../../../../Components/Cells/FormInput';
import MessageService from '../../../../../Services/messageService';
import { schema } from './schema';
import { Col, Row } from 'antd';
import Toolbar from '../../../../../Components/Cells/Toolbar';
import { CommonGutterConfig } from '../../../../../Configs/common';
import UseQuery from '../../../../../Components/Wrappers/UseQuery';
import { AppMutations, AppQueries } from '../../../../../Apis/app';
import UseMutation from '../../../../../Components/Wrappers/UseMutation';
import LoadingService from '../../../../../Services/loadingService';

class AppConfigTab extends React.PureComponent {
  constructor(props) {
    super(props);

    this.state = {};
  }

  onSubmit = updateAppConfig => values => {
    const { id: clientId } = this.props;

    LoadingService.toggleLoading();

    updateAppConfig({ clientId, payload: values })
      .then(() => {
        MessageService.success('Config updated successfully!!!');
      })
      .catch(error => {
        MessageService.error(error.message);
      })
      .finally(LoadingService.toggleLoading);
  };

  render() {
    const { id } = this.props;

    return (
      <UseQuery
        queryKey={AppQueries.getAppConfigByClientId.key}
        queryOptions={{
          onError: error => {
            MessageService.error(error.message);
          }
        }}
        extraData={[id]}>
        {({ query: { data: { data = {} } = {}, isFetching, refetch } }) => {
          return (
            <UseMutation mutationKey={AppMutations.updateAppConfig.key}>
              {({ isLoading, mutateAsync: updateAppConfig }) => {
                return (
                  <Row
                    className={'min-w-full'}
                    gutter={CommonGutterConfig.gutterVerticalLg}>
                    <Col span={24}>
                      <Toolbar
                        title={'App related configs'}
                        size={'middle'}
                        loading={isFetching}
                        onReload={refetch}
                      />
                    </Col>

                    <Col span={24}>
                      <FormInput
                        defaultValues={data}
                        schemes={schema}
                        loading={isFetching || isLoading}
                        onSubmit={this.onSubmit(updateAppConfig)}
                      />
                    </Col>
                  </Row>
                );
              }}
            </UseMutation>
          );
        }}
      </UseQuery>
    );
  }
}

export default AppConfigTab;
