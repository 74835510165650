import {
  Button,
  Col,
  DatePicker,
  Form,
  Input,
  Row,
  Select,
  Grid,
  Space
} from 'antd';
import React from 'react';
import { Formik } from 'formik';
import * as Yup from 'yup';
import CustomUpload from '../../Atoms/CustomUpload';
import CustomSwitch from '../../Atoms/CustomSwitch';
import './index.less';
import CustomDivider from '../../Molecules/CustomDivider';
import CustomSelect from '../../Atoms/CustomSelect';
import DisplayApps from '../DisplayApps';
import MultiSelect from '../../Atoms/MultiSelect';
import { deepMergeObjects } from '../../../Services/generalServices';
import { CommonGutterConfig } from '../../../Configs/common';

const InputTypes = {
  input: {
    name: 'input',
    component: type => (type ? Input[type] : Input)
  },
  datePicker: {
    name: 'datePicker',
    component: type => (type ? DatePicker[type] : DatePicker)
  },
  file: {
    name: 'file',
    component: () => CustomUpload
  },
  select: {
    name: 'select',
    component: () => CustomSelect
  },
  multiSelect: {
    name: 'multiSelect',
    component: () => MultiSelect
  },
  switch: {
    name: 'switch',
    component: () => CustomSwitch
  },
  appsPicker: {
    name: 'appsPicker',
    component: () => DisplayApps
  }
};

const getInitialValues = (schemes = [], defaultValues = {}) => {
  return schemes.reduce((attr, schema) => {
    if (schema?.name) {
      return (attr = {
        ...attr,
        [schema.name]:
          defaultValues[schema.name] ?? schema.inputConfig?.value ?? ''
      });
    }

    return attr;
  }, {});
};

const getValidationSchema = (schemes = []) => {
  const formatObject = schemes.reduce((attr, schema) => {
    if (schema?.inputConfig?.validation) {
      return (attr = {
        ...attr,
        [schema.name]: schema.inputConfig.required
          ? schema.inputConfig.validation?.required?.('Required')
          : schema.inputConfig.validation
      });
    }

    return attr;
  }, {});

  return Yup.object(formatObject);
};

function FormInput({
  rowConfig = {
    gutter: [
      { xs: 8, md: 16 },
      { xs: 2, md: 8 }
    ]
  },
  colConfig = {},
  schemes = [],
  defaultValues = {},
  onSubmit = () => {},
  saveButtonProps = {},
  extraInputConfig = {},
  commonInputConfig = {},
  loading = false,
  renderHeader,
  renderFooter,
  showSaveButton = true,
  saveButtonPrefixNode,
  ...rest
}) {
  const handleErrors = React.useCallback((name, formik) => {
    return (
      (formik.touched[name] || !!formik.values[name]) && formik.errors[name]
    );
  }, []);

  const uniqueId = React.useId();

  const screens = Grid.useBreakpoint();

  const { className: rowClassName, ...rowConfigRest } = rowConfig;

  const initialValuesClone = React.useMemo(
    () => getInitialValues(schemes, defaultValues),
    [schemes, defaultValues]
  );

  return (
    <Formik
      enableReinitialize
      initialValues={getInitialValues(schemes, defaultValues)}
      validationSchema={getValidationSchema(schemes)}
      onSubmit={onSubmit}
      validateOnMount
      validateOnBlur>
      {formik => {
        return (
          <Form
            preserve={false}
            onSubmitCapture={formik.handleSubmit}
            layout={'vertical'}
            {...rest}>
            <Row className={`min-w-full ${rowClassName}`} {...rowConfigRest}>
              {renderHeader && React.isValidElement(renderHeader) && (
                <Col span={24}>
                  {React.cloneElement(renderHeader, { formik, loading })}
                </Col>
              )}

              {schemes.map((schema, index) => {
                const {
                  colConfig: baseColConfig = {},
                  inputConfig = {},
                  label,
                  divider,
                  name
                } = schema;

                const ColConfig = {
                  key: uniqueId + name + index,
                  xs: 24,
                  ...colConfig,
                  ...baseColConfig
                };

                if (divider) {
                  const { title, titleProps, ...restDividerProps } =
                    divider ?? {};

                  return (
                    <Col {...ColConfig}>
                      <CustomDivider
                        title={title}
                        titleProps={{ ...titleProps }}
                        {...restDividerProps}
                      />
                    </Col>
                  );
                }

                const {
                  name: inputTypeName = InputTypes.input.name,
                  type,
                  formItemProps = {},
                  inputProps = {},
                  data = [],
                  required = false,
                  derived = {
                    names: [],
                    function: () => {}
                  }
                } = deepMergeObjects(
                  deepMergeObjects(inputConfig, extraInputConfig[name] ?? {}),
                  commonInputConfig
                );

                if (Array.isArray(derived?.names) && !!derived?.names?.length) {
                  const derivedValues = derived.names.reduce(
                    (acc, key) => (acc = { ...acc, [key]: formik.values[key] }),
                    {}
                  );

                  let shouldSetFieldValue = false;

                  Object.keys(derivedValues).forEach(key => {
                    if (derivedValues[key] !== initialValuesClone[key]) {
                      initialValuesClone[key] = derivedValues[key];

                      shouldSetFieldValue = true;
                    }
                  });

                  if (shouldSetFieldValue) {
                    const derivedResult = derived.function(derivedValues);
                    formik.setFieldValue(name, derivedResult, true);
                  }
                }

                const InputComponent =
                  InputTypes[inputTypeName].component(type);

                const errors = handleErrors(schema?.name, formik);

                return (
                  <Col {...ColConfig}>
                    <Form.Item
                      label={label}
                      help={!!errors ? errors : null}
                      hasFeedback
                      required={required}
                      validateStatus={!!errors ? 'error' : null}
                      {...formItemProps}>
                      <InputComponent
                        disabled={loading}
                        error={errors}
                        {...formik.getFieldProps(name)}
                        {...inputProps}>
                        {/* The below code is for rendering options for `Select` only */}
                        {inputTypeName === InputTypes.select.name &&
                        Array.isArray(data) &&
                        !!data.length
                          ? data.map((item, index) => {
                              const { value, label } = item;

                              return (
                                <Select.Option
                                  value={value}
                                  key={uniqueId + value + index}>
                                  {label}
                                </Select.Option>
                              );
                            })
                          : null}
                      </InputComponent>
                    </Form.Item>
                  </Col>
                );
              })}

              {renderFooter && React.isValidElement(renderFooter) && (
                <Col span={24}>
                  {React.cloneElement(renderFooter, { formik, loading })}
                </Col>
              )}

              {showSaveButton && (
                <Col
                  span={24}
                  className={
                    screens.xs || saveButtonProps?.block
                      ? ''
                      : 'flex justify-end'
                  }>
                  <Space
                    size={CommonGutterConfig.gutterHorizontalSm}
                    align={'center'}>
                    {saveButtonPrefixNode &&
                      React.isValidElement(saveButtonPrefixNode) && (
                        <Form.Item>
                          {React.cloneElement(saveButtonPrefixNode, {
                            disabled: !formik.isValid,
                            loading: loading
                          })}
                        </Form.Item>
                      )}

                    <Form.Item>
                      <Button
                        block={screens.xs}
                        {...saveButtonProps}
                        type={'primary'}
                        htmlType={'submit'}
                        disabled={!formik.isValid}
                        loading={loading}>
                        {saveButtonProps?.title
                          ? saveButtonProps.title
                          : 'SAVE'}
                      </Button>
                    </Form.Item>
                  </Space>
                </Col>
              )}
            </Row>
          </Form>
        );
      }}
    </Formik>
  );
}

export default React.memo(FormInput);
