import { Button } from 'antd';
import React from 'react';
import FormInput from '../../../../../Components/Cells/FormInput';
import { schema } from './schema';

function ClientFilter({
  filter = {},
  onFilter = () => {},
  closeModal = () => {}
}) {
  const onSubmit = React.useCallback(values => {
    onFilter(values);
    closeModal();
  }, []);

  return (
    <FormInput
      schemes={schema}
      defaultValues={filter}
      saveButtonProps={{ title: 'FILTER' }}
      saveButtonPrefixNode={
        <Button type={'dashed'} danger onClick={() => onSubmit({})}>
          CLEAR
        </Button>
      }
      onSubmit={onSubmit}
    />
  );
}

export default React.memo(ClientFilter);
