import { CloseOutlined } from '@ant-design/icons';
import { Col, Divider, Image, Row, Tag } from 'antd';
import React from 'react';
import { NavLink } from 'react-router-dom';
import { CommonGutterConfig, ClientStatuses } from '../../../Configs/common';
import { Images } from '../../../Shared/images';
import CustomText from '../../Atoms/CustomText';
import ButtonGroup from '../../Molecules/ButtonGroup';
import './index.less';
import PlatformCard from './Components/PlatformCard';

function ClientDisplayCard({
  clientName = '',
  clientLogo = '',
  bizId = null,
  platformDetails = [],
  status = ClientStatuses.active.key,
  viewState = {},
  viewPath = '',
  onDelete = () => {}
}) {
  return (
    <NavLink to={{ pathname: viewPath, state: viewState }}>
      <Row
        className={'min-w-full client-card-container px-2 py-3 rounded'}
        gutter={CommonGutterConfig.gutterSm}>
        <Col className={'flex-grow'}>
          <CustomText className={'font-bold text-base'} element={'paragraph'}>
            {clientName}
          </CustomText>
        </Col>

        <Col>
          <ButtonGroup
            buttons={[
              {
                icon: <CloseOutlined />,
                title: 'Delete',
                className: 'text-base',
                danger: true,
                dangerProps: {
                  title: `Are you sure to delete "${clientName}"?`
                },
                onClick: onDelete
              }
            ]}
            type={'iconButton'}
          />
        </Col>

        <Col span={24}>
          <Divider className={'my-1'} />
        </Col>

        <Col xs={14} sm={12} md={10}>
          <Image
            src={clientLogo}
            className={'w-40 h-40 object-contain'}
            preview={false}
            fallback={Images.placeholderImage}
          />
        </Col>

        <Col xs={10} sm={12} md={14}>
          <Row
            className={'min-w-full'}
            gutter={CommonGutterConfig.gutterVerticalSm}
            wrap>
            <Col span={24}>
              <CustomText
                className={'text-sm text-right'}
                element={'paragraph'}
                copyable={{ text: bizId }}>
                Biz Id: {bizId}
              </CustomText>
            </Col>

            <Col span={24} className={'flex flex-row justify-end'}>
              <Tag
                color={ClientStatuses[status]?.color}
                icon={ClientStatuses[status]?.icon}
                className={'mr-0'}>
                {ClientStatuses[status]?.label}
              </Tag>
            </Col>
          </Row>
        </Col>

        <Col span={24}>
          <Row className={'min-w-full'} gutter={8} justify={'space-between'}>
            {platformDetails.map((item, index) => {
              return (
                <PlatformCard
                  key={index}
                  platform={item.platform}
                  status={item.status}
                  version={item.version}
                  lastUpdate={item.lastUpdate}
                />
              );
            })}
          </Row>
        </Col>
      </Row>
    </NavLink>
  );
}

export default React.memo(ClientDisplayCard);
