import * as Yup from 'yup';

const tooltipConfig = title => ({
  title
});

export const FirebaseSchema = [
  {
    colConfig: { xs: 24 },
    divider: { title: 'Firebase configurations' }
  },
  {
    name: 'googleServicesJSON',
    label: 'Google services JSON file',
    colConfig: { xs: 24, sm: 6 },
    inputConfig: {
      name: 'file',
      formItemProps: {
        tooltip: tooltipConfig('Google services JSON file for Android')
      },
      inputProps: {
        title: 'Upload JSON file',
        accept: '.json',
        showUploadButton: true
      },
      validation: Yup.mixed()
    }
  },
  {
    name: 'googleServicesPLIST',
    label: 'Google services .plist file',
    colConfig: { xs: 24, sm: 6 },
    inputConfig: {
      name: 'file',
      formItemProps: {
        tooltip: tooltipConfig('Google services .plist file for iOS updates')
      },
      inputProps: {
        title: 'Upload .plist file',
        accept: '.plist',
        showUploadButton: true
      },
      validation: Yup.mixed()
    }
  }
];
