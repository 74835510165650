import { RocketFilled } from '@ant-design/icons';
import { Button, Col, Input, Row, Space } from 'antd';
import React from 'react';
import { CommonGutterConfig } from '../../../Configs/common';
import ReleaseCard from '../../../Components/Cells/ReleaseCard';
import Toolbar from '../../../Components/Cells/Toolbar';
import { SubRoutes } from '../../../Configs/routes';
import { withDashboard } from '../../../Layouts/Dashboard';
import MessageService from '../../../Services/messageService';
import { NavLink } from 'react-router-dom';
import './container.less';
import UseQuery from '../../../Components/Wrappers/UseQuery';
import { GithubQueries } from '../../../Apis/github';
import LoadingProvider from '../../../Components/Wrappers/LoadingProvider';
import { stripFaultyData } from '../../../Services/generalServices';

class Releases extends React.PureComponent {
  constructor(props) {
    super(props);

    this.state = {
      search: ''
    };
  }

  componentDidMount() {
    this.props.setDashboardHeader(this.setDashboardHeader());
  }

  setDashboardHeader = () => {
    return <Toolbar title={'Releases'} />;
  };

  handleSearch = ({ target: { value } }) => {
    this.setState({ search: value });
  };

  handleFilteredDataRender = (data = []) => {
    const { search } = this.state;

    const searchRegExp = new RegExp(stripFaultyData(search), 'i');

    return data?.filter?.(
      item =>
        searchRegExp.test(item.name) ||
        searchRegExp.test(item.tag_name) ||
        item.name.includes(search) ||
        item.tag_name.includes(search)
    );
  };

  render() {
    const {
      match: { path }
    } = this.props;
    const { search } = this.state;

    return (
      <UseQuery
        queryKey={GithubQueries.getAllReleases.key}
        queryOptions={{
          onError: error => {
            MessageService.error(error.message);
          }
        }}>
        {({ query: { data: { data = [] } = {}, isFetching, refetch } }) => {
          return (
            <LoadingProvider loading={isFetching}>
              <Row
                className={'min-w-full'}
                gutter={CommonGutterConfig.gutterVerticalBase}>
                <Col span={24}>
                  <Toolbar
                    title={'All Releases'}
                    size={'middle'}
                    onReload={refetch}
                    loading={isFetching}
                    renderActions={() => {
                      return (
                        <Input.Search
                          enterButton
                          allowClear
                          placeholder={'Find release by name'}
                          value={search}
                          loading={isFetching}
                          onChange={this.handleSearch}
                        />
                      );
                    }}
                  />
                </Col>

                <Col span={24}>
                  <Space
                    direction={'vertical'}
                    size={'middle'}
                    className={'min-w-full'}>
                    {this.handleFilteredDataRender(data)?.map?.(
                      (release, index) => {
                        const viewPath =
                          path + '/' + release.id + SubRoutes.VIEW;

                        return (
                          <ReleaseCard
                            key={release.id}
                            isLatest={index === 0}
                            releaseVersion={release.name}
                            deployedDate={release.published_at}
                            description={release.body}
                            to={viewPath}
                          />
                        );
                      }
                    )}
                  </Space>
                </Col>
              </Row>
            </LoadingProvider>
          );
        }}
      </UseQuery>
    );
  }
}

export default withDashboard(Releases);
