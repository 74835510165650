import { Space, Tabs } from 'antd';
import React from 'react';
import { Colors } from '../../../Common/Colors/colors';
import CustomText from '../../Atoms/CustomText';

function CustomTab({
  tabs = [],
  defaultActiveKey = '',
  labelProps,
  onChange = () => {},
  tabComponentProps = {},
  ...rest
}) {
  const [activeKey, setActiveKey] = React.useState(
    defaultActiveKey ? defaultActiveKey : tabs[0].key
  );
  const [renderedTabs, setRenderedTabs] = React.useState([
    defaultActiveKey ? defaultActiveKey : tabs[0].key
  ]);

  const handleChange = React.useCallback(
    key => {
      setActiveKey(key);
      onChange(key);

      if (!renderedTabs.includes(key)) {
        const _renderedTabs = [...renderedTabs, key];
        setRenderedTabs(_renderedTabs);
      }
    },
    [renderedTabs, onChange]
  );

  return (
    <Tabs type={'card'} activeKey={activeKey} onChange={handleChange} {...rest}>
      {tabs.map((tab = {}) => {
        const {
          label,
          icon: InActiveIcon,
          activeIcon: ActiveIcon,
          labelProps: tabLabelProps,
          component,
          key,
          color = Colors.primaryColor
        } = tab;

        const isActive = key === activeKey;
        const activeTabProps = isActive ? { style: { color } } : {};
        const Icon = isActive ? ActiveIcon : InActiveIcon;

        return (
          <Tabs.TabPane
            key={key}
            tabKey={key}
            tab={
              <Space
                size={0}
                {...labelProps}
                {...activeTabProps}
                {...tabLabelProps}>
                <Icon className={'text-current'} />

                <CustomText className={'text-current'}>{label}</CustomText>
              </Space>
            }>
            {renderedTabs.includes(key)
              ? React.cloneElement(component, { ...tabComponentProps })
              : null}
          </Tabs.TabPane>
        );
      })}
    </Tabs>
  );
}

export default React.memo(CustomTab);
