import { ConsoleSqlOutlined } from '@ant-design/icons';
import axios from 'axios';
import { createClient } from 'graphql-ws';
import { BASE_URL, SOCKET_URL } from '../Configs/environment';
import Store from '../Redux/store';
import { logoutThunk } from '../Redux/Thunks/user';
import Utilities from '../Utilities';

export const StatusCodes = {
  SUCCESS: 200,
  SERVER_ERROR: 500,
  UNAUTHORIZED: 401,
  BAD_REQUEST: 400,
  NOT_FOUND: 404
};

const GQL = {
  getInstance: () => {
    return createClient({
      url: SOCKET_URL,
      connectionParams: async () => {
        return {
          isWebSocket: true,
          headers: {
            authorization: `Bearer ${Utilities.secretToken}`
          }
        };
      }
    });
  }
};

const Axios = {
  getInstance: () => {
    const instance = axios.create({
      baseURL: BASE_URL,
      headers: {
        'content-type': 'application/json',
        accept: 'application/json',
        Authorization: 'Bearer ' + Utilities.secretToken
      },
      transformResponse: axios.defaults.transformResponse.concat(data => {
        if (data.statusCode === StatusCodes.UNAUTHORIZED) {
          Store.dispatchAction(logoutThunk(true));
        }

        return data;
      })
    });

    instance.interceptors.response.use(
      response => {
        return {
          data: response.data,
          statusCode: response.status
        };
      },
      error => {
        const errorMessage = error?.response?.data?.error ?? '';
        const message = error?.response?.data?.message ?? 'Not found';
        const statusCode = error?.response?.data?.statusCode ?? 404;

        const newError = {
          message: Array.isArray(message) ? message.join(', ') : message,
          description: { errorMessage, message },
          statusCode
        };

        return Promise.reject(newError);
      }
    );

    return instance;
  }
};

export default Axios;
export { GQL };
