import { AndroidFilled, AppleFilled } from '@ant-design/icons';
import { Badge, Col, Space, Tag } from 'antd';
import React from 'react';
import CustomText from '../../../../Atoms/CustomText';
import { Colors } from '../../../../../Common/Colors/colors';
import { Statuses } from '../../../../../Configs/common';
import './index.less';

const Platform = {
  android: {
    key: 'android',
    icon: AndroidFilled,
    color: Colors.android
  },
  ios: {
    key: 'ios',
    icon: AppleFilled,
    color: Colors.ios
  }
};

function PlatformCard({
  platform = Platform.android.key,
  status,
  version,
  lastUpdate
}) {
  const Icon = Platform[platform].icon;

  return (
    <Col span={12}>
      <Badge.Ribbon
        color={Platform[platform].color}
        text={
          <Space size={4}>
            <Icon className={'text-sm'} />
          </Space>
        }>
        <Space
          direction={'vertical'}
          size={[0, 0]}
          className={'platform-container p-2 pt-8 min-w-full rounded'}>
          <CustomText className={'text-xs'}>
            Status:{' '}
            <Tag color={Statuses[status].color}>{Statuses[status].label}</Tag>
          </CustomText>

          <CustomText className={'text-xs'}>Version: {version}</CustomText>

          <CustomText className={'text-xs'}>
            Last Update: {lastUpdate}
          </CustomText>
        </Space>
      </Badge.Ribbon>
    </Col>
  );
}

export default React.memo(PlatformCard);
