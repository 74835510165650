import { RocketOutlined, TeamOutlined, BuildOutlined } from '@ant-design/icons';
import { NavLink } from 'react-router-dom';
import AllClients from '../Pages/AllClients/AllClients';
import ViewClient from '../Pages/AllClients/ViewClient';
import Releases from '../Pages/Releases/Releases';
import NewRelease from '../Pages/Releases/NewRelease';
import ViewRelease from '../Pages/Releases/ViewRelease';
import AllBuilds from '../Pages/AllBuilds';
import SignIn from '../Pages/SignIn';
import Dashboard from '../Layouts/Dashboard';
import Error from '../Pages/Error';
import TestCreds from '../Pages/TestCreds';

const getNavLink = (label = '', to = '') => {
  return <NavLink to={RouteNames.DASHBOARD + to}>{label}</NavLink>;
};

export const RouteNames = {
  DASHBOARD: '/dashboard',
  ALL_CLIENTS: '/all-clients',
  RELEASES: '/releases',
  SIGN_IN: '/sign-in',
  ALL_BUILDS: '/all-builds',
  ERROR: '/error',
  TEST_CREDS: '/test-creds'
};

export const SubRoutes = {
  CREATE: '/create',
  EDIT: '/edit',
  CLONE: '/clone',
  VIEW: '/view'
};

export const RouteParams = {
  ID: '/:id'
};

export const SideBarContents = {
  ALL_CLIENTS: {
    key: 'ALL_CLIENTS',
    path: RouteNames.ALL_CLIENTS,
    routeConfig: {
      title: () => 'All clients'
    },
    component: AllClients,
    label: getNavLink('All Clients', RouteNames.ALL_CLIENTS),
    icon: <TeamOutlined />,
    subComponents: {
      viewClient: {
        key: 'viewClient',
        routeConfig: {
          title: clientName =>
            `View client${clientName ? ' - ' + clientName : ''}`
        },
        config: {
          path: RouteParams.ID + SubRoutes.VIEW,
          component: ViewClient
        }
      }
    }
  },

  RELEASES: {
    key: 'RELEASES',
    path: RouteNames.RELEASES,
    routeConfig: {
      title: () => 'Releases'
    },
    component: Releases,
    label: getNavLink('Releases', RouteNames.RELEASES),
    icon: <RocketOutlined />,
    subComponents: {
      createRelease: {
        key: 'createRelease',
        routeConfig: {
          title: () => 'Create release'
        },
        config: {
          path: SubRoutes.CREATE,
          component: NewRelease
        }
      },
      viewRelease: {
        key: 'viewRelease',
        routeConfig: {
          title: () => 'View release'
        },
        config: {
          path: RouteParams.ID + SubRoutes.VIEW,
          component: ViewRelease
        }
      }
    }
  },
  ALL_BUILDS: {
    key: 'ALL_BUILDS',
    path: RouteNames.ALL_BUILDS,
    routeConfig: {
      title: () => 'All builds'
    },
    component: AllBuilds,
    label: getNavLink('All Builds', RouteNames.ALL_BUILDS),
    icon: <BuildOutlined />
  }
};

export const UnAuthorizedRouteNames = {
  SIGN_IN: {
    key: 'SIGN_IN',
    path: RouteNames.SIGN_IN,
    routeConfig: {
      title: () => 'Sign in'
    },
    component: SignIn
  },

  DASHBOARD: {
    key: 'DASHBOARD',
    path: RouteNames.DASHBOARD,
    component: Dashboard
  },

  ERROR: {
    key: 'ERROR',
    path: RouteNames.ERROR,
    routeConfig: {
      title: () => 'Error'
    },
    component: Error
  },

  TEST_CREDS: {
    key: 'TEST_CREDS',
    path: RouteNames.TEST_CREDS + RouteParams.ID,
    routeConfig: {
      title: () => 'Test Creds'
    },
    component: TestCreds
  }
};

export const AuthorizedRouteNames = {
  ...SideBarContents
};
