import Axios from '.';

const UserApiRoutes = {
  base: '/user'
};

/**
 * Object key should be same as the `key` value present inside the object value.
 */
export const UserQueries = {};

/**
 * Object key should be same as the `key` value present inside the object value.
 */
export const UserMutations = {
  login: {
    key: 'login',
    api: tokenId =>
    Axios.getInstance().post(`${UserApiRoutes.base}/google`, {
      credential: tokenId
    })
  },
  loginWithSlack: {
    key: 'loginWithSlack',
    api: tokenId =>
    Axios.getInstance().get(`${UserApiRoutes.base}/slack?code=${tokenId}`)
  }
};
