import React from 'react';
import { connect } from 'react-redux';
import { Switch, Route, Redirect } from 'react-router-dom';
import { UnAuthorizedRouteNames } from '../Configs/routes';
import WithDefaultSetting from './Components/WithDefaultSetting';

class Routes extends React.PureComponent {
  render() {
    const { isLoggedIn } = this.props;

    return (
      <Switch>
        <Route
          exact
          path={UnAuthorizedRouteNames.SIGN_IN.path}
          component={props => {
            const Component = UnAuthorizedRouteNames.SIGN_IN.component;

            if (isLoggedIn)
              return <Redirect to={UnAuthorizedRouteNames.DASHBOARD.path} />;

            return (
              <WithDefaultSetting
                props={{ ...props }}
                defaults={{ ...UnAuthorizedRouteNames.SIGN_IN.routeConfig }}
                Component={Component}
              />
            );
          }}
        />

        <Route
          path={UnAuthorizedRouteNames.DASHBOARD.path}
          component={props => {
            const Component = UnAuthorizedRouteNames.DASHBOARD.component;

            if (!isLoggedIn)
              return <Redirect to={UnAuthorizedRouteNames.SIGN_IN.path} />;

            return <Component {...props} />;
          }}
        />

        <Route
          path={UnAuthorizedRouteNames.ERROR.path}
          component={props => {
            return (
              <WithDefaultSetting
                props={{ ...props }}
                defaults={{ ...UnAuthorizedRouteNames.ERROR.routeConfig }}
                Component={UnAuthorizedRouteNames.ERROR.component}
              />
            );
          }}
        />

        <Route
          exact
          path={UnAuthorizedRouteNames.TEST_CREDS.path}
          component={props => {
            const Component = UnAuthorizedRouteNames.TEST_CREDS.component;

            if (isLoggedIn)
              return <Redirect to={UnAuthorizedRouteNames.DASHBOARD.path} />;

            return (
              <WithDefaultSetting
                props={{ ...props }}
                defaults={{ ...UnAuthorizedRouteNames.TEST_CREDS.routeConfig }}
                Component={Component}
              />
            );
          }}
        />

        <Route
          component={() => {
            return (
              <Redirect
                to={
                  isLoggedIn
                    ? UnAuthorizedRouteNames.DASHBOARD.path
                    : UnAuthorizedRouteNames.SIGN_IN.path
                }
              />
            );
          }}
        />
      </Switch>
    );
  }
}

const mapStateToProps = state => ({
  isLoggedIn: state.user.isLoggedIn
});

export default connect(mapStateToProps)(Routes);
