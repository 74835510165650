export const Images = {
  urbanPiperLogo: [
    require('../Assets/Images/UrbanpiperLogo/urbanpiper-logo.webp'),
    require('../Assets/Images/UrbanpiperLogo/urbanpiper-logo.jpeg')
  ],
  signIn: [
    [
      require('../Assets/Images/SignIn/sign-in-1.webp'),
      require('../Assets/Images/SignIn/sign-in-1.jpeg')
    ],
    [
      require('../Assets/Images/SignIn/sign-in-2.webp'),
      require('../Assets/Images/SignIn/sign-in-2.jpeg')
    ],
    [
      require('../Assets/Images/SignIn/sign-in-3.webp'),
      require('../Assets/Images/SignIn/sign-in-3.jpeg')
    ],
    [
      require('../Assets/Images/SignIn/sign-in-4.webp'),
      require('../Assets/Images/SignIn/sign-in-4.jpeg')
    ],
    [
      require('../Assets/Images/SignIn/sign-in-5.webp'),
      require('../Assets/Images/SignIn/sign-in-5.jpeg')
    ]
  ],
  placeholderImage: require('../Assets/Images/placeholder-image.png')
};
