import {
  AndroidFilled,
  AppleFilled,
  MinusSquareOutlined,
  PlusSquareOutlined
} from '@ant-design/icons';
import { Col, Input, Row, Space } from 'antd';
import React from 'react';
import { Colors } from '../../../Common/Colors/colors';
import { CommonGutterConfig } from '../../../Configs/common';
import { stripFaultyData } from '../../../Services/generalServices';
import ButtonGroup from '../../Molecules/ButtonGroup';
import ClientPlatformCard from '../../Molecules/ClientPlatformCard';
import Toolbar from '../Toolbar';

const Platforms = {
  ios: {
    key: 'ios',
    icon: <AppleFilled />,
    iconProps: {
      style: { color: Colors.ios }
    }
  },
  android: {
    key: 'android',
    icon: <AndroidFilled />,
    iconProps: {
      style: { color: Colors.android }
    }
  }
};

const DisplayApps = React.forwardRef(
  (
    {
      value = [],
      selectable = true,
      name = '',
      onChange = () => {},
      onBlur = () => {}
    },
    ref
  ) => {
    const [appsList, setAppsList] = React.useState(value);
    const [search, setSearch] = React.useState('');
    const [selectedApps, setSelectedApps] = React.useState(value);
    const uniqueId = React.useId();

    const setSelectedAppList = React.useCallback(
      apps => {
        setSelectedApps(apps);

        const event = {
          target: {
            name,
            value: apps
          }
        };

        onChange(event);
        setTimeout(() => onBlur(event), 100);
      },
      [setSelectedApps, onChange, onBlur]
    );

    const onSelectApp = React.useCallback(
      app => {
        if (app) {
          if (!selectedApps.includes(app)) {
            const selectedAppsClone = [...selectedApps, app];
            setSelectedAppList(selectedAppsClone);
          }
        } else {
          setSelectedAppList(value);
        }
      },
      [setSelectedAppList, value, selectedApps]
    );

    const onDeselectApp = React.useCallback(
      app => {
        if (app) {
          const index = selectedApps.indexOf(app);

          const selectedAppsClone = [...selectedApps];
          selectedAppsClone.splice(index, 1);
          setSelectedAppList(selectedAppsClone);
        } else {
          setSelectedAppList([]);
        }
      },
      [selectedApps, setSelectedAppList]
    );

    const Buttons = React.useMemo(
      () => [
        {
          title: 'Select all',
          icon: <PlusSquareOutlined />,
          onClick: onSelectApp
        },
        {
          title: 'Deselect all',
          icon: <MinusSquareOutlined />,
          danger: true,
          showPopUpConfirm: false,
          onClick: onDeselectApp
        }
      ],
      []
    );

    const onChangeAppSearch = React.useCallback(
      ({ target: { value } }) => {
        setSearch(value);
      },
      [setSearch]
    );

    const title = React.useMemo(
      () =>
        selectable
          ? `Apps: ${selectedApps.length} / ${appsList.length}`
          : 'Apps: ' + appsList.length,
      [appsList, selectable, selectedApps]
    );

    const handleFilteredDataRender = (data = []) => {
      return data.filter(item =>
        item.clientName.includes(stripFaultyData(search))
      );
    };

    return (
      <Row
        ref={ref}
        className={'min-w-full apps-data-container shadow-inner p-4'}
        gutter={CommonGutterConfig.gutterVerticalBase}>
        <Col span={24}>
          <Toolbar
            title={title}
            size={'middle'}
            renderActions={() => (
              <Space>
                {selectable && (
                  <ButtonGroup
                    buttons={Buttons}
                    TooltipProps={{ placement: 'top' }}
                  />
                )}

                <Input.Search
                  enterButton
                  allowClear
                  placeholder={'Find app'}
                  value={search}
                  onChange={onChangeAppSearch}
                />
              </Space>
            )}
          />
        </Col>

        <Col span={24}>
          <Row
            className={'min-w-full'}
            gutter={CommonGutterConfig.gutterVerticalBase}>
            {handleFilteredDataRender(appsList).map((app, index) => {
              const isSelected = selectedApps.includes(app);

              const selectableProps = selectable
                ? {
                    onRemove: () => onDeselectApp(app),
                    onSelect: () => onSelectApp(app)
                  }
                : {};

              return (
                <Col xs={8} sm={2} xxl={1} key={uniqueId + index}>
                  <ClientPlatformCard
                    name={app.clientName}
                    icon={Platforms[app.platform].icon}
                    iconProps={Platforms[app.platform].iconProps}
                    selected={isSelected}
                    {...selectableProps}
                  />
                </Col>
              );
            })}
          </Row>
        </Col>
      </Row>
    );
  }
);

export default React.memo(DisplayApps);
