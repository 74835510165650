import React from 'react';
import { QueryClient, QueryClientProvider } from 'react-query';
import { Provider } from 'react-redux';
import { BrowserRouter } from 'react-router-dom';
import { PersistGate } from 'redux-persist/integration/react';
import { GoogleOAuthProvider } from '@react-oauth/google';
import { GOOGLE_CLIENT_ID } from '../Configs/environment';
import LoadingProvider from '../Components/Wrappers/LoadingProvider';
import ModalProvider from '../Components/Wrappers/ModalProvider';
import Store from '../Redux/store';
import Routes from '../Routes';
import { setLoadingRef } from '../Services/loadingService';
import { setModalRef } from '../Services/modalService';
import { setHistoryRef } from '../Services/routerService';

const queryClient = new QueryClient({
  defaultOptions: {
    queries: {
      refetchOnWindowFocus: false,
      retry: false
    },
    mutations: {
      retry: false
    }
  }
});

function App() {
  return (
    <React.Fragment>
      <Provider store={Store.store}>
        <PersistGate loading={null} persistor={Store.persistor}>
          <QueryClientProvider client={queryClient}>
            <LoadingProvider ref={ref => setLoadingRef(ref)}>
              <ModalProvider ref={ref => setModalRef(ref)}>
                <GoogleOAuthProvider clientId={GOOGLE_CLIENT_ID}>
                  <BrowserRouter ref={ref => setHistoryRef(ref?.history)}>
                    <Routes />
                  </BrowserRouter>
                </GoogleOAuthProvider>
              </ModalProvider>
            </LoadingProvider>
          </QueryClientProvider>
        </PersistGate>
      </Provider>
    </React.Fragment>
  );
}

export default App;
