import { Carousel, Col, Row, Space } from 'antd';
import React from 'react';
import CustomImage from '../../Components/Atoms/CustomImage';
import CustomText from '../../Components/Atoms/CustomText';
import './index.less';
import { DashboardName, DashboardTagLine } from '../../Configs/app';
import Footer from '../../Components/Molecules/Footer';
import SignInButton from '../../Components/Atoms/SignInButton';
import { CommonGutterConfig, FoodQuotes } from '../../Configs/common';

function SignIn() {
  const uniqueId = React.useId();

  return (
    <Row className={'min-w-full h-screen'}>
      <Col xs={0} md={17} className={'h-screen'}>
        <Carousel
          fade
          waitForAnimate
          dots={false}
          autoplay
          autoplaySpeed={4500}>
          {FoodQuotes.map((item, index) => {
            return (
              <div key={uniqueId + index}>
                <div className={'carousel-container'}>
                  <CustomImage
                    imageList={item.imageSet}
                    imgClassName={'object-cover object-center h-screen w-full'}
                  />
                </div>

                <div className={'carousel-quote p-2'}>
                  <Space className={'min-w-full'} direction={'vertical'}>
                    <CustomText
                      element={'paragraph'}
                      className={'text-2xl italic text-center'}
                      light>
                      "{item.quote}"
                    </CustomText>

                    <CustomText
                      element={'paragraph'}
                      className={'text-right font-semibold italic'}
                      light>
                      - {item.author}
                    </CustomText>
                  </Space>
                </div>
              </div>
            );
          })}
        </Carousel>
      </Col>

      <Col xs={24} md={7} className={'h-screen'}>
        <div className={'signin-sider-container p-10 h-full'}>
          <div className={'h-full flex items-center justify-center'}>
            <div>
              <Space
                direction={'vertical'}
                className={'w-full'}
                size={CommonGutterConfig.gutterVerticalBase}>
                <CustomText
                  element={'paragraph'}
                  className={'text-3xl text-center font-bold font-sans'}>
                  Welcome To{' '}
                  <CustomText className={'title'}>{DashboardName}</CustomText>
                </CustomText>

                {!!DashboardTagLine && (
                  <CustomText
                    element={'paragraph'}
                    className={'text-right text-xs italic font-medium'}
                    type={'secondary'}>
                    {DashboardTagLine}
                  </CustomText>
                )}

                <div className={'flex justify-center w-full'}>
                  <SignInButton />
                </div>
              </Space>
            </div>
          </div>

          <Footer />
        </div>
      </Col>
    </Row>
  );
}

export default SignIn;
