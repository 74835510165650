import React from 'react';
import { createRoot } from 'react-dom/client';
import './index.less';
import './index.css';
import App from './App';
import reportWebVitals from './reportWebVitals';

import * as Sentry from '@sentry/react';
import { BrowserTracing } from '@sentry/tracing';
import { PRODUCTION_MODE, SENTRY_DSN } from './Configs/environment';

if (SENTRY_DSN) {
  Sentry.init({
    dsn: SENTRY_DSN,
    debug: !PRODUCTION_MODE,
    integrations: [new BrowserTracing()],
    tracesSampleRate: 1.0
  });
}

const Container = document.getElementById('root');
const root = createRoot(Container);
root.render(<App />);

// If you want to start measuring performance in your app, pass a function
// to log results (for example: reportWebVitals(console.log))
// or send to an analytics endpoint. Learn more: https://bit.ly/CRA-vitals
reportWebVitals();
