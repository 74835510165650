import React from 'react';
import { useQuery, useQueryClient } from 'react-query';
import { AllQueries } from '../../../Apis/allApis';

/**
 * Custom useQuery hook for managing the query and reFetching logic.
 */
function useCustomQuery({ queryKey = '', extraData = [], queryOptions = {} }) {
  const queryClient = useQueryClient();

  const reFetch = React.useCallback(
    (extraData = [], fetchQueryOptions = {}) => {
      queryClient.prefetchQuery([queryKey, ...extraData], fetchQueryOptions);
    },
    [queryKey, queryClient]
  );

  const query = useQuery(
    [queryKey, ...extraData],
    () => AllQueries[queryKey]?.api?.(...extraData),
    queryOptions
  );

  return { query, reFetch, queryClient };
}

function UseQuery({
  children,
  queryKey = '',
  extraData = [],
  queryOptions = {}
}) {
  return children({ ...useCustomQuery({ queryKey, extraData, queryOptions }) });
}

export { useCustomQuery };
export default React.memo(UseQuery);
