import { AppstoreOutlined, TableOutlined } from '@ant-design/icons';
import { Col, Empty } from 'antd';
import React from 'react';
import ClientDisplayCard from '../../../../../Components/Cells/ClientDisplayCard';
import CustomTable from '../../../../../Components/Molecules/CustomTable';
import { SubRoutes } from '../../../../../Configs/routes';
import { Columns } from './schema';

export const ClientDisplayType = {
  grid: {
    key: 'grid',
    label: 'Grid view',
    icon: <AppstoreOutlined className={'text-base'} />
  },
  table: {
    key: 'table',
    label: 'Table view',
    icon: <TableOutlined className={'text-base'} />
  }
};

function ClientDisplay({
  data,
  onDeleteClient = () => {},
  path = '',
  displayType = ClientDisplayType.grid.key
}) {
  const DisplayType = React.useMemo(
    () => ({
      [ClientDisplayType.grid.key]: {
        render: () => {
          if (!data.length)
            return (
              <Col span={24}>
                <Empty />
              </Col>
            );

          return data?.map?.(item => {
            return (
              <Col xs={24} sm={12} xl={8} xxl={6} key={item.id}>
                <ClientDisplayCard
                  clientName={item.bizName}
                  clientLogo={item.bizLogoUrl}
                  bizId={item.bizId}
                  status={item.status}
                  platformDetails={[
                    {
                      platform: 'android',
                      status: 'pendingRelease',
                      version: `8.0.0`,
                      lastUpdate: `Wed 25 2022, 01:25 PM`
                    },
                    {
                      platform: 'ios',
                      status: 'active',
                      version: `8.0.0`,
                      lastUpdate: `Tue 24 2022, 07:13 PM`
                    }
                  ]}
                  viewState={item}
                  viewPath={path + '/' + item.id + SubRoutes.VIEW}
                  onDelete={() => onDeleteClient(item)}
                />
              </Col>
            );
          });
        }
      },
      [ClientDisplayType.table.key]: {
        render: () => {
          return (
            <Col span={24}>
              <CustomTable
                dataSource={data}
                columns={Columns}
                extraData={{ path }}
                extraActions={{ onDeleteClient }}
              />
            </Col>
          );
        }
      }
    }),
    [data, path, onDeleteClient]
  );

  return DisplayType[displayType].render();
}

export default React.memo(ClientDisplay);
