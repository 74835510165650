import React from 'react';
import './index.less';

const IconButton = React.forwardRef(
  ({ icon, danger = false, className, ...rest }, ref) => {
    return (
      <div className={'cursor-pointer'} {...rest} ref={ref}>
        {React.cloneElement(icon, {
          className: `icon-container cursor-pointer ${danger ? 'danger' : ''} ${
            className ? className : ''
          }`
        })}
      </div>
    );
  }
);

export default React.memo(IconButton);
