export const Colors = {
  white: '#fff',
  primaryColor: '#6456f5',
  android: '#a4c639',
  ios: '#000',
  purple: '#9C27B0',
  blue: '#03A9F4',
  indigo: '#3F51B5',
  red: '#f44336',
  orange: '#FFA726'
};
