import * as Yup from 'yup';
import { Platforms, ReleaseTypes } from '../../../Configs/common';

const AppsData = [
  {
    platform: Platforms.ios.key,
    clientName: 'UrbanPiper'
  },
  {
    platform: Platforms.android.key,
    clientName: 'UrbanPiper'
  }
];

const tooltipConfig = title => ({
  title
});

export const schemes = [
  {
    name: 'typeOfUpdate',
    label: 'Type of update',
    colConfig: { xs: 24, md: 12 },
    inputConfig: {
      required: true,
      name: 'select',
      value: ReleaseTypes[0].value,
      data: ReleaseTypes,
      formItemProps: {
        tooltip: tooltipConfig('The type of update you want to send')
      },
      inputProps: { placeholder: 'Enter the type of update' },
      validation: Yup.string()
    }
  },
  {
    name: 'majorVersion',
    label: 'Major',
    colConfig: { xs: 12, md: 4 },
    inputConfig: {
      required: true,
      value: 1,
      formItemProps: {
        tooltip: tooltipConfig('Major version')
      },
      inputProps: { placeholder: 'Enter major version', type: 'number' },
      validation: Yup.number().min(1, 'Should be greater than ${min}')
    }
  },
  {
    name: 'minorVersion',
    label: 'Minor',
    colConfig: { xs: 12, md: 4 },
    inputConfig: {
      required: true,
      value: 0,
      formItemProps: {
        tooltip: tooltipConfig('Minor version')
      },
      inputProps: { placeholder: 'Enter minor version', type: 'number' },
      validation: Yup.number().min(
        0,
        'Should be greater than or equal to ${min}'
      )
    }
  },
  {
    name: 'patchVersion',
    label: 'Patch',
    colConfig: { xs: 12, md: 4 },
    inputConfig: {
      required: true,
      value: 0,
      formItemProps: {
        tooltip: tooltipConfig('Patch version')
      },
      inputProps: { placeholder: 'Enter patch version', type: 'number' },
      validation: Yup.number().min(
        0,
        'Should be greater than or equal to ${min}'
      )
    }
  },
  {
    name: 'description',
    label: "What's new in this version",
    colConfig: { xs: 24 },
    inputConfig: {
      required: true,
      type: 'TextArea',
      value: '',
      formItemProps: {
        tooltip: tooltipConfig(
          'Description of the new release, share what is new in the version.'
        )
      },
      inputProps: {
        placeholder: 'Enter what is new in this version',
        maxLength: 4000,
        showCount: true,
        autoSize: {
          minRows: 4,
          maxRows: 6
        }
      },
      validation: Yup.string().trim().max(4000)
    }
  },
  {
    divider: { title: 'Apps list' }
  },
  {
    name: 'targetedApps',
    label: 'Targeted Apps',
    colConfig: { span: 24 },
    inputConfig: {
      required: true,
      name: 'appsPicker',
      value: [...AppsData],
      formItemProps: {
        tooltip: tooltipConfig('Apps to target for this release')
      },
      inputProps: {
        selectable: true
      },
      validation: Yup.array().min(1, 'Minimum ${min} app should be selected.')
    }
  }
];
