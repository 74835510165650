import React from 'react';
import { Switch, Route, Redirect, withRouter } from 'react-router-dom';
import { AuthorizedRouteNames } from '../Configs/routes';
import WithDefaultSetting from './Components/WithDefaultSetting';

class AdminRoutes extends React.PureComponent {
  renderSubComponents = (subComponents = {}, path) => {
    return Object.values(subComponents).map((subItems, index) => {
      const {
        routeConfig = {},
        config: {
          path: subItemsPath,
          component: SubPageComponent,
          subComponentsProps = {},
          subComponents: subItemsComponents = []
        } = {}
      } = subItems;

      return (
        <Route
          key={index}
          path={path + subItemsPath}
          component={props => {
            const {
              match: { path }
            } = props;

            const hasSubItemsComponents =
              !!subItemsComponents && !!subItemsComponents.length;

            if (hasSubItemsComponents) {
              return (
                <Switch>
                  <Route
                    path={path}
                    exact
                    component={props => (
                      <WithDefaultSetting
                        props={{
                          ...props,
                          ...subComponentsProps
                        }}
                        defaults={routeConfig}
                        Component={SubPageComponent}
                      />
                    )}
                  />

                  {this.renderSubComponents(subItemsComponents, path)}

                  <Redirect to={path} />
                </Switch>
              );
            }

            return (
              <WithDefaultSetting
                props={{
                  ...props,
                  ...subComponentsProps
                }}
                defaults={routeConfig}
                Component={SubPageComponent}
              />
            );
          }}
        />
      );
    });
  };

  render() {
    const {
      match: { path }
    } = this.props;

    return (
      <Switch>
        {Object.values(AuthorizedRouteNames).map((item, index) => {
          const {
            path: PagePath,
            component: PageComponent,
            subComponents,
            componentProps = {},
            routeConfig = {}
          } = item;

          return (
            <Route
              key={index}
              path={`${path}${PagePath}`}
              component={({ match: { path } }) => (
                <Switch>
                  <Route
                    exact
                    path={path}
                    component={props => (
                      <WithDefaultSetting
                        props={{
                          ...props,
                          ...componentProps
                        }}
                        defaults={routeConfig}
                        Component={PageComponent}
                      />
                    )}
                  />

                  {!!subComponents &&
                    this.renderSubComponents(subComponents, path)}

                  <Redirect to={path} />
                </Switch>
              )}
            />
          );
        })}

        <Redirect exact to={path + AuthorizedRouteNames.ALL_CLIENTS.path} />
      </Switch>
    );
  }
}

export default withRouter(AdminRoutes);
