import { Col, Row } from 'antd';
import React from 'react';
import { ClientMutations } from '../../../../../Apis/clients';
import FormInput from '../../../../../Components/Cells/FormInput';
import { useCustomMutation } from '../../../../../Components/Wrappers/UseMutation';
import { SubRoutes } from '../../../../../Configs/routes';
import MessageService from '../../../../../Services/messageService';
import { NotificationService } from '../../../../../Services/notificationService';
import RouterService from '../../../../../Services/routerService';
import { AddClientSchema } from './schema';

function AddClientModal({
  path = '',
  closeModal = () => {},
  setModalProps = () => {}
}) {
  const { isLoading, mutateAsync } = useCustomMutation({
    mutationKey: ClientMutations.createClient.key
  });

  const onSubmit = React.useCallback(
    values => {
      setModalProps({ closable: false });

      mutateAsync(values)
        .then(({ data = {} } = {}) => {
          closeModal({
            onClose: () => {
              RouterService.pushRoute(
                `${path}/${data.id}${SubRoutes.VIEW}`,
                data
              );
            }
          });

          NotificationService.showNotification({
            type: 'success',
            message: 'Create client',
            description: 'Creating client successful!!!'
          });
        })
        .catch(error => {
          MessageService.error(error.message);

          setModalProps({ closable: true });
        });
    },
    [setModalProps, closeModal]
  );

  return (
    <Row className={'min-w-full'}>
      <Col span={24}>
        <FormInput
          size={'large'}
          saveButtonProps={{ block: true, title: 'VALIDATE' }}
          schemes={AddClientSchema}
          loading={isLoading}
          onSubmit={values => onSubmit(values)}
        />
      </Col>
    </Row>
  );
}

export default React.memo(AddClientModal);
