import { connect } from 'react-redux';
import AllClients from './container';

const mapStateToProps = state => ({
  userName: state.user.userData?.name
});

const mapDispatchToProps = {};

export default connect(mapStateToProps, mapDispatchToProps)(AllClients);
