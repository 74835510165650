import { Col, Row } from 'antd';
import React from 'react';
import FormInput from '../../../../../Components/Cells/FormInput';
import Toolbar from '../../../../../Components/Cells/Toolbar';
import { CommonGutterConfig } from '../../../../../Configs/common';
import { FirebaseSchema } from './schema';

class Integrations extends React.PureComponent {
  constructor(props) {
    super(props);

    this.state = {};
  }

  render() {
    return (
      <Row
        className={'min-w-full'}
        gutter={CommonGutterConfig.gutterVerticalLg}>
        <Col span={24}>
          <Toolbar size={'middle'} title={'External integrations'} />
        </Col>

        {/* <Col span={24}>
          <FormInput schemes={FirebaseSchema} />
        </Col> */}
      </Row>
    );
  }
}

export default Integrations;
