import { Image, Space } from 'antd';
import { NavLink } from 'react-router-dom';
import CustomText from '../../Components/Atoms/CustomText';
import { CommonGutterConfig } from '../../Configs/common';
import { RouteNames, SubRoutes } from '../../Configs/routes';
import { BuildColumns } from '../AllClients/ViewClient/Tabs/Builds/schema';

const ColumnsClone = [...BuildColumns];
ColumnsClone.shift();

const ClientColumn = {
  title: 'Client',
  dataIndex: 'client',
  key: 'client',
  width: 200,
  render: ({ value }) => {
    if (!value) return '-';

    return (
      <Space size={CommonGutterConfig.gutterHorizontalXs}>
        <Image src={value?.bizLogoUrl} width={40} preview={false} />

        <Space
          direction={'vertical'}
          size={CommonGutterConfig.gutterHorizontalXs}>
          <NavLink
            to={{
              pathname:
                RouteNames.DASHBOARD +
                RouteNames.ALL_CLIENTS +
                '/' +
                value.id +
                SubRoutes.VIEW,
              state: value
            }}>
            <CustomText className={'hover:underline'}>
              {value?.bizName}
            </CustomText>
          </NavLink>

          <CustomText
            className={'text-xs'}
            type={'secondary'}
            copyable={{ text: value?.bizId }}>
            Biz id: {value?.bizId}
          </CustomText>
        </Space>
      </Space>
    );
  },
  filterSearch: (input, record) =>
    new RegExp(input, 'gi').test(record.value) ||
    new RegExp(input, 'gi').test(record.bizId),

  onFilter: (value, record) =>
    new RegExp(value, 'gi').test(record.client.bizName)
};

export const Columns = [ClientColumn, ...ColumnsClone];
