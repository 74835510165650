import { configureStore } from '@reduxjs/toolkit';
import storage from 'redux-persist/lib/storage';
import AppReducers from './Reducers';
import { persistReducer, persistStore } from 'redux-persist';
import thunk from 'redux-thunk';

const persistConfig = {
  key: 'root',
  storage,
  whitelist: ['user']
};

const persistedReducer = persistReducer(persistConfig, AppReducers);

const store = configureStore({
  reducer: persistedReducer,
  devTools: process.env.NODE_ENV !== 'production',
  middleware: [thunk]
});

const persistor = persistStore(store);
const dispatchAction = store.dispatch;

const Store = {
  store,
  persistor,
  dispatchAction
};

export default Store;
