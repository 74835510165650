import { CopyrightOutlined } from '@ant-design/icons';
import React from 'react';
import CustomText from '../../Atoms/CustomText';
import moment from 'moment';
import { Col, Divider, Row } from 'antd';

function Footer({ showCaption = false, left = false, showDivider = false }) {
  return (
    <Row
      className={'min-w-full'}
      justify={left ? 'start' : 'end'}
      gutter={[4, 4]}>
      {!!showDivider && (
        <Col span={24}>
          <Divider />
        </Col>
      )}

      <Col>
        <CopyrightOutlined
          className={`text-gray-500 ${!showCaption ? 'text-xs' : ''}`}
        />
      </Col>

      <Col>
        <CustomText type={'secondary'} className={!showCaption && 'text-xs'}>
          {moment().format('YYYY')} UrbanPiper Technology Private Limited
        </CustomText>
      </Col>

      {!!showCaption && (
        <Col span={24}>
          <CustomText className={'text-sm'} type={'secondary'}>
            Created with love for food. 🍕
          </CustomText>
        </Col>
      )}
    </Row>
  );
}

export default React.memo(Footer);
