import { DeleteOutlined, QuestionCircleOutlined } from '@ant-design/icons';
import { Button, Image, Popconfirm, Space, Tag, Tooltip } from 'antd';
import React from 'react';
import { NavLink } from 'react-router-dom';
import CustomText from '../../../../../Components/Atoms/CustomText';
import {
  CommonGutterConfig,
  Platforms,
  Statuses,
  ClientStatuses
} from '../../../../../Configs/common';
import { SubRoutes } from '../../../../../Configs/routes';

export const Columns = [
  {
    title: 'Client',
    dataIndex: 'bizId',
    key: 'bizId',
    width: 250,
    render: ({ record: value, extraData: { path = '' } }) => {
      if (!value) return '-';

      return (
        <Space size={CommonGutterConfig.gutterHorizontalSm} align={'start'}>
          <Image src={value?.bizLogoUrl} width={80} preview={false} />

          <Space
            direction={'vertical'}
            size={CommonGutterConfig.gutterHorizontalXs}>
            <NavLink
              to={{
                pathname: path + '/' + value.id + SubRoutes.VIEW,
                state: value
              }}>
              <CustomText className={'hover:underline'}>
                {value?.bizName}
              </CustomText>
            </NavLink>

            <CustomText
              className={'text-xs'}
              type={'secondary'}
              copyable={{ text: value?.bizId }}>
              Biz id: {value?.bizId}
            </CustomText>

            <Tag
              color={ClientStatuses[value.status]?.color}
              icon={ClientStatuses[value.status]?.icon}>
              {ClientStatuses[value.status]?.label}
            </Tag>
          </Space>
        </Space>
      );
    },
    sorter: (a, b) => a.bizName?.localeCompare?.(b.bizName)
  },
  {
    title: 'Build',
    children: [
      {
        title: (
          <Space>
            {React.cloneElement(Platforms.android.icon, {
              style: { color: Platforms.android.color }
            })}

            <CustomText>{Platforms.android.label}</CustomText>
          </Space>
        ),
        children: [
          {
            title: 'Status',
            dataIndex: 'status',
            key: 'status',
            render: ({ value }) => {
              if (!value)
                return (
                  <Tag color={Statuses.pendingRelease.color}>
                    {Statuses.pendingRelease.label}
                  </Tag>
                );

              return value;
            }
          },
          {
            title: 'Version',
            dataIndex: 'version',
            key: 'version',
            render: ({ value }) => {
              if (!value)
                return (
                  <CustomText
                    className={'underline text-sm'}>{`v8.0.0`}</CustomText>
                );

              return value;
            }
          },
          {
            title: 'Last update',
            dataIndex: 'lastUpdate',
            key: 'lastUpdate',
            render: ({ value }) => {
              if (!value)
                return (
                  <CustomText
                    className={'text-xs'}>{`Wed 25 2022, 01:25 PM`}</CustomText>
                );

              return value;
            }
          }
        ]
      },
      {
        title: (
          <Space>
            {React.cloneElement(Platforms.ios.icon, {
              style: { color: Platforms.ios.color }
            })}

            <CustomText>{Platforms.ios.label}</CustomText>
          </Space>
        ),
        children: [
          {
            title: 'Status',
            dataIndex: 'status',
            key: 'status',
            render: ({ value }) => {
              if (!value)
                return (
                  <Tag color={Statuses.active.color}>
                    {Statuses.active.label}
                  </Tag>
                );

              return value;
            }
          },
          {
            title: 'Version',
            dataIndex: 'version',
            key: 'version',
            render: ({ value }) => {
              if (!value)
                return (
                  <CustomText
                    className={'underline text-sm'}>{`v8.0.0`}</CustomText>
                );

              return value;
            }
          },
          {
            title: 'Last update',
            dataIndex: 'lastUpdate',
            key: 'lastUpdate',
            render: ({ value }) => {
              if (!value)
                return (
                  <CustomText
                    className={'text-xs'}>{`Tue 24 2022, 07:13 PM`}</CustomText>
                );

              return value;
            }
          }
        ]
      }
    ]
  },
  {
    title: 'Actions',
    dataIndex: 'bizId',
    key: 'bizId',
    render: ({ record, extraActions: { onDeleteClient = () => {} } = {} }) => {
      return (
        <Popconfirm
          title={`Are you sure to delete "${record?.bizName}"?`}
          onConfirm={() => onDeleteClient(record)}
          icon={<QuestionCircleOutlined />}>
          <Tooltip title={'Delete client'} placement={'bottom'}>
            <Button type={'ghost'} danger icon={<DeleteOutlined />} />
          </Tooltip>
        </Popconfirm>
      );
    }
  }
];
