import { QuestionCircleOutlined } from '@ant-design/icons';
import { Button, Popconfirm, Space, Tooltip } from 'antd';
import React from 'react';
import IconButton from '../../Atoms/IconButton';

const ButtonType = {
  default: {
    key: 'default',
    type: 'primary',
    component: Button
  },
  iconButton: {
    key: 'iconButton',
    component: IconButton
  }
};

function ButtonGroup({
  buttons = [],
  type = ButtonType.default.key,
  TooltipProps = { placement: 'bottom' },
  BaseButtonProps = {},
  ...rest
}) {
  const uniqueId = React.useId();

  return (
    <Space {...rest}>
      {buttons.map((button, index) => {
        const {
          title,
          icon,
          onClick = () => {},
          danger = false,
          showPopUpConfirm = true,
          dangerProps: {
            title: dangerTitle = 'Are you sure to delete?',
            icon: dangerIcon = <QuestionCircleOutlined />,
            okButtonProps = { type: 'default', danger: true },
            cancelButtonProps = { type: 'primary' },
            ...restDangerProps
          } = {},
          ...rest
        } = button;

        const Comp = danger && showPopUpConfirm ? Popconfirm : React.Fragment;
        const ButtonComponent = ButtonType[type].component;

        const DangerProps =
          danger && showPopUpConfirm
            ? {
                ...restDangerProps,
                title: dangerTitle,
                icon: dangerIcon,
                okButtonProps,
                cancelButtonProps,
                onConfirm: e => {
                  e.stopPropagation();
                  onClick();
                },
                onCancel: e => e.stopPropagation()
              }
            : {};

        return (
          <Comp key={uniqueId + index} {...DangerProps}>
            <Tooltip title={title} {...TooltipProps}>
              <ButtonComponent
                icon={icon}
                onClick={
                  danger && showPopUpConfirm ? () => {} : () => onClick()
                }
                danger={danger}
                type={ButtonType[type].type || ''}
                {...BaseButtonProps}
                {...rest}
              />
            </Tooltip>
          </Comp>
        );
      })}
    </Space>
  );
}

export default React.memo(ButtonGroup);
