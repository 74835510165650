import * as Yup from 'yup';
import { AssetsMutation } from '../../../../../Apis/assets';
import {
  ANDROID_FILE_VALIDATION,
  DISPLAY_NAME_LENGTH,
  NAME_SUBTITLE_LENGTH
} from '../../../../../Configs/common';
import { validateFile } from '../../../../../Services/generalServices';

const tooltipConfig = title => ({
  title
});

export const schema = [
  {
    colConfig: { xs: 24 },
    divider: { title: 'App information' }
  },
  {
    name: 'displayName',
    label: 'Name',
    colConfig: { xs: 24, sm: 8 },
    inputConfig: {
      formItemProps: {
        tooltip: tooltipConfig('App display name')
      },
      inputProps: { placeholder: 'Enter app name' },
      validation: Yup.string().max(
        DISPLAY_NAME_LENGTH,
        'Name must be at most ${max} characters'
      )
    }
  },
  {
    name: 'applicationId',
    label: 'Package name',
    colConfig: { xs: 24, sm: 8 },
    inputConfig: {
      formItemProps: {
        tooltip: tooltipConfig('Package name')
      },
      inputProps: { placeholder: 'Enter package name' },
      validation: Yup.string()
    }
  },
  {
    name: 'teamId',
    label: 'Team Id',
    colConfig: { xs: 24, sm: 8 },
    inputConfig: {
      formItemProps: {
        tooltip: tooltipConfig('Team Id')
      },
      inputProps: { placeholder: 'Enter team id' },
      validation: Yup.string()
    }
  },
  {
    colConfig: { xs: 24 },
    divider: { title: 'Store information' }
  },
  {
    name: 'developerAccountName',
    label: 'Developer account name',
    colConfig: { xs: 24, sm: 8 },
    inputConfig: {
      formItemProps: {
        tooltip: tooltipConfig(
          'Developer account name where the app is hosted.'
        )
      },
      inputProps: { placeholder: 'Enter developer account name' },
      validation: Yup.string()
    }
  },
  {
    name: 'name',
    label: 'App name',
    colConfig: { xs: 24, sm: 6 },
    inputConfig: {
      type: 'TextArea',
      formItemProps: {
        tooltip: tooltipConfig(
          'This is how your app will appear on Play Store.'
        )
      },
      inputProps: {
        placeholder: 'Enter app name',
        autoSize: {
          minRows: 1,
          maxRows: 1
        },
        maxLength: NAME_SUBTITLE_LENGTH,
        showCount: true
      },
      validation: Yup.string().max(
        NAME_SUBTITLE_LENGTH,
        'App name must be at most ${max} characters'
      )
    }
  },
  {
    name: 'subTitle',
    label: 'Sub title',
    colConfig: { xs: 24, sm: 10 },
    inputConfig: {
      type: 'TextArea',
      formItemProps: {
        tooltip: tooltipConfig(
          'A short description for your app. Users can expand to view your full description.'
        )
      },
      inputProps: {
        placeholder: 'Enter sub title',
        autoSize: {
          minRows: 1,
          maxRows: 1
        },
        maxLength: NAME_SUBTITLE_LENGTH,
        showCount: true
      },
      validation: Yup.string().max(
        NAME_SUBTITLE_LENGTH,
        'Subtitle must be at most ${max} characters'
      )
    }
  },
  {
    name: 'keywords',
    label: 'Keywords',
    colConfig: { xs: 24, sm: 12 },
    inputConfig: {
      type: 'TextArea',
      formItemProps: {
        tooltip: tooltipConfig(
          'Include one or more keywords that describe your app. Keywords make search results more accurate. Separate keywords with an English comma, Chinese comma, or a mix of both.'
        )
      },
      inputProps: {
        autoSize: {
          minRows: 1,
          maxRows: 1
        },
        placeholder: 'Enter keywords',
        maxLength: 100,
        showCount: true
      },
      validation: Yup.string().trim().max(100)
    }
  },
  {
    name: 'promotionalText',
    label: 'Promotional text',
    colConfig: { xs: 24, sm: 12 },
    inputConfig: {
      formItemProps: {
        tooltip: tooltipConfig(
          'Promotional text lets you inform your visitors of any current app features without requiring an updated submission.'
        )
      },
      inputProps: {
        placeholder: 'Enter promotional text'
      },
      validation: Yup.string()
    }
  },
  {
    name: 'description',
    label: 'Description',
    colConfig: { xs: 24 },
    inputConfig: {
      type: 'TextArea',
      formItemProps: {
        tooltip: tooltipConfig(
          'A description of your app, detailing features and functionality.'
        )
      },
      inputProps: {
        placeholder: 'Enter description',
        maxLength: 4000,
        showCount: true,
        autoSize: {
          minRows: 4,
          maxRows: 8
        }
      },
      validation: Yup.string().trim().max(4000)
    }
  },
  {
    colConfig: { xs: 24 },
    divider: { title: 'App privacy and copyright' }
  },
  {
    name: 'supportUrl',
    label: 'Support URL',
    colConfig: { xs: 24, md: 12 },
    inputConfig: {
      formItemProps: {
        tooltip: tooltipConfig('A URL with support information for your app.')
      },
      inputProps: {
        placeholder: 'Enter support Url'
      },
      validation: Yup.string().url()
    }
  },
  {
    name: 'supportEmail',
    label: 'Support email',
    colConfig: { xs: 24, md: 12 },
    inputConfig: {
      formItemProps: {
        tooltip: tooltipConfig('An email for support for your app.')
      },
      inputProps: {
        placeholder: 'Enter support email'
      },
      validation: Yup.string().email()
    }
  },
  {
    name: 'marketingUrl',
    label: 'Marketing URL',
    colConfig: { xs: 24, md: 12 },
    inputConfig: {
      formItemProps: {
        tooltip: tooltipConfig(
          'A URL with marketing information about your app.'
        )
      },
      inputProps: {
        placeholder: 'Enter marketing Url'
      },
      validation: Yup.string().url().optional()
    }
  },
  {
    name: 'privacyUrl',
    label: 'Privacy policy URL',
    colConfig: { xs: 24, md: 12 },
    inputConfig: {
      formItemProps: {
        tooltip: tooltipConfig(
          'A URL that links to your privacy policy. A privacy policy is required for all apps.'
        )
      },
      inputProps: {
        placeholder: 'Enter privacy policy Url'
      },
      validation: Yup.string().url()
    }
  },
  {
    name: 'copyright',
    label: 'Copyright',
    colConfig: { xs: 24, md: 12 },
    inputConfig: {
      formItemProps: {
        tooltip: tooltipConfig(
          'The name of the person or entity that owns the exclusive rights to your app, preceded by the year the rights were obtained (for example, "2008 Acme Inc."). Do not provide a URL.'
        )
      },
      inputProps: {
        placeholder: 'Enter copyright'
      },
      validation: Yup.string()
    }
  },
  {
    name: 'keyStoreId',
    label: 'Keystore file',
    colConfig: { xs: 24, md: 12 },
    inputConfig: {
      name: 'select',
      value: '',
      formItemProps: {
        tooltip: tooltipConfig(
          'Associate a keystore file for securing the app.'
        )
      },
      inputProps: {
        placeholder: 'Enter keystore file name.',
        showSearch: true,
        filterOption: (input, option) =>
          option.children.toLowerCase().includes(input.toLowerCase())
      },
      validation: Yup.string()
    }
  },
  {
    colConfig: { span: 24 },
    divider: { title: 'Code push configs' }
  },
  {
    name: 'codePushDeploymentKey',
    label: 'Code push deployment key',
    colConfig: { xs: 24, sm: 10 },
    inputConfig: {
      type: 'Password',
      value: '',
      inputProps: { placeholder: 'Enter code push deployment key' },
      formItemProps: {
        tooltip: tooltipConfig(
          'Key for code-push deployment, This is required for sending in-app updates.'
        )
      },
      validation: Yup.string()
    }
  },
  {
    name: 'codePushAppName',
    label: 'Code push app name',
    colConfig: { xs: 24, sm: 8 },
    inputConfig: {
      value: '',
      inputProps: { placeholder: 'Enter code push app name' },
      formItemProps: {
        tooltip: tooltipConfig(
          'The app name is required to target that particular app for the OTA update'
        )
      },
      validation: Yup.string()
    }
  },
  {
    name: 'appcenterJson',
    label: 'App center config JSON file',
    colConfig: { xs: 24, sm: 6 },
    inputConfig: {
      name: 'file',
      formItemProps: {
        tooltip: tooltipConfig(
          'App center config JSON file for Android updates'
        )
      },
      inputProps: {
        title: 'Upload JSON file',
        accept: '.json',
        isFile: true,
        showUploadButton: true,
        uploadOptions: {
          key: AssetsMutation.uploadAppCenterJsonFile.key
        }
      },
      validation: Yup.mixed()
    }
  },
  {
    colConfig: { xs: 24 },
    divider: { title: 'Firebase and Play store configurations' }
  },
  {
    name: 'serviceConfigJson',
    label: 'Google services JSON file',
    colConfig: { xs: 24, sm: 6 },
    inputConfig: {
      name: 'file',
      formItemProps: {
        tooltip: tooltipConfig('Google services JSON file for Android')
      },
      inputProps: {
        title: 'Upload JSON file',
        accept: '.json',
        isFile: true,
        showUploadButton: true,
        uploadOptions: {
          key: AssetsMutation.uploadServicesJsonFile.key
        }
      },
      validation: Yup.mixed()
    }
  },
  {
    name: 'googleplayJson',
    label: 'Google Play JSON',
    colConfig: { xs: 24, sm: 6 },
    inputConfig: {
      name: 'file',
      formItemProps: {
        tooltip: tooltipConfig('Google play store JSON file for Android')
      },
      inputProps: {
        title: 'Upload JSON file',
        accept: '.json',
        isFile: true,
        showUploadButton: true,
        uploadOptions: {
          key: AssetsMutation.uploadPlayStoreServicesJsonFile.key
        }
      },
      validation: Yup.mixed()
    }
  }
];

export const storeAssetSchemes = [
  {
    colConfig: { span: 24 },
    divider: { title: 'App icon and feature banner' }
  },
  {
    name: 'logo',
    label: 'Android logo',
    colConfig: { xs: 24, sm: 12 },
    inputConfig: {
      name: 'file',
      formItemProps: {
        tooltip: tooltipConfig('Logo of the app to be displayed on Play Store')
      },
      inputProps: {
        title: 'Upload logo',
        showDragAndDrop: true,
        showCropper: true,
        uploadOptions: { key: AssetsMutation.uploadLogo.key }
      },
      validation: Yup.mixed().test({
        name: 'fileSize',
        params: {
          size: ANDROID_FILE_VALIDATION.logo.size,
          width: ANDROID_FILE_VALIDATION.logo.dimension.width,
          height: ANDROID_FILE_VALIDATION.logo.dimension.height
        },
        message:
          'Your app icon must be a transparent PNG or JPEG, up to ${size} MB, ${width} px by ${height} px',
        test: async value => {
          if (!value || !value.size) return true;

          return await validateFile(value, ANDROID_FILE_VALIDATION.logo);
        }
      })
    }
  },
  {
    name: 'banner',
    label: 'Feature graphic',
    colConfig: { xs: 24, sm: 12 },
    inputConfig: {
      name: 'file',
      formItemProps: {
        tooltip: tooltipConfig(
          'Feature graphic of the app to be displayed on Play Store'
        )
      },
      inputProps: {
        title: 'Upload graphic',
        showDragAndDrop: true,
        uploadOptions: { key: AssetsMutation.uploadBanner.key }
      },
      validation: Yup.mixed().test({
        name: 'fileSize',
        params: {
          size: ANDROID_FILE_VALIDATION.featureBanner.size,
          width: ANDROID_FILE_VALIDATION.featureBanner.dimension.width,
          height: ANDROID_FILE_VALIDATION.featureBanner.dimension.height
        },
        message:
          'Your feature graphic must be a transparent PNG or JPEG, up to ${size} MB, ${width} px by ${height} px',
        test: async value => {
          if (!value || !value.size) return true;

          return await validateFile(
            value,
            ANDROID_FILE_VALIDATION.featureBanner
          );
        }
      })
    }
  },
  {
    colConfig: { span: 24 },
    divider: { title: 'App screenshots' }
  },
  {
    name: 'screenshots',
    label: 'Screenshots',
    colConfig: { xs: 24 },
    inputConfig: {
      name: 'file',
      value: [],
      formItemProps: {
        tooltip: tooltipConfig(
          'Screenshots of the app to be displayed on Play Store'
        )
      },
      inputProps: {
        title: 'Upload screenshots',
        showDragAndDrop: true,
        listType: 'picture-card',
        multiple: true,
        uploadOptions: { key: AssetsMutation.uploadScreenshots.key }
      },
      validation: Yup.array()
        .min(
          ANDROID_FILE_VALIDATION.screenshots.length.min,
          'Minimum ${min} screenshots should be added.'
        )
        .max(
          ANDROID_FILE_VALIDATION.screenshots.length.max,
          'Maximum ${max} screenshots are allowed.'
        )
        .of(
          Yup.mixed().test({
            name: 'fileSize',
            params: {
              size: ANDROID_FILE_VALIDATION.screenshots.size,
              aspect: ANDROID_FILE_VALIDATION.screenshots.dimension.aspect,
              rangeMin:
                ANDROID_FILE_VALIDATION.screenshots.dimension.widthRange[0],
              rangeMax:
                ANDROID_FILE_VALIDATION.screenshots.dimension.widthRange[1]
            },
            message:
              'Screenshots must be PNG or JPEG, up to ${size} MB each, 9:16 aspect ratio, with each side between ${rangeMin} px and ${rangeMax} px, ',
            test: async value => {
              if (!value || !value.size) return true;

              return await validateFile(
                value,
                ANDROID_FILE_VALIDATION.screenshots,
                true
              );
            }
          })
        )
    }
  }
];

export const appAssetSchemes = [
  {
    name: 'launcherIcon',
    label: 'App launcher icon',
    colConfig: { xs: 24, sm: 12 },
    inputConfig: {
      name: 'file',
      formItemProps: {
        tooltip: tooltipConfig(
          'Launcher icon of the app to be displayed on the users phone.'
        )
      },
      inputProps: {
        title: 'Upload launcher icon',
        showDragAndDrop: true,
        uploadOptions: { key: AssetsMutation.uploadLauncherIcon.key }
      },
      validation: Yup.mixed().test({
        name: 'fileSize',
        params: {
          size: ANDROID_FILE_VALIDATION.launcherIcon.size,
          width: ANDROID_FILE_VALIDATION.launcherIcon.dimension.width,
          height: ANDROID_FILE_VALIDATION.launcherIcon.dimension.height
        },
        message:
          'Your launcher icon must be a PNG or JPEG, up to ${size} MB, ${width} px by ${height} px',
        test: async value => {
          if (!value || !value.size) return true;

          return await validateFile(
            value,
            ANDROID_FILE_VALIDATION.launcherIcon
          );
        }
      })
    }
  },
  {
    name: 'notificationIcon',
    label: 'App notification icon',
    colConfig: { xs: 24, sm: 12 },
    inputConfig: {
      name: 'file',
      formItemProps: {
        tooltip: tooltipConfig(
          'Notification icon of the app to be displayed when user receives any notification.'
        )
      },
      inputProps: {
        title: 'Upload notification icon',
        showDragAndDrop: true,
        uploadOptions: { key: AssetsMutation.uploadNotificationIcon.key }
      },
      validation: Yup.mixed().test({
        name: 'fileSize',
        params: {
          size: ANDROID_FILE_VALIDATION.notificationIcon.size,
          width: ANDROID_FILE_VALIDATION.notificationIcon.dimension.width,
          height: ANDROID_FILE_VALIDATION.notificationIcon.dimension.height
        },
        message:
          'Your notification icon must be a PNG or JPEG, up to ${size} MB, ${width} px by ${height} px',
        test: async value => {
          if (!value || !value.size) return true;

          return await validateFile(
            value,
            ANDROID_FILE_VALIDATION.notificationIcon
          );
        }
      })
    }
  },
  {
    name: 'splashScreen',
    label: 'Splash screen',
    colConfig: { xs: 24 },
    inputConfig: {
      name: 'file',
      formItemProps: {
        tooltip: tooltipConfig(
          'Splash screen of the app to be displayed on start of the app.'
        )
      },
      inputProps: {
        title: 'Upload splash screen',
        showDragAndDrop: true,
        uploadOptions: { key: AssetsMutation.uploadSplashScreen.key }
      },
      validation: Yup.mixed().test({
        name: 'fileSize',
        params: {
          size: ANDROID_FILE_VALIDATION.splashScreen.size,
          width: ANDROID_FILE_VALIDATION.splashScreen.dimension.width,
          height: ANDROID_FILE_VALIDATION.splashScreen.dimension.height
        },
        message:
          'Your splash screen must be a PNG or JPEG, up to ${size} MB, ${width} px by ${height} px',
        test: async value => {
          if (!value || !value.size) return true;

          return await validateFile(
            value,
            ANDROID_FILE_VALIDATION.splashScreen
          );
        }
      })
    }
  }
];
