import React from 'react';
import FormInput from '../../../../../Components/Cells/FormInput';
import MessageService from '../../../../../Services/messageService';
import { storeAssetSchemes, schema, appAssetSchemes } from './schema';
import { Button, Col, Row, Tooltip } from 'antd';
import Toolbar from '../../../../../Components/Cells/Toolbar';
import { ShopOutlined } from '@ant-design/icons';
import { CommonGutterConfig, Platforms } from '../../../../../Configs/common';
import UseQuery from '../../../../../Components/Wrappers/UseQuery';
import { AppMutations, AppQueries } from '../../../../../Apis/app';
import UseMutation from '../../../../../Components/Wrappers/UseMutation';
import LoadingService from '../../../../../Services/loadingService';
import RenderHeader from '../Components/RenderHeader';
import { AssetsMutation } from '../../../../../Apis/assets';
import { toPascalCase } from '../../../../../Services/generalServices';

class AndroidTab extends React.PureComponent {
  constructor(props) {
    super(props);

    this.state = {};
  }

  onStoreClick = () => {};

  onSubmit = (values, mutateAsync, refetch, id) => {
    delete values.serviceConfigJson;
    delete values.appcenterJson;

    const payload = {
      ...values,
      platform: Platforms.android.key
    };

    LoadingService.toggleLoading();

    mutateAsync({ payload, id })
      .then(() => {
        refetch();

        MessageService.success('Changes updated successfully!!!');
      })
      .catch(error => {
        MessageService.error(error.message);
      })
      .finally(LoadingService.toggleLoading);
  };

  onStoreScreenShotDelete = (appId, mutateAsync) => (_, index) => {
    LoadingService.toggleLoading();
    return mutateAsync({ appId, index }).finally(LoadingService.toggleLoading);
  };

  render() {
    const { id } = this.props;

    return (
      <UseQuery
        queryKey={AppQueries.getAppByClientIdAndPlatform.key}
        queryOptions={{
          onError: error => {
            MessageService.error(error.message);
          }
        }}
        extraData={[id, Platforms.android.key]}>
        {({ query: { data: { data = {} } = {}, isFetching, refetch } }) => (
          <UseMutation mutationKey={AppMutations.updateApp.key}>
            {({ mutateAsync: updateOrCreateApp }) => (
              <Row
                className={'min-w-full'}
                gutter={CommonGutterConfig.gutterVerticalLg}>
                <Col span={24}>
                  <Toolbar
                    title={'Android & Play Store configs'}
                    size={'middle'}
                    loading={isFetching}
                    onReload={refetch}
                    renderActions={() => (
                      <Tooltip title={'Go to store'} placement={'bottom'}>
                        <Button
                          type={'primary'}
                          loading={isFetching}
                          icon={<ShopOutlined />}
                          onClick={this.onStoreClick}
                        />
                      </Tooltip>
                    )}
                  />
                </Col>

                <Col span={24}>
                  <UseMutation
                    mutationKey={AssetsMutation.deleteScreenshotById.key}>
                    {({ mutateAsync: deleteScreenshotById }) => (
                      <UseQuery queryKey={AppQueries.getKeystoreFiles.key}>
                        {({
                          query: { data: { data: keystoreFiles = [] } = {} }
                        }) => (
                          <FormInput
                            defaultValues={data}
                            loading={isFetching}
                            schemes={schema}
                            extraInputConfig={{
                              keyStoreId: {
                                data: keystoreFiles.map(item => ({
                                  value: item.id,
                                  label: toPascalCase(item.name)
                                })),
                                inputProps: {
                                  value: keystoreFiles.find(
                                    item => item.name === 'common'
                                  )?.id
                                }
                              },
                              appcenterJson: {
                                inputProps: {
                                  uploadOptions: {
                                    extraConfig: { appId: data?.id }
                                  }
                                }
                              },
                              serviceConfigJson: {
                                inputProps: {
                                  uploadOptions: {
                                    extraConfig: { appId: data?.id }
                                  }
                                }
                              },
                              googleplayJson: {
                                inputProps: {
                                  uploadOptions: {
                                    extraConfig: { appId: data?.id }
                                  }
                                }
                              }
                            }}
                            renderHeader={
                              <RenderHeader
                                refetch={refetch}
                                storeAssetsProps={{
                                  schemes: storeAssetSchemes,
                                  commonInputConfig: {
                                    inputProps: {
                                      uploadOptions: {
                                        extraConfig: { appId: data?.id }
                                      }
                                    }
                                  },
                                  extraInputConfig: {
                                    screenshots: {
                                      inputProps: {
                                        onDelete: this.onStoreScreenShotDelete(
                                          data?.id,
                                          deleteScreenshotById
                                        )
                                      }
                                    }
                                  },
                                  data: {
                                    logo: data?.logo ?? '',
                                    banner: data?.banner ?? '',
                                    screenshots:
                                      data?.screenshots?.large?.screenshots ??
                                      []
                                  }
                                }}
                                appAssetsProps={{
                                  schemes: appAssetSchemes,
                                  commonInputConfig: {
                                    inputProps: {
                                      uploadOptions: {
                                        extraConfig: { appId: data?.id }
                                      }
                                    }
                                  },
                                  data: {
                                    launcherIcon: data?.launcherIcon ?? '',
                                    notificationIcon:
                                      data?.notificationIcon ?? '',
                                    splashScreen: data?.splashScreen ?? ''
                                  }
                                }}
                              />
                            }
                            onSubmit={values =>
                              this.onSubmit(
                                { ...data, ...values },
                                updateOrCreateApp,
                                refetch,
                                data.id
                              )
                            }
                          />
                        )}
                      </UseQuery>
                    )}
                  </UseMutation>
                </Col>
              </Row>
            )}
          </UseMutation>
        )}
      </UseQuery>
    );
  }
}

export default AndroidTab;
