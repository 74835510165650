import { ReloadOutlined } from '@ant-design/icons';
import { Button, Col, Row, Space, Tooltip } from 'antd';
import React from 'react';
import { CommonGutterConfig } from '../../../Configs/common';
import CustomText from '../../Atoms/CustomText';

const TitleSize = {
  small: {
    key: 'small',
    value: 'text-sm'
  },
  middle: {
    key: 'middle',
    value: 'text-xl'
  },
  large: {
    key: 'large',
    value: 'text-2xl'
  }
};

function Toolbar({
  title,
  size = TitleSize.large.key,
  center = false,
  loading = false,
  onReload,
  renderActions,
  titleClassName
}) {
  return (
    <Row
      justify={'space-between'}
      className={'min-w-full'}
      wrap
      gutter={CommonGutterConfig.gutterVerticalBase}>
      <Col className={'flex flex-grow items-center'}>
        {typeof title === 'string' ? (
          <CustomText
            element={'paragraph'}
            className={`${
              TitleSize[size].value
            } font-medium mb-0 flex-grow whitespace-nowrap ${
              center ? 'text-center' : ''
            } ${titleClassName ? titleClassName : ''}`}>
            {title}
          </CustomText>
        ) : React.isValidElement(title) ? (
          title
        ) : null}
      </Col>

      <Col>
        <Space>
          {renderActions &&
            renderActions instanceof Function &&
            renderActions()}

          {onReload && onReload instanceof Function && (
            <Tooltip title={'Reload'} placement={'bottomRight'}>
              <Button
                icon={<ReloadOutlined />}
                loading={loading}
                onClick={onReload}
                type={'primary'}
                size={size}
                shape={'circle'}
              />
            </Tooltip>
          )}
        </Space>
      </Col>
    </Row>
  );
}

export default React.memo(Toolbar);
