import { REHYDRATE } from 'redux-persist';
import Utilities from '../../Utilities';
import { UserActionTypes } from '../Actions/user';

const unPersistedUserState = {};

const defaultUserState = {
  isLoggedIn: false,
  userData: null,
  ...unPersistedUserState
};

const UserReducer = (state = { ...defaultUserState }, action) => {
  switch (action.type) {
    case UserActionTypes.LOGIN:
      Utilities.secretToken =
        action.userData?.secretToken || Utilities.secretToken;

      return {
        ...state,
        isLoggedIn: true,
        userData: { ...state.userData, ...action.userData }
      };

    case UserActionTypes.UPDATE_USER_DATA:
      return {
        ...state,
        userData: { ...state.userData, ...action.userData }
      };

    case UserActionTypes.LOGOUT:
      Utilities.secretToken = null;

      return {
        ...state,
        ...defaultUserState
      };

    case REHYDRATE:
      let { user } = action.payload || {};

      Utilities.secretToken = user?.userData?.secretToken;

      return {
        ...state,
        ...user,
        ...unPersistedUserState
      };

    default:
      return {
        ...state
      };
  }
};

export default UserReducer;
