import React from 'react';
import { useGoogleOneTapLogin, GoogleLogin } from '@react-oauth/google';
import { connect } from 'react-redux';
import { loginThunk } from '../../../Redux/Thunks/user';
import MessageService from '../../../Services/messageService';

function SignInButton({ login, ...rest }) {
  useGoogleOneTapLogin({
    onSuccess: login,
    onError: () => {
      MessageService.error('Failed to login')
    },
  });

  return (
    <GoogleLogin
      onSuccess={login}
      onError={() => MessageService.error('Failed to login')}
    />
  );
}

const mapDispatchToProps = dispatch => ({
  login: response => dispatch(loginThunk(response))
});

export default connect(null, mapDispatchToProps)(React.memo(SignInButton));
