import { BugFilled, BugOutlined } from '@ant-design/icons';
import { Button, Col, Row } from 'antd';
import React from 'react';
import { BuildQueries, BuildSubscriptions } from '../../../../../Apis/builds';
import Toolbar from '../../../../../Components/Cells/Toolbar';
import BuildDetails from '../../../../../Components/Cells/BuildDetails';
import CustomTable from '../../../../../Components/Molecules/CustomTable';
import IconText from '../../../../../Components/Molecules/IconText';
import UseQuery from '../../../../../Components/Wrappers/UseQuery';
import UseSubscription from '../../../../../Components/Wrappers/UseSubscription';
import { CommonGutterConfig } from '../../../../../Configs/common';
import ModalService from '../../../../../Services/modalService';
import RequestBuild from './Components/RequestBuild';
import { BuildColumns } from './schema';

class Builds extends React.PureComponent {
  constructor(props) {
    super(props);

    this.clientId = props.id;
    this.state = {
      updatedBuilds: {}
    };
  }

  mergeDataWithState = (data, state) => {
    return data.map(build => {
      if (state[build.id]) {
        build.progress = state[build.id].progress;
      }
      return build;
    });
  };

  onRequestBuild = refetch => {
    ModalService.showModal({
      title: <IconText icon={<BugFilled />} label={'Request build'} />,
      renderChildren: <RequestBuild id={this.clientId} onSuccess={refetch} />
    });
  };

  onSuccess = refetch => {
    refetch();
  };

  renderActions = (loading = false, refetch) => {
    return (
      <Button
        type={'primary'}
        loading={loading}
        icon={<BugOutlined />}
        onClick={() => this.onRequestBuild(refetch)}>
        Request build
      </Button>
    );
  };

  render() {
    return (
      <UseQuery
        queryKey={BuildQueries.getBuildsByClientId.key}
        extraData={[this.clientId]}>
        {({ query: { data: { data = [] } = {}, isFetching, refetch } }) => {
          return (
            <Row
              className={'min-w-full'}
              gutter={CommonGutterConfig.gutterVerticalLg}>
              <Col span={24}>
                <Toolbar
                  size={'middle'}
                  title={'Builds'}
                  loading={isFetching}
                  renderActions={() => this.renderActions(isFetching, refetch)}
                  onReload={refetch}
                />
              </Col>

              <UseSubscription
                queryKey={BuildSubscriptions.subscribeToBuild.key}
                extraData={data}
                onNext={(preData, { data: { buildUpdated } }) => {
                  const updatedBuildId = buildUpdated.id;
                  const buildIndex = preData.findIndex(
                    build => build.id === updatedBuildId
                  );
                  if (buildIndex >= 0) {
                    this.setState({
                      updatedBuilds: {
                        ...this.state.updatedBuilds,
                        [updatedBuildId]: buildUpdated
                      }
                    });
                  }
                }}
              />

              <Col span={24}>
                <CustomTable
                  columns={BuildColumns}
                  extraActions={{
                    onSuccess: () => this.onSuccess(refetch)
                  }}
                  expandable={{
                    expandedRowRender: record => <BuildDetails {...record} />
                  }}
                  extraData={{ loading: isFetching, clientId: this.clientId }}
                  dataSource={this.mergeDataWithState(
                    data,
                    this.state.updatedBuilds
                  )}
                  loading={isFetching}
                />
              </Col>
            </Row>
          );
        }}
      </UseQuery>
    );
  }
}

export default Builds;
