import * as Yup from 'yup';

export const schemes = [
  {
    name: 'emails',
    label: 'Email list',
    colConfig: { xs: 24 },
    inputConfig: {
      name: 'multiSelect',
      value: [],
      required: true,
      formItemProps: {
        tooltip: 'List of testers emails.'
      },
      inputProps: { placeholder: 'Enter email', autoFocus: true },
      validation: Yup.array()
        .of(Yup.string().email().required())
        .min(1, 'Minimum ${min} email should be passed')
        .max(8, 'Maximum ${max} testers are allowed')
    }
  },
  {
    name: 'appIds',
    label: 'Platform',
    colConfig: { xs: 24 },
    inputConfig: {
      required: true,
      name: 'select',
      value: [],
      formItemProps: {
        tooltip: 'For which platform you want to request the build for.'
      },
      inputProps: {
        placeholder: 'Enter platform',
        mode: 'multiple',
        allowClear: true
      },
      validation: Yup.array().min(
        1,
        'Minimum ${min} platform should be selected'
      )
    }
  },
  {
    name: 'releaseId',
    label: 'Build Version',
    colConfig: { xs: 24 },
    inputConfig: {
      required: true,
      name: 'select',
      value: [],
      formItemProps: {
        tooltip: 'Select the app version you need to build for.'
      },
      inputProps: {
        placeholder: 'Select Version',
      },
    }
  },
  {
    name: 'comment',
    label: 'Reason for request',
    colConfig: { xs: 24 },
    inputConfig: {
      required: true,
      type: 'TextArea',
      value: '',
      formItemProps: {
        tooltip: 'Reason for the request or comment to the developer.'
      },
      inputProps: {
        autoSize: {
          minRows: 2,
          maxRows: 4
        },
        placeholder:
          'Enter a reason for the request or a comment to the developer',
        maxLength: 200,
        showCount: true
      },
      validation: Yup.string().trim().max(200)
    }
  }
];
