import Axios from '.';

const GithubApiRoutes = {
  base: '/github'
};

export const GithubQueries = {
  getAllReleases: {
    key: 'getAllReleases',
    api: () =>
      Axios.getInstance()
        .get(`${GithubApiRoutes.base}/releases`)
        .then(data => {
          data.data = data.data.filter(item => !item.draft);
          return data;
        })
  },
  getReleaseById: {
    key: 'getReleaseById',
    api: releaseId =>
      Axios.getInstance().get(`${GithubApiRoutes.base}/releases/${releaseId}`)
  }
};

export const GithubMutations = {};
