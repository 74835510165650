import { CloseOutlined } from '@ant-design/icons';
import { Modal, Tooltip } from 'antd';
import React from 'react';
import UseBreakPoint from '../UseBreakPoint';

const ModalContext = React.createContext();

export const withModal = RCTComponent =>
  React.forwardRef((props, ref) => (
    <ModalContext.Consumer>
      {context => <RCTComponent ref={ref} {...props} {...context} />}
    </ModalContext.Consumer>
  ));

const WidthSize = {
  sm: '30%',
  base: '40%',
  lg: '60%',
  xl: '80%',
  full: '100%'
};

class ModalProvider extends React.PureComponent {
  constructor(props) {
    super(props);

    this.state = {
      modals: [],
      context: {
        showModal: this.showModal,
        closeModal: this.closeModal,
        closeAll: this.closeAll
      }
    };
  }

  showModal = params => {
    const { modals } = this.state;

    const newModals = [...modals];

    newModals.push({ visible: true, ...params });

    this.setState({ modals: [...newModals] });
  };

  closeModal = ({ onClose = () => {} } = {}, index = -1) => {
    const { modals } = this.state;

    const modalToDeleteIndex = index >= 0 ? index : modals.length - 1;
    const modalToDelete = modals[modalToDeleteIndex];

    modalToDelete.visible = false;

    const newModals = [...modals];
    newModals.splice(modalToDeleteIndex, 1, modalToDelete);

    this.setState({ modals: [...newModals] }, () => {
      /**
       * This has been done to sustain the Modal closing animation.
       */
      setTimeout(() => {
        const { modals } = this.state;
        const newModals = [...modals];

        newModals.pop();
        this.setState({ modals: [...newModals] }, onClose);
      }, 300);
    });
  };

  closeAll = ({ onClose = () => {} } = {}) => {
    this.setState({ modals: [] }, onClose);
  };

  /**
   * This function is called from the children element to alter the props of their Modal
   */
  setModalProps = (props, index) => {
    const modalsClone = [...this.state.modals];
    let currentModal = { ...modalsClone[index] };
    currentModal['ModalProps'] = {
      ...currentModal['ModalProps'],
      ...props
    };

    modalsClone.splice(index, 1, currentModal);

    this.setState({ modals: modalsClone });
  };

  render() {
    const { modals, context } = this.state;
    const { children } = this.props;

    return (
      <ModalContext.Provider value={{ ...context }}>
        {children}

        <UseBreakPoint>
          {({ xs }) => {
            return modals.map((modal, index) => {
              const {
                renderChildren = () => {},
                title,
                width = 'base',
                renderFooter,
                visible = true,
                ModalProps = {}
              } = modal;

              const { closable = true, ...rest } = ModalProps;

              const renderChildrenProps = {
                modalIndex: index,
                setModalProps: props => this.setModalProps(props, index),
                closeModal: props => this.closeModal(props, index),
                closeAll: props => this.closeAll(props)
              };

              return (
                <Modal
                  title={title}
                  width={xs ? WidthSize.full : WidthSize[width]}
                  visible={visible}
                  destroyOnClose
                  closeIcon={
                    <Tooltip title={'Close'} placement={'bottom'}>
                      <CloseOutlined />
                    </Tooltip>
                  }
                  closable={closable}
                  maskClosable={closable}
                  keyboard={closable}
                  focusTriggerAfterClose={false}
                  footer={renderFooter ? renderFooter() : null}
                  onCancel={this.closeModal}
                  key={index}
                  {...rest}>
                  {renderChildren &&
                    React.isValidElement(renderChildren) &&
                    React.cloneElement(renderChildren, renderChildrenProps)}
                </Modal>
              );
            });
          }}
        </UseBreakPoint>
      </ModalContext.Provider>
    );
  }
}

export default ModalProvider;
